.header_container {
  width: 1920px;
  height: 108px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 2;

  .left {
    position: static;
    width: 132px;
    height: 51px;
    margin-left: 113px;
    margin-top: 28.5px;
    // left: 113px;
    // top: 28.5px;
    font-family: Saira;
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 133%;
    color: #ffffff;
  }

  .right {
    // position: static;
    // width: 412px;
    // height: 56px;
    // // left: 1395px;
    // margin-right: 113px;
    // // top: 26px;
    // margin-top: 26px;
    // margin-bottom: 26px;
    // float: left;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      // list-style: none;
      float: left;
      padding-inline-start: 0;
      justify-content: space-between;
      position: static;
      width: 412px;
      height: 56px;
      margin-right: 113px;
      margin-top: 26px;
      margin-bottom: 26px;
      float: left;
      li {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        // line-height: 19px;
        color: #ffffff;
      }
      .home {
        // padding-inline-start: 0;
        margin-right: 48px;
      }
      .life {
        margin-right: 48px;
      }
      .join_us {
        width: 156px;
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // padding: 24px 72px;
        // position: relative;
        // width: 156px;
        // height: 48px;
        // left: 4px;
        // top: 4px;
        // padding: 24px 72px;
        // margin-top: -13px;
        // padding: 14.5px  49px;
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        border-radius: 8px;
      }
    }
  }
}
