.page1_container {
  width: 1980px;
  height: 972px;
  display: flex;
  flex-direction: row;
  // background-size: cover;
  // background-attachment: fixed;
  // background-blend-mode: multiply;
  // overflow: auto;
  // scroll-snap-align: start;
  // scroll-snap-type: y mandatory;

  .left {
    .group1 {
      width: 127px;
      height: 114px;
      position: absolute;
      left: -0.78%;
      right: 94.17%;
      top: 19.07%;
      bottom: 70.37%;
      // opacity: 0.1;
    }

    .group2 {
      width: 72px;
      height: 72px;
      position: absolute;
      left: 33.96%;
      right: 62.29%;
      top: 27.22%;
      bottom: 66.11%;
    }

    .group3 {
      width: 88px;
      height: 85px;
      position: absolute;
      left: 3.75%;
      right: 91.68%;
      top: 46.63%;
      bottom: 45.51%;
      // transform: rotate(-51.4deg);
    }

    .group4 {
      width: 55px;
      height: 55px;
      position: absolute;
      left: 37.71%;
      right: 59.43%;
      top: 53.24%;
      bottom: 41.72%;
    }

    .group5 {
      width: 34px;
      height: 34px;
      position: absolute;
      left: 6.35%;
      right: 91.87%;
      top: 74.07%;
      bottom: 22.78%;
      // opacity: 0.1;
    }

    .group6 {
      width: 74px;
      height: 74px;
      position: absolute;
      left: 30.1%;
      right: 66.04%;
      top: 81.67%;
      bottom: 11.48%;
      // opacity: 0.2;
    }

    .text_container {
      position: absolute;
      width: 670px;
      height: 124px;
      //   left: 167px;
      left: 146px;
      //   top: 502px;
      top: 548px;
      //   top: 394px;

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 120%;
        /* or 62px */

        letter-spacing: -0.03em;

        color: #ffffff;

        .color_text {
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .text_box {
      position: absolute;
      width: 663px;
      height: 257px;
      left: 164px;
      top: 559px;

      background: rgba(77, 29, 110, 0.56);
      border-radius: 0px 12px 12px 0px;
      margin-top: 130px;
      .ver_box {
        // position: absolute;
        width: 9.77px;
        height: 223.02px;
        // left: 164px;
        // top: 575.99px;
        position: relative;
        top: 16.99px;

        background: linear-gradient(144.98deg, #ff008a 16.34%, #0082ff 77.38%);
        border-radius: 0px 29px 29px 0px;
      }
      .text {
        position: absolute;
        width: 535px;
        height: 216px;
        // left: 201px;
        top: 585px;

        // position: relative;
        left: 37px;
        top: 6px;
        bottom: 15px;

        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 225%;
        /* or 54px */

        letter-spacing: 0.105em;

        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .right {
    // position: absolute;
    position: fixed;
    width: 887px;
    height: 690px;
    left: 1010px;
    // top: 255px;
    top: 147px;

    .tier2 {
      // position: absolute;
      position: fixed;
      width: 350.05px;
      height: 258.47px;
      // left: 1118.96px;
      right: 451px;
      // top: 344.58px;
      top: 236.58px;
    }

    .tier1 {
      // position: absolute;
      position: fixed;
      width: 350.05px;
      height: 258.47px;
      // left: 1362.22px;
      right: 207.73px;
      // top: 503.59px;
      top: 395.59px;
    }
    .anchor {
      // position: absolute;
      position: fixed;
      width: 350.9px;
      height: 258.47px;
      // left: 1102.79px;
      right: 466.3px;
      // top: 657.57px;
      top: 549.57px;

      
    }

    .road1 {
      // position: absolute;
      position: fixed;
      width: 121.39px;
      height: 83.71px;
      // left: 122.31px;
      // top: 12.05px;
      right: 428.69px;
      top: 384.05px;
    }
    .truck1 {
      // position: absolute;
      position: fixed;
      width: 57.56px;
      height: 48.38px;
      // left: 58.56px;
      // top: 0px;
      top: 372px;
      right: 492.44px;
      // transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    .road2 {
      // position: absolute;
      position: fixed;
      width: 121.39px;
      height: 83.71px;
      // left: 0px;
      // top: 12.05px;
      top: 543.05px;
      right: 456.61px;
    }
    .truck2 {
      // position: absolute;
      position: fixed;
      width: 57.56px;
      height: 48.38px;
      // left: 63.75px;
      // top: 0px;
      top: 531px;
      right: 456.69px;
    }

    .tier2_title {
      // position: absolute;
      position: fixed;

      // width: 120.85px;
      width: 135px;
      // width: 204.92px;
      height: 46.48px;
      // left: 1149.55px;
      // top: 260.62px;
      top: 152.62px;
      right: 665.78px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 39.0439px;
      line-height: 120%;
      /* identical to box height, or 47px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    }

    .tier2_text {
      // position: absolute;
      position: fixed;
      width: 196px;
      height: 29px;
      // left: 1043.12px;
      right: 707.14px;
      // top: 255px;
      top: 147px;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);

      margin-top: 70px;
    }

    .tier1_title {
      // position: absolute;
      position: fixed;
      // width: 115.27px;
      width: 135px;
      height: 46.48px;
      // left: 1739.86px;
      right: 80.31px;
      // top: 637.12px;
      top: 529.12px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 39.0439px;
      line-height: 120%;
      /* identical to box height, or 47px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    }
    .tier1_text {
      // position: absolute;
      position: fixed;
      width: 217px;
      height: 29px;
      // left: 1709.12px;
      // right: 22.95px;
      right: 48px;
      // top: 672px;
      top: 564px;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);

      // margin-top: 70px;
      margin-top: 60px;
    }
    .anchor_title {
      // position: absolute;
      position: fixed;
      width: 172.91px;
      height: 46.48px;
      // left: 1074.25px;
      right: 696px;
      // top: 797.94px;
      top: 689.94px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 39.0439px;
      line-height: 120%;
      /* identical to box height, or 47px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    }
    .anchor_text {
      // position: absolute;
      position: fixed;
      width: 205px;
      height: 29px;
      // left: 1035.12px;
      // right: 707.35px;
      right: 720px;
      top: 828px;
      top: 720px;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      // margin-top: 70px;
      margin-top: 55px;
    }
  }
}
