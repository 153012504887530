.d_flex{
    display: flex;
}
.mr_20{
    margin-right: 20px;
}
.mb_10{
    margin-bottom: 10px;
}
.text_center{
    text-align: center;
}
.fs_12_quick_links{
    font-size: 16px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .fs_12_quick_links{
        font-size: 12px !important;
    }
    .mr_20{
        margin-right: 15px;
    }
}