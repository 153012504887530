.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  background: linear-gradient(298.59deg, #45108A -5.2%, #3D065F 42.6%, #200554 168.28%);
}
.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.group-5 {
  position: absolute;
  left: 30.1%;
  right: 66.04%;
  top: 81.67%;
  bottom: 11.48%;
  opacity: 0.2;
  height: 74px;
  width: 74px;
}
.screen a {
    display: contents;
    text-decoration: none;
}


.frame-909 {
    align-items: center;
    display: flex;
    height: 108px;
    left:0;
    min-width: 1920px;
    padding: 0 113px;
    position: absolute;
    top: 0;
}

.nakad{
    letter-spacing: 0;
    line-height: 133px;
    min-height: 51px;
    min-width: 132px;
    white-space: nowrap;
}
.frame-908{
    align-items: center;
    display: flex;
    height: 56px;
    margin-left: 1150px;
    min-width: 412px;
    position: relative;
}
.place{
    letter-spacing: 0;
    min-height: 19px;
    min-width: 45px;
}
.life-at-nakad{
    letter-spacing: 0;
    margin-left: 48px;
    min-height: 19px;
    min-width: 107px;
}
.frame-932{
    align-items: center;
    border-radius: 8px;
    display: flex;
    height: 56px;
    margin-left: 48px;
    overflow: hidden;
    padding: 0 53px;
    width: 164px;
}
.join-us {
    letter-spacing: 0;
    min-height: 19px;
    min-width: 58px;
}
.frame-935{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 1080px;
    overflow: hidden;
    width: 1920px;
}
.frame-container {
    height: 108px;
    position: relative;
    width: 1920px;
}
.frame-935-1{
    height:1px;
    left:0;
    position: absolute;
    top:0;
    width:1px;
}
.group-container{
    height: 750px;
    margin-left:-15px;
    margin-top: 98px;
    position: relative;
    width: 1559px;
}
.overlap-group{
    height: 690px;
    left:87px;
    position: absolute;
    top: 46px;
    width: 1472px;
}
.unlocking-small-busi{
    left:95px;
    letter-spacing: -1.56px;
    line-height: 120px;
    position: absolute;
    top: 250px;
    white-space: nowrap;
    width: 670px;

}
.group{
    height: 122px;
    left:0;
    position: absolute;
    top: 183px;
    width: 121px;
}
.group-1{
    height: 54px;
    left: 652px;
    position: absolute;
    top: 323px;
    width: 55px;
}
.grouo-2{
    height: 72px;
    left: 580px;
    position: absolute;
    top: 42px;
    width: 72px;
}
.group-2186{
    height: 690px;
    left: 585px;
    position: absolute;
    top: 0px;
    width: 887px;
}
.grouo-3{
    height: 114px;
    left: 0;
    position: absolute;
    top: 0;
    width: 127px;
}
.grouo-4{
/* position: absolute; */
position: relative;
left: 6.35%;
right: 91.87%;
top: 74.07%;
bottom: 22.78%;
/* opacity: 0.1; */
height: 34px;
width: 34px;
}
/* .grouo-4{
    height: 34px;
    left: 137px;
    position: absolute;
    top: 594px;
    width: 34px;
} */
/* .grouo-5{
    height: 74px;
    left: 593px;
    position: absolute;
    top: 676px;
    width: 74px;
} */
