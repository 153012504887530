.Life_3_container {
  background: #000614;
  width: 100vw;
  // height: 100vh;
  height: 790px;
  box-sizing: border-box;

  .heading {
    // height: 15vh;
    height: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 70px;
    padding: 70px 70px 95px 70px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 103%;
    /* identical to box height, or 54px */

    text-align: center;

    /* White */

    color: #ffffff;

    .color_text {
      background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .value_container {
      // background-color: #ff008a;
      width: 950px;
    height: 70%;
    // width: 100%;
    // margin: 5px 150px;
    // margin-left: 250px;

    display: flex;
    margin: auto;
   
    // justify-content: center;

    .ver_box1 {
        // background-color: gray;
      height: 100%;
      // width: 0px;
      margin: auto 10px;

      //   .inside_box{
      //       background-color: green;
      //       width: 100%;
      //       height: 40%;
      //     //   margin: 20px auto;
      //   }
      .inside_box {
        width: 170px;
        height: 40%;
        // background-color: yellow;
        background: #1b1b1b;
        margin-bottom: 15px;
        border-radius: 10px;
        transition: transform 0.2s;
      }
    }
    .ver_box2 {
      // background-color: rgb(243, 234, 234);
      height: 100%;
      // width: 200px;
      margin: auto 10px;
      margin-bottom: 0;

      .inside_box1 {
        width: 170px;
        height: 40%;
        // background-color: yellow;
        background: #1b1b1b;
        // margin-bottom: 0px;
        margin-top: 80px;
        border-radius: 10px;
      }
      .inside_box2 {
        width: 170px;
        height: 40%;
        // background-color: yellow;
        background: #1b1b1b;
        // margin-bottom: 0px;
        margin-top: 15px;
        border-radius: 10px;
      }
    }
    .top:hover {
      // transform: scaleY(1.5);
      // transition:  .6s ease;
      // z-index: 2;
      // background: linear-gradient(141.11deg, #FF008A 17.2%, #0082FF 156.24%);
      background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
      .hustle {
        // transform: scaleY(1.5);
        position: relative;
        // margin-top: -60px;
        top: -60px;
        transform: scale(1.2); 
        transition: 0.4s ease-in-out;
        // animation-delay: 1s;
        // transition: .2s
      }
      .team {
        position: relative;
        top: -115px;
        transform: scale(1.2); 
        transition: 0.4s ease-in-out;
        
      }
      .caring {
        position: relative;
        top: -150px;
        transform: scale(1.2); 
        transition: 0.4s ease-in-out;
      }
      .name2 {
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Hustle {
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Shiddat {
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Top {
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Caring {
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      // .name2 {
      //   opacity: 0;
      //   transition: 0.8s ease-in-out;
      // }
      .name1 {
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Shiddat {
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Top {
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Caring {
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .text {
        opacity: 1;
        // transition: .5s ease-in-out;
      }
      .textShiddat {
        opacity: 1;
        // transition: .5s ease-in-out;
      }
      .textTop {
        opacity: 1;
        // transition: .5s ease-in-out;
      }
      .textCaring {
        opacity: 1;
        // transition: .5s ease-in-out;
      }
    }
    .bottom:hover {
      background: linear-gradient(141.11deg, #ff008a 17.2%, #0082ff 156.24%);
      .name2 {
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Top {
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Communication{
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Ownership{
         opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Target{
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name2Speak{
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      .name1 {
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Shiddat {
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Top {
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Communication{
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Ownership{
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Target{
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .name1Speak{
        opacity: 1;
        transition: 0.4s ease-in-out;
      }
      .text {
        opacity: 1;
        // transition: .5s ease-in-out;
      }
      .textTop {
        opacity: 1;
        // transition: .5s ease-in-out;
      }
      .textCommunication{
        opacity: 1;
        // transition: .5s ease-in-out;
      }
      .textOwnership{
        opacity: 1;
      }
      .textTarget{
        opacity: 1;
        // transition: .5s ease-in-out;
      }
      .textSpeak{
        opacity: 1;
        // transition: .5s ease-in-out;
      }
      .ownership{
        position: relative;
        // position: absolute;
        // bottom: -95px;
        // top: 90px;
        // top: 11vh;
        top:85px;
        transform: scale(1.2); 
        transition: 0.4s ease-in-out;
        // top: 10%;
      }
      .target8020{
        position: relative;
        // bottom: -95px;
        // top: 90px;
        // top: 11vh;
        top:85px;
        transform: scale(1.2); 
        transition: 0.4s ease-in-out;
      }
      .speak{
        position: relative;
        // bottom: -150px;
        top: 143px;
        // top: 18.3vh;
        transform: scale(1.2); 
        transition: 0.4s ease-in-out;
      }
      .family{
        position: relative;
        // bottom: -105px;
        top: 100px;
        // top: 12vh;
        transform: scale(1.2); 
        transition: 0.4s ease-in-out;
      }
      .communication{
        position: relative;
        // bottom: -95px;
        top: 90px;
        // top: 11vh;
        transform: scale(1.2); 
        transition: 0.4s ease-in-out;
      }
    }
    .hustle_div {
      display: flex;
      justify-content: center;
      .hustle {
        width: 100px;
        height: 100px;
        margin-top: 10px;
        // margin-left: 20px;
      }
    }

    .team_div {
      display: flex;
      justify-content: center;
      .team {
        width: 100px;
        height: 60px;
        margin-top: 50px;
        // margin-left: 20px;
      }
    }

    .caring_div {
      display: flex;
      justify-content: center;
      .caring {
        width: 110px;
        height: 90px;
        margin-top: 40px;
      }
    }

    .ownership_div {
      display: flex;
      justify-content: center;
      .ownership {
        width: 100px;
        height: 100px;
        margin-top: 50px;
        // margin-left: 20px;
      }
    }

    .target8020_div {
      display: flex;
      justify-content: center;
      .target8020 {
        width: 120px;
        height: 100px;
        margin-top: 50px;
        // margin-left: 20px;
      }
    }

    .communication_div {
      display: flex;
      justify-content: center;
      .communication {
        width: 80px;
        height: 120px;
        margin-top: 50px;
        // margin-left: 20px;
      }
    }

    .speak_div{
      display: flex;
      justify-content: center;
      .speak{
        width: 120px;
        height: 110px;
        margin-top: 10px;
      }  
    }

    .family_div{
      display: flex;
      justify-content: center;
      .family {
        width: 120px;
        height: 80px;
        margin-top: 50px;
        // margin-left: 20px;
      }
    }

    .name2 {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 20px;
      // bottom: 80px;
      // bottom: 0px;
      bottom: 25px
    }
    .name2Hustle{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 20px;
      // bottom: 80px;
      // bottom: 0px;
      bottom: 55px
    }
    .name2Shiddat{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 20px;
      // bottom: 80px;
      // bottom: 0px;
      bottom: 50px
    }
    .name2Top {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      top: 30px;
    }
    .name2Caring {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      top: 30px;
    }
    .name2Communication{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 21.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      top: 30px;
    }
    .name2Ownership{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      top: 30px;
    }
    .name2Target{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      top: 30px;
    }
    .name2Speak{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 20px;
      // bottom: 80px;
      bottom: 50px;
    }
    .name1 {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 70px;
      // bottom: 45px;
      bottom: 35px;
      opacity: 0;
    }
    .name1Shiddat{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 70px;
      // bottom: 45px;
      bottom: 50px;
      opacity: 0;
    }
    .name1Top {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 50px;
      bottom: 25px;
      opacity: 0;
    }
    .name1Caring {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      bottom: 85px;
      // bottom: 70px;
      opacity: 0;
    }
    .name1Communication{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 21.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 80px;
      bottom: 115px;
      opacity: 0;
    }
    .name1Ownership{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 60px;
      bottom: 85px;
      opacity: 0;
    }
    .name1Target{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 50px;
      // bottom: 25px;
      // bottom: 80px;
      bottom: 105px;
      opacity: 0;
    }
    .name1Speak{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22.6404px;
      line-height: 103%;
      /* identical to box height, or 23px */

      text-align: center;

      color: #ffffff;
      // bottom: 70px;
      // bottom: 45px;
      bottom: 70px;
      opacity: 0;
    }
    .text {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      /* or 19px */

      text-align: center;

      color: #ffffff;
      // bottom: 60px;
      // bottom: 25px; 
      bottom: 10px;
    margin: auto 30px;
      opacity: 0;
      // text-transform: capitalize;
    //  margin: 60px 10px 0 10px;
    }
    .textShiddat {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      /* or 19px */

      text-align: center;

      color: #ffffff;
      // bottom: 60px;
      // bottom: 25px; 
      bottom: 35px;
    margin: auto 30px;
      opacity: 0;
      // text-transform: capitalize;
    //  margin: 60px 10px 0 10px;
    }
    .textTop {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      /* or 19px */

      text-align: center;

      color: #ffffff;
      bottom: 60px;
      margin: auto 30px;
      opacity: 0;
      // text-transform: capitalize;
    }
    .textCaring {
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      /* or 19px */

      text-align: center;

      color: #ffffff;
      // bottom: 90px;
      bottom: 115px;
      opacity: 0;
      margin: auto 30px;
      // text-transform: capitalize;
    }
    .textCommunication{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      /* or 19px */

      text-align: center;

      color: #ffffff;
      // bottom: 80px;
      bottom: 140px;
    margin: auto 30px;
    // text-transform: capitalize;
      opacity: 0;
    }
    .textOwnership{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      /* or 19px */

      text-align: center;

      color: #ffffff;
      // bottom: 70px;
      // bottom: 100px;
      // margin: auto 10px;
      bottom: 120px;
    margin: auto 30px;
      opacity: 0;
      // text-transform: capitalize;
    }
    .textTarget{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      /* or 19px */

      text-align: center;

      color: #ffffff;
      // bottom: 60px;
      bottom: 120px;
    margin: auto 30px;
      opacity: 0;
      // text-transform: capitalize;
    }
    .textSpeak{
      position: relative;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      /* or 19px */

      text-align: center;

      color: #ffffff;
      // bottom: 60px;
      bottom: 35px;
    
    margin: auto 30px;
      opacity: 0;
      // text-transform: capitalize;
    //  margin: 60px 10px 0 10px;
    }
  }
}


//-------------------------//
// @media (max-height: 646px) and (max-width: 976px) {
//    .value_container{
//     .bottom:hover {
//       background: linear-gradient(141.11deg, #ff008a 17.2%, #0082ff 156.24%);
//       .name2Top {
//         opacity: 0;
//         transition: 0.8s ease-in-out;
//       }
//       .name2Communication{
//         opacity: 0;
//         transition: 0.8s ease-in-out;
//       }
//       .name2Ownership{
//          opacity: 0;
//         transition: 0.8s ease-in-out;
//       }
//       .name1Top {
//         opacity: 1;
//         transition: 0.8s ease-in-out;
//       }
//       .name1Communication{
//         opacity: 1;
//         transition: 0.8s ease-in-out;
//       }
//       .name1Ownership{
//         opacity: 1;
//         transition: 0.8s ease-in-out;
//       }
//       .name2 {
//         opacity: 0;
//         transition: 0.8s ease-in-out;
//       }
//       .name1 {
//         opacity: 1;
//         transition: 0.8s ease-in-out;
//       }
//       .text {
//         opacity: 1;
//         // transition: .5s ease-in-out;
//       }
//       .textTop {
//         opacity: 1;
//         // transition: .5s ease-in-out;
//       }
//       .textCommunication{
//         opacity: 1;
//         // transition: .5s ease-in-out;
//       }
//       .textOwnership{
//         opacity: 1;
//       }
//       .ownership{
//         position: relative;
//         // bottom: -95px;
//         top: 60px;
//         // top: 10%;
//       }
//       .target8020{
//         position: relative;
//         // bottom: -95px;
//         top: 90px;
//       }
//       .speak{
//         position: relative;
//         // bottom: -150px;
//         top: 145px;
//       }
//       .family{
//         position: relative;
//         // bottom: -105px;
//         top: 100px;
//       }
//       .communication{
//         position: relative;
//         // bottom: -95px;
//         top: 90px;
//       }
//     }
//    }
// }


/* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .Life_3_container {
    background: #000614;
    width: 100vw;
    height: 150vh;
    box-sizing: border-box;
  
    .heading {
      height: 15vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 70px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      // font-size: 52px;
      font-size: 36px;
      line-height: 103%;
      /* identical to box height, or 54px */
  
      text-align: center;
  
      /* White */
  
      color: #ffffff;
  
      .color_text {
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  
    .value_container {
        // background-color: #ff008a;
        width: 100vw;
      height: 70vh;
      
      display: block;
     
      justify-content: center;
  
      .ver_box1 {
          // background-color: gray;
        height: 30vh;
        display: flex;
        margin: auto 10px;
        margin-bottom: -5vh;
        justify-content: space-evenly;
        //   .inside_box{
        //       background-color: green;
        //       width: 100%;
        //       height: 40%;
        //     //   margin: 20px auto;
        //   }
        .inside_box {
          width: 150px;
          height: 80%;
          // background-color: yellow;
          background: #1b1b1b;
          
          // margin: 15px;
          border-radius: 10px;
          transition: transform 0.2s;
        }
      }
      .ver_box2 {
        // background-color: rgb(243, 234, 234);
        height: 30vh;
        display: flex;
        margin: auto 10px;
        margin-bottom: -3vh;
        justify-content: space-evenly;
        .inside_box1 {
          width: 150px;
          height: 80%;
          // background-color: yellow;
          background: #1b1b1b;
          // margin-bottom: 0px;
          margin-top: 10px;
          // margin: 15px;
          border-radius: 10px;
        }
        .inside_box2 {
          width: 150px;
          height: 80%;
          // background-color: yellow;
          background: #1b1b1b;
          // margin-bottom: 0px;
          margin-top: 10px;
          // margin: 15px;
          border-radius: 10px;
        }
      }
      .top:hover {
        // transform: scaleY(1.5);
        // transition:  .6s ease;
        // z-index: 2;
        // background: linear-gradient(141.11deg, #FF008A 17.2%, #0082FF 156.24%);
        // background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
        .hustle {
          // transform: scaleY(1.5);
          // position: relative;
          // margin-top: -60px;
          top: 0px;
          transform: scale(1); 
          // transition: 0.4s ease-in-out;
          // animation-delay: 1s;
          // transition: .2s
        }
        .team {
          // position: relative;
          // top: -115px;
          // transform: scale(1.2); 
          top: 0px;
          transform: scale(1); 
          // transition: 0.4s ease-in-out;
          
        }
        .caring {
          // position: relative;
          top: 0px;
          transform: scale(1); 
          // transition: 0.4s ease-in-out;
        }
        .name2 {
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        .name2Hustle {
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        .name2Shiddat {
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        .name2Caring {
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        
        .name2Top {
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        // .name2 {
        //   opacity: 0;
        //   transition: 0.8s ease-in-out;
        // }
        // .name1 {
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .name1Top {
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .name1Caring {
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .text {
        //   opacity: 1;
        //   // transition: .5s ease-in-out;
        // }
        // .textTop {
        //   opacity: 1;
        //   // transition: .5s ease-in-out;
        // }
        // .textCaring {
        //   opacity: 1;
        //   // transition: .5s ease-in-out;
        // }
      }
      .bottom:hover {
        // background: linear-gradient(141.11deg, #ff008a 17.2%, #0082ff 156.24%);
        .name2 {
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        .name2Top {
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        .name2Communication{
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        .name2Ownership{
           opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        .name2Target{
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        .name2Speak{
          opacity: 1;
          // transition: 0.4s ease-in-out;
        }
        // .name1 {
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .name1Top {
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .name1Communication{
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .name1Ownership{
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .name1Target{
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .name1Speak{
        //   opacity: 1;
        //   transition: 0.4s ease-in-out;
        // }
        // .text {
        //   opacity: 1;
        //   // transition: .5s ease-in-out;
        // }
        // .textTop {
        //   opacity: 1;
        //   // transition: .5s ease-in-out;
        // }
        // .textCommunication{
        //   opacity: 1;
        //   // transition: .5s ease-in-out;
        // }
        // .textOwnership{
        //   opacity: 1;
        // }
        // .textTarget{
        //   opacity: 1;
        //   // transition: .5s ease-in-out;
        // }
        // .textSpeak{
        //   opacity: 1;
        //   // transition: .5s ease-in-out;
        // }
        .ownership{
          // position: relative;
          // position: absolute;
          // bottom: -95px;
          // top: 90px;
          top: 0vh;
          transform: scale(1); 
          // transition: 0.4s ease-in-out;
          // top: 10%;
        }
        .target8020{
          // position: relative;
          // bottom: -95px;
          // top: 90px;
          top: 0vh;
          transform: scale(1); 
          // transition: 0.4s ease-in-out;
        }
        .speak{
          // position: relative;
          // bottom: -150px;
          // top: 145px;
          top: 0vh;
          transform: scale(1); 
          transition: 0.4s ease-in-out;
        }
        .family{
          // position: relative;
          // bottom: -105px;
          // top: 100px;
          top: 0vh;
          transform: scale(1); 
          // transition: 0.4s ease-in-out;
        }
        .communication{
          // position: relative;
          // bottom: -95px;
          // top: 90px;
          top: 0vh;
          transform: scale(1); 
          // transition: 0.4s ease-in-out;
        }
      }
      .hustle_div {
        display: flex;
        justify-content: center;
        .hustle {
          width: 100px;
          height: 100px;
          margin-top: 10px;
          // margin-left: 20px;
        }
      }
  
      .team_div {
        display: flex;
        justify-content: center;
        .team {
          width: 100px;
          height: 60px;
          margin-top: 50px;
          // margin-left: 20px;
        }
      }
  
      .caring_div {
        display: flex;
        justify-content: center;
        .caring {
          width: 110px;
          height: 90px;
          margin-top: 40px;
        }
      }
  
      .ownership_div {
        display: flex;
        justify-content: center;
        .ownership {
          width: 100px;
          height: 100px;
          margin-top: 50px;
          // margin-left: 20px;
        }
      }
  
      .target8020_div {
        display: flex;
        justify-content: center;
        .target8020 {
          width: 120px;
          height: 100px;
          margin-top: 50px;
          // margin-left: 20px;
        }
      }
  
      .communication_div {
        display: flex;
        justify-content: center;
        .communication {
          width: 80px;
          height: 100px;
          margin-top: 50px;
          // margin-left: 20px;
        }
      }
  
      .speak_div{
        display: flex;
        justify-content: center;
        .speak{
          width: 120px;
          height: 110px;
          margin-top: 10px;
        }  
      }
  
      .family_div{
        display: flex;
        justify-content: center;
        .family {
          width: 120px;
          height: 80px;
          margin-top: 50px;
          // margin-left: 20px;
        }
      }
  
      .name2 {
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 20px;
        // bottom: 80px;
        // bottom: 0px;
        // bottom: 25px;
        bottom: -25px;
      }
      .name2Hustle{
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 20px;
        // bottom: 80px;
        // bottom: 0px;
        // bottom: 25px;
        bottom: -25px;
      }
      .name2Shiddat{
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 20px;
        // bottom: 80px;
        // bottom: 0px;
        // bottom: 25px;
        bottom: -25px;
      }
      .name2Top {
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        top: 30px;
      }
      .name2Caring {
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        top: 30px;
      }
      .name2Communication{
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        top: 30px;
      }
      .name2Ownership{
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        top: 30px;
      }
      .name2Target{
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        top: 30px;
      }
      .name2Speak{
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
         font-size: 20px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 20px;
        // bottom: 80px;
        // bottom: 20px;
        bottom: -18px;
      }
      .name1 {
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22.6404px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 70px;
        // bottom: 45px;
        bottom: 35px;
        opacity: 0;
      }
      .name1Shiddat {
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22.6404px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 70px;
        // bottom: 45px;
        bottom: 50px;
        opacity: 0;
      }
      .name1Top {
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22.6404px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 50px;
        bottom: 25px;
        opacity: 0;
      }
      .name1Caring {
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22.6404px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        bottom: 85px;
        // bottom: 70px;
        opacity: 0;
      }
      .name1Communication{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22.6404px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 80px;
        bottom: 115px;
        opacity: 0;
      }
      .name1Ownership{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22.6404px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 60px;
        bottom: 85px;
        opacity: 0;
      }
      .name1Target{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22.6404px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 50px;
        // bottom: 25px;
        bottom: 80px;
        opacity: 0;
      }
      .name1Speak{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22.6404px;
        line-height: 103%;
        /* identical to box height, or 23px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 70px;
        // bottom: 45px;
        bottom: 70px;
        opacity: 0;
      }
      .text {
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* or 19px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 60px;
        // bottom: 25px; 
        bottom: 10px;
      margin: auto 30px;
        opacity: 0;
        // text-transform: capitalize;
      //  margin: 60px 10px 0 10px;
      }
      .textShiddat{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* or 19px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 60px;
        // bottom: 25px; 
        bottom: 10px;
      margin: auto 30px;
        opacity: 0;
      }
      .textTop {
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* or 19px */
  
        text-align: center;
  
        color: #ffffff;
        bottom: 60px;
        margin: auto 30px;
        opacity: 0;
        // text-transform: capitalize;
      }
      .textCaring {
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* or 19px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 90px;
        bottom: 100px;
        opacity: 0;
        margin: auto 30px;
        // text-transform: capitalize;
      }
      .textCommunication{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* or 19px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 80px;
        bottom: 140px;
      margin: auto 30px;
      // text-transform: capitalize;
        opacity: 0;
      }
      .textOwnership{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* or 19px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 70px;
        // bottom: 100px;
        // margin: auto 10px;
        bottom: 120px;
      margin: auto 30px;
        opacity: 0;
        // text-transform: capitalize;
      }
      .textTarget{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* or 19px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 60px;
        bottom: 120px;
      margin: auto 30px;
        opacity: 0;
        // text-transform: capitalize;
      }
      .textSpeak{
        display: none;
        position: relative;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        /* or 19px */
  
        text-align: center;
  
        color: #ffffff;
        // bottom: 60px;
        bottom: 35px;
      
      margin: auto 30px;
        opacity: 0;
        // text-transform: capitalize;
      //  margin: 60px 10px 0 10px;
      }
    }
  }
}