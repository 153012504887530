.testimonials_container{
    background: linear-gradient(90deg, #7A1C72 0%, #581953 100%);
    .center_align{
        display: flex;
        justify-content: center;
    }
    .pb_60{
        padding-bottom: 60px;
      }
    .rect_line{
        width: 80%;
height: 1px;
background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF02EB 50%, rgba(122, 28, 114, 0.00) 100%);
    }
    .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 70px;
        padding: 70px 70px 70px 70px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 103%;
        /* identical to box height, or 54px */
    
        text-align: center;
    
        /* White */
    
        color: #ffffff;
  
        .rect_line_left{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
  margin-right: 9px;
        }
        .rect_line_right{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
  margin-left: 9px;
        }
        .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .container{
        // width: 80%;
        width: 1120px;
        .card{

// height: 407px;
// padding: 30px;
border-radius: 20px;
background: #FFF;
box-shadow: 4px 0px 33.7px 0px rgba(0, 0, 0, 0.16);

.card-body{
    height: 366px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

            .testimonials_text{
                color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.2px;
// margin-bottom: 40px;
            }
           
            .testimonials_name{
                color: #E200B2;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
margin-bottom: 0;
            }
            .testimonials_comp{
                color: #2B2B2B;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.16px;
margin-bottom: 0;
            }
        }
      }
}
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .testimonials_container{
        .heading {
            display: flex;
            align-items: center;
            justify-content: center;
            // padding: 70px;
            padding: 40px 30px 40px 30px;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 103%;
            /* identical to box height, or 54px */
        
            text-align: center;
        
            /* White */
        
            color: #ffffff;
      
            .rect_line_left{
                width: 39.873px;
                height: 3.418px;
      background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
      margin-right: 4px;
            }
            .rect_line_right{
                width: 39.873px;
                height: 3.418px;
      background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
      margin-right: 4px;
            }
        
            .color_text {
                background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        .container{
            width: 90%;
            .card{
    
                margin-top: 15px;
    .card-body{
        height: 230px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .testimonials_text{
        color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.2px;
// margin-bottom: 40px;
    }
    .testimonials_name{
        color: #E200B2;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
margin-bottom: 0;
    }
    .testimonials_comp{
        color: #2B2B2B;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.16px;
margin-bottom: 0;
    }
}}
        }
  }
   
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .testimonials_container{
        .container{
            width: 90%;
            .card{
    
                margin-top: 15px;
    .card-body{
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }}}
        }
  }
   
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .testimonials_container{
        .container{
            width: 90%;
            .card{
                margin-top: 15px;
    
    .card-body{
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }}}
        }

  }
   
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    /* Styles for Large devices */
    .testimonials_container{
        .container{
            // width: 80%;
            width: 960px;
            .card{
    
    // // height: 407px;
    // padding: 30px;
    // border-radius: 20px;
    // background: #FFF;
    // box-shadow: 4px 0px 33.7px 0px rgba(0, 0, 0, 0.16);
    
    .card-body{
        height: 460px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }}}
        }
  }
   
  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) and (max-width: 1399.98px) {
   
  }