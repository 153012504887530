.pricing_container_outer{
    background: linear-gradient(90deg, #7A1C72 0%, hsl(305, 56%, 22%) 100%);
.pricing_container{
    // background: linear-gradient(90deg, #7A1C72 0%, hsl(305, 56%, 22%) 100%);
    background-image: url('../../assetsPng/Arp/Vector.svg');
    background-size: cover; /* Cover the entire container */
    background-position: center;
    // fill: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.12) 100%);
    .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 70px;
        padding: 70px 70px 32px 70px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: normal;
        /* identical to box height, or 54px */
    
        text-align: center;
    
        /* White */
    
        color: #ffffff;
  
        .rect_line_left{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
  margin-right: 9px;
        }
        .rect_line_right{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
  margin-left: 9px;
        }
    
        .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }


      .pricing_text{
        color: #FFF;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.32px;
text-align: center;
margin-bottom: 80px;
      }

      .center_align{
        display: flex;
        justify-content: center;
    }
    .rect_line{
        width: 80%;
height: 1px;
background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF02EB 50%, rgba(122, 28, 114, 0.00) 100%);
    }
}}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .pricing_container_outer{
        .pricing_container{
            .heading {
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 70px;
                padding: 40px 30px 40px 30px;
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: normal;
                /* identical to box height, or 54px */
            
                text-align: center;
            
                /* White */
            
                color: #ffffff;
          
                .rect_line_left{
                    width: 39.873px;
                    height: 3.418px;
          background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
          margin-right: 4px;
                }
                .rect_line_right{
                    width: 39.873px;
                    height: 3.418px;
          background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
          margin-right: 4px;
                }
            
                .color_text {
                    background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
              .pricing_text{
                color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.32px;
        text-align: center;
        margin-bottom: 80px;
        padding: 0 30px;
              }
        
        }
    }
  }