.Page_8_container {
  width: 1920px;
  height: 2166px;
  background: #000614;
  .text_container {
    position: absolute;
    width: 729px;
    height: 236px;
    left: 190px;
    top: 150px;
    .text {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 52px;
      line-height: 120%;
      /* or 62px */

      letter-spacing: -0.03em;

      color: #ffffff;

      .color_text {
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
