.disclaimer_sec{
    margin: 20px 40px;
}
.fs_18{
    font-size: 18px;
}
/* Media Query for Mobile Devices  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .disclaimer_sec{
        margin: 15px 15px;
    }
}