.privacy_header{
    margin-top: 120px;
    font-size: 40px;
    padding: 10px;
    // text-decoration: underline;
}
.privacy_sub_header_section{
.privacy_sub_header{
    font-size: 20px;
    padding-top: 20px;
    font-weight: bold;
}
li{
    padding: 6px 0 ;
    font-size: 18px ;
}
.without_li_text{
    padding: 6px 0 ;
    font-size: 18px ;
}
}
.card_shadow{
    box-shadow: 0 26px 95px rgba(0,0,0,.1)
}
.cursor_pointer{
    cursor: pointer;
}
ol {
    font-size: 20px;
  }
.mr_30{
    margin-right: 30px;
}
.text_align_justify{
text-align: justify;
}

  /* Media Query for Mobile Devices  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .privacy_header{
        margin-top: 80px;
        font-size: 34px;
        padding: 10px;
        // text-decoration: underline;
    }
}