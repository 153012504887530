.client_container{

    .slider-container-div{
        // div{
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            height: 110px;
        // }
    }

// .client_center_align{
   
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // padding: 10px;
//     // text-align: center;
//     height: 110px;

//     // div{
//     //     display: flex;
//     //     justify-content: center;
//     //     align-items: center;
//     // }
   
// }
.bg_prp{
    background: linear-gradient(90deg, #7A1C72 0%, #581953 100%);
    // height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 160px;

    .center_align_client_box{
display: flex;
justify-content: center;
    }

    .client_box{
        display: flex;
width: 300px;
// width: 18.75vw;
height: 228px;
padding: 0px 29px;
flex-direction: column;
// justify-content: center;
align-items: flex-start;
// gap: 12px;
border-radius: 22px;
border: 2px solid #FF018A;

.number{
    color:  #F5C03C;
font-family: Inter;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.48px;
}
.text{
    color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 100px;
}
    }
    .mr_20{
        margin-right: 20px;
    }
    .mt_30{
        margin-top: 30px;
    }
    .mt_12{
        margin-top: 12px;
    }
}

.bg_white{
    background: linear-gradient(90deg, #7A1C72 0%, #581953 100%);
    display: flex;
    justify-content: center;
  
    align-items: center;
  


// max-width: 960px;
.section_43_bh{
    color: #DB1E5C;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    margin-left: 13px;
    width: 160px;
}
.section_43_bh_text{
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.2px;
}
.bg_white_border{
    display: flex;
    border-radius: 9px;
border: 1px solid hsl(340, 76%, 49%);
padding: 16px 23px;
gap: 33px;
background: #FFF;
margin-bottom: 60px;
max-width: 960px;

}
.center_align{
    display: flex;
    align-items: center;
    justify-content: center;
}
}
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .client_container{
        .slider-container-div{
            // div{
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                height: 72px;
            // }
        }
        .bg_prp{
            padding-top: 60px;
            padding-bottom: 60px;
            .client_box{
                display: flex;
                width: 130px;
                height: 98.4px;
                padding: 0px 12.516px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 5.179px;
                flex-shrink: 0;

                .number{
                    color:  #F5C03C;
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -0.2px;
                }
                .text{
                    color: #FFF;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    width: 60px;
                }
            }
            .mt_30{
                margin-top: 0;
            }
            .mr_20{
                margin-right: 0px;
            }
            .client1{
                width: 70%;
            }
            .client2{
                width: 55%;
            }
            .client3{
                width: 55%;
            }
            .client4{
                width: 60%;
            }
        }

            .bg_white{
                .bg_white_border{
                    display: block;
                    margin-left: 10px;
                margin-right: 10px;
                }
                
                .section_43_bh_text{
                    margin-top: 10px;
                    padding: 5px;
                    
                }
                .center_align{
                   float: left;
                }
            }
        }
  }
   
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .client_container{
        .bg_prp{
            padding-top: 80px;
            padding-bottom: 80px;
            .client_box{
                margin-bottom: 25px;
                width: 240px;
                height: 182.4px;

                .number{
                    color:  #F5C03C;
                font-family: Inter;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.48px;
                }
                
            }
            .client1{
                width: 70%;
            }
            .client2{
                width: 55%;
            }
            .client3{
                width: 55%;
            }
            .client4{
                width: 60%;
            }
        }

            .bg_white{
                .bg_white_border{
                    display: block;
                    margin-left: 10px;
                margin-right: 10px;
                }
                
                .section_43_bh_text{
                    margin-top: 10px;
                    padding: 5px;
                    
                }
                .center_align{
                   float: left;
                }
            }
        }
  }
   
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .client_container{
        .bg_prp{
            padding-top: 80px;
            padding-bottom: 80px;
            .client_box{
                margin-bottom: 25px;
                
            }}}
            .bg_white{
                .bg_white_border{
                    margin-left: 10px;
                margin-right: 10px;
                }
            }
  }
   
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .client_container{
        .bg_prp{
            padding-top: 80px;
            padding-bottom: 80px;
            .client_box{
                margin-bottom: 25px;
                
            }}}
  }
   
  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) and (max-width: 1399.98px) {
   .client_container{
.bg_prp{
    .client_box{
        width: 270px;
        height: 205.2px;

        .number{
            color:  #F5C03C;
        font-family: Inter;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.48px;
        }
    }
    .client1{
        width: 70%;
    }
    .client2{
        width: 55%;
    }
    .client3{
        width: 55%;
    }
    .client4{
        width: 60%;
    }
}

   }
  }