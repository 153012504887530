.Life_6_container {
  background: #000614;
  width: 100%;
  // height: 100vh;
  box-sizing: border-box;
  // background: yellow;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 70px;
    padding: 50px 70px 40px 70px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 103%;
    /* identical to box height, or 54px */

    text-align: center;

    /* White */

    color: #ffffff;

    .color_text {
      background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .journey_container {
    width: 100vw;
    height: 70%;

    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 140%;
      /* or 50px */

      text-align: center;

      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 20px;
    }
    .img_box_holy {
      display: flex;
      width: 200vw;
      // transform: translateX(+%);
      transition: 1s ease-in-out 0.2s;
      .image {
        width: 100vw;
        height: 50%;
      }
    }
    .img_box_future{
      display: flex;
      width: 200vw;
      transform: translateX(-50%);
      transition:  1s ease-in-out 0.2s;
      .image {
        width: 100vw;
        height: 50%;
      }
    }
    .btn_box {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .btn {
        border: 2px solid #ffffff;
        border-radius: 120px;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 103%;
          padding: 8px;
          /* or 16px */

          text-align: center;
          text-transform: uppercase;

          color: #ffffff;
        }
        .arrow_end{
          transition: 1s ease-in-out 0.2s;
        }
        .arrow_start{
          transform: translateX(-150px);
          
          transition:  1s ease-in-out 0.2s;
        }
      }
    }
  }

  .journey_container_mobile{
    display: none;
  }
}


/* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .Life_6_container {
    background: #000614;
    width: 100vw;
    height: 60vh;
    box-sizing: border-box;
  
    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 70px;
      padding: 70px 70px 50px 70px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      // font-size: 42px;
      font-size: 36px;
      line-height: 103%;
      /* identical to box height, or 54px */
  
      text-align: center;
  
      /* White */
  
      color: #ffffff;
  
      .color_text {
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }


    .journey_container {
      width: 100vw;
      height: 70vh;
      display: none;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 140%;
        /* or 50px */
  
        text-align: center;
  
        color: rgba(255, 255, 255, 0.7);
      }
      .img_box_holy {
        display: flex;
        width: 200vw;
        // transform: translateX(+%);
        transition: 1s ease-in-out 0.2s;
        .image {
          width: 100vw;
          height: 50vh;
        }
      }
      .img_box_future{
        display: flex;
        width: 200vw;
        transform: translateX(-50%);
        transition:  1s ease-in-out 0.2s;
        .image {
          width: 100vw;
          height: 50vh;
        }
      }
      .btn_box {
        display: flex;
        justify-content: center;
        margin-top: 40px;
  
        .btn {
          border: 2px solid #ffffff;
          border-radius: 120px;
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 103%;
            padding: 8px;
            /* or 16px */
  
            text-align: center;
            text-transform: uppercase;
  
            color: #ffffff;
          }
          .arrow_end{
            transition: 1s ease-in-out 0.2s;
          }
          .arrow_start{
            transform: translateX(-150px);
            
            transition:  1s ease-in-out 0.2s;
          }
        }
      }
    }

    .journey_container_mobile{
      display: flex;
      width: 100vw;
      height: 52vh;

      .img_container{
        display: flex;
        overflow-x: auto;

        .image {
          // width: 230vw;
          width: 270vw;
          // width: 300vw;
          height: 45vh;
        }
      }
      .img_container::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    }

  }
  
}