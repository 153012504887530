.page_1_container {
  width: 1920px;
  height: 1080px;
  display: flex;
  position: relative;
  // transition-timing-function: ease-out;
  // transition-delay: 100s;

  .left {
    width: 739px;
    height: 750px;
    left: -15px;
    // top: 206px;
    top: 98px;
    margin-top: 98px;

    .group1 {
      width: 126.99px;
      height: 114px;
      // // margin-top: 98px;
      // margin-left: -15px;
      // position: fixed;
      position: absolute;
      left: -0.78%;
      right: 94.17%;
      top: 19.07%;
      bottom: 70.37%;
    }
    .group2 {
      width: 72px;
      height: 72px;
      // margin-left: 652px;
      // position: fixed;

      position: absolute;
      left: 33.96%;
      right: 62.29%;
      top: 27.22%;
      bottom: 66.11%;
    }
    .group3 {
      // position: absolute;
      width: 87.73px;
      height: 84.88px;
      // // position: absolute;
      // position: fixed;
      // left: 72px;

      position: absolute;
      top: 339px;
      left: 72px;
      // left: 3.75%;
      // right: 91.68%;
      // top: 37.74%;
      // bottom: 54.4%;
    }
    // .group4{
    //   width: 34px;
    //   height: 34px;
    //   right: 122px;
    //   top: 692px;
    //   position: absolute;
    // }
    .group5 {
      width: 34px;
      height: 34px;
      // left: 122px;
      // top: 692px;
      // // position: absolute;
      // position: fixed;

      position: absolute;
      left: 6.35%;
      right: 91.87%;
      top: 74.07%;
      bottom: 22.78%;
    }
    .group6 {
      width: 74px;
      height: 74px;
      // left: 572px;
      // top: 774px;
      // // position: absolute;
      // position: fixed;

      position: absolute;
      left: 30.1%;
      right: 66.04%;
      top: 81.67%;
      bottom: 11.48%;
    }

    .text_container {
      position: absolute;
      // position: fixed;
      width: 670px;
      height: 124px;
      left: 167px;
      top: 405px;
      // top: 297px;

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 120%;
        /* or 62px */

        letter-spacing: -0.03em;

        color: #ffffff;

        .color_text {
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .text_box {
      position: absolute;
      width: 663px;
      height: 257px;
      left: 164px;
      top: 559px;

      background: rgba(77, 29, 110, 0.56);
      border-radius: 0px 12px 12px 0px;

      .ver_box {
        // position: absolute;
        width: 9.77px;
        height: 223.02px;
        // left: 164px;
        // top: 575.99px;

        position: relative;
        top: 16.99px;

        background: linear-gradient(144.98deg, #ff008a 16.34%, #0082ff 77.38%);
        border-radius: 0px 29px 29px 0px;
      }
      .text {
        position: absolute;
        width: 575px;
        height: 108px;
        // left: 201px;
        // top: 585px;

        left: 37px;
        top: 6px;
        bottom: 15px;

        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 225%;
        /* or 54px */

        letter-spacing: 0.105em;

        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .right {
    // position: absolute;
    // width: 887.05px;
    // height: 689.99px;
    // left: 1010px;

    // position: absolute;
    // width: 887.05px;
    // height: 689.99px;
    // left: 1010px;
    // top: 255px;

    // top: 255px;
    // top: 147px;
    // margin-top: 98px;

    // .tier2 {
    //   // position: absolute;
    //   position: fixed;
    //   width: 350.05px;
    //   height: 258.47px;
    //   // left: 1118.96px;
    //   right: 451px;
    //   // top: 344.58px;
    //   top: 236.58px;
    // }

    // .tier1 {
    //   // position: absolute;
    //   position: fixed;
    //   width: 350.05px;
    //   height: 258.47px;
    //   // left: 1362.22px;
    //   right: 207.73px;
    //   // top: 503.59px;
    //   top: 395.59px;
    // }
    // .anchor {
    //   // position: absolute;
    //   position: fixed;
    //   width: 350.9px;
    //   height: 258.47px;
    //   // left: 1102.79px;
    //   right: 466.3px;
    //   // top: 657.57px;
    //   top: 549.57px;
    // }

    // .road1 {
    //   // position: absolute;
    //   position: fixed;
    //   width: 121.39px;
    //   height: 83.71px;
    //   // left: 122.31px;
    //   // top: 12.05px;
    //   right: 428.69px;
    //   top: 384.05px;
    // }
    // .truck1 {
    //   // position: absolute;
    //   position: fixed;
    //   width: 57.56px;
    //   height: 48.38px;
    //   // left: 58.56px;
    //   // top: 0px;
    //   top: 372px;
    //   right: 492.44px;
    //   // transform: matrix(-1, 0, 0, 1, 0, 0);
    // }
    // .road2 {
    //   // position: absolute;
    //   position: fixed;
    //   width: 121.39px;
    //   height: 83.71px;
    //   // left: 0px;
    //   // top: 12.05px;
    //   top: 543.05px;
    //   right: 456.61px;
    // }
    // .truck2 {
    //   // position: absolute;
    //   position: fixed;
    //   width: 57.56px;
    //   height: 48.38px;
    //   // left: 63.75px;
    //   // top: 0px;
    //   top: 531px;
    //   right: 456.69px;
    // }

    // .tier2_title {
    //   // position: absolute;
    //   position: fixed;

    //   // width: 120.85px;
    //   width: 135px;
    //   // width: 204.92px;
    //   height: 46.48px;
    //   // left: 1149.55px;
    //   // top: 260.62px;
    //   top: 152.62px;
    //   right: 665.78px;

    //   font-family: Inter;
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 39.0439px;
    //   line-height: 120%;
    //   /* identical to box height, or 47px */

    //   color: rgba(255, 255, 255, 0.4);

    //   text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
    //   transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    // }

    // .tier2_text {
    //   // position: absolute;
    //   position: fixed;
    //   width: 196px;
    //   height: 29px;
    //   // left: 1043.12px;
    //   right: 707.14px;
    //   // top: 255px;
    //   top: 147px;

    //   font-family: Inter;
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 24px;
    //   line-height: 120%;
    //   /* identical to box height, or 29px */

    //   color: rgba(255, 255, 255, 0.4);

    //   text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
    //   transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);

    //   margin-top: 70px;
    // }

    // .tier1_title {
    //   // position: absolute;
    //   position: fixed;
    //   // width: 115.27px;
    //   width: 135px;
    //   height: 46.48px;
    //   // left: 1739.86px;
    //   right: 80.31px;
    //   // top: 637.12px;
    //   top: 529.12px;

    //   font-family: Inter;
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 39.0439px;
    //   line-height: 120%;
    //   /* identical to box height, or 47px */

    //   color: rgba(255, 255, 255, 0.4);

    //   text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
    //   transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    // }
    // .tier1_text {
    //   // position: absolute;
    //   position: fixed;
    //   width: 217px;
    //   height: 29px;
    //   // left: 1709.12px;
    //   // right: 22.95px;
    //   right: 48px;
    //   // top: 672px;
    //   top: 564px;

    //   font-family: Inter;
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 24px;
    //   line-height: 120%;
    //   /* identical to box height, or 29px */

    //   color: rgba(255, 255, 255, 0.4);

    //   text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
    //   transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);

    //   // margin-top: 70px;
    //   margin-top: 60px;
    // }
    // .anchor_title {
    //   // position: absolute;
    //   position: fixed;
    //   width: 172.91px;
    //   height: 46.48px;
    //   // left: 1074.25px;
    //   right: 696px;
    //   // top: 797.94px;
    //   top: 689.94px;

    //   font-family: Inter;
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 39.0439px;
    //   line-height: 120%;
    //   /* identical to box height, or 47px */

    //   color: rgba(255, 255, 255, 0.4);

    //   text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
    //   transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    // }
    // .anchor_text {
    //   // position: absolute;
    //   position: fixed;
    //   width: 205px;
    //   height: 29px;
    //   // left: 1035.12px;
    //   // right: 707.35px;
    //   right: 720px;
    //   top: 828px;
    //   top: 720px;

    //   font-family: Inter;
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 24px;
    //   line-height: 120%;
    //   /* identical to box height, or 29px */

    //   color: rgba(255, 255, 255, 0.4);

    //   text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
    //   transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    //   // margin-top: 70px;
    //   margin-top: 55px;
    // }

    // new

    .tier2 {
      // position: absolute;
      // position: fixed;
      // width: 350.05px;
      // height: 258.47px;
      // // left: 1118.96px;
      // right: 451px;
      // // top: 344.58px;
      // top: 236.58px;

      position: absolute;
      width: 350.05px;
      height: 258.47px;
      // left: 1118.96px;
      right: 451px;
      top: 344.58px;
    }
    .road1 {
      // position: absolute;
      // position: fixed;
      // width: 121.39px;
      // height: 83.71px;
      // // left: 122.31px;
      // right: 428.69px;
      // // top: 12.05px;
      // // top: 492.05px;
      // top: 384.05px;

      position: absolute;
      width: 121.39px;
      height: 83.71px;
      // left: 122.31px;
      right: 428.69px;
      // top: 12.05px;
      top: 492.05px;
    }
    .truck1 {
      position: absolute;
      // position: fixed;
      right: 492.44px;
      top: 480px;
      // top: 372px;
    }
    .tier1 {
      // position: absolute;
      // position: fixed;
      // width: 350.05px;
      // height: 258.47px;
      // // left: 1362.22px;
      // right: 207.73px;
      // // top: 503.59px;
      // top: 395.59px;

      position: absolute;
      width: 350.05px;
      height: 258.47px;
      // left: 1362.22px;
      right: 207.73px;
      top: 503.59px;
    }
    .road2 {
      position: absolute;
      // position: fixed;
      width: 121.39px;
      height: 83.71px;
      // left: 0px;
      // top: 12.05px;
      top: 651.05px;
      // top: 543.05px;
      right: 456.61px;
    }
    .truck2 {
      position: absolute;
      // position: fixed;
      width: 57.56px;
      height: 48.38px;
      // left: 63.75px;
      // top: 0px;
      right: 456.69px;
      top: 639px;
      // top: 531px;
    }
    .anchor {
      // position: absolute;
      // position: fixed;
      // width: 350.9px;
      // height: 258.47px;
      // // left: 1102.79px;
      // right: 466.3px;
      // // top: 657.57px;
      // top: 549.57px;

      position: absolute;
      width: 350.9px;
      height: 258.47px;
      // left: 1102.79px;
      right: 466.3px;
      top: 657.57px;
    }
    .lender {
      position: absolute;
      // position: fixed;
      top: 349.45px;
      // top: 241.45px;
      right: 700.1px;
    }
    .tier2_title {
      position: absolute;
      // position: fixed;
      width: 130.85px;
      height: 46.48px;
      // left: 1149.55px;
      right: 665.78px;
      top: 296px;
      // top: 152.62px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 39.0439px;
      line-height: 120%;
      /* identical to box height, or 47px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    }
    .tier2_text {
      position: absolute;
      // position: fixed;
      width: 196px;
      height: 29px;
      // left: 1043.12px;
      right: 707.14px;
      top: 255px;
      // top: 147px;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      margin-top: 70px;
    }
    .tier1_title {
      position: absolute;
      // position: fixed;
      width: 125px;
      height: 46.48px;
      // left: 1739.86px;
      right: 80.31px;
      top: 672.12px;
      // top: 529.12px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 39.0439px;
      line-height: 120%;
      /* identical to box height, or 47px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    }
    .tier1_text {
      position: absolute;
      // position: fixed;
      width: 200px;
      height: 29px;
      // left: 1709.12px;
      right: 55px;
      top: 672px;
      // top: 564px;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      margin-top: 60px;
    }
    .anchor_title {
      position: absolute;
      // position: fixed;
      width: 172.91px;
      height: 46.48px;
      // left: 1074.25px;
      right: 696px;
      top: 827.94px;
      // top: 689.94px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 39.0439px;
      line-height: 120%;
      /* identical to box height, or 47px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    }
    .anchor_text {
      position: absolute;
      // position: fixed;
      width: 205px;
      height: 29px;
      // left: 1035.12px;
      right: 707.35px;
      top: 828px;
      // top: 720px;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      margin-top: 50px;
    }
    .lender_title {
      position: absolute;
      width: 165px;
      height: 50px;
      // left: 788.3px;
      right: 988.8px;
      top: 630px;
      // top: 522px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 120%;
      /* or 50px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
    }
    .lender_text {
      position: absolute;
      width: 273px;
      height: 29px;
      // left: 751.12px;
      right: 932.46px;
      top: 655px;
      // top: 547px;

      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      /* identical to box height, or 29px */

      color: rgba(255, 255, 255, 0.4);

      text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);

      margin-top: 80px;
    }
    .arrow1 {
      position: absolute;
      width: 296.76px;
      height: 239px;
      // top: 215px;
      top: 323px;
      right: 215.24px;
      //  transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    .arrow2 {
      position: absolute;
      width: 296.76px;
      height: 239px;
      // top: 587px;
      top: 695px;
      right: 215.24px;
    }
    .arrow3 {
      position: absolute;
      width: 296.76px;
      height: 239px;
      // top: 176px;
      top: 311.23px;
      right: 839.24px;
    }
  }
}
