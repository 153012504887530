.Arp_service_container {
    background: linear-gradient(90deg, #7A1C72 0%, #581953 100%);
    width: 100vw;
    // height: 100vh;
    box-sizing: border-box;

    .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 70px;
        padding: 88px 70px 20px 70px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: normal;
        /* identical to box height, or 54px */
    
        text-align: center;
    
        /* White */
    
        color: #ffffff;
  
        .rect_line_left{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
  margin-right: 9px;
        }
        .rect_line_right{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
  margin-left: 9px;
        }
    
   
        .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
  
    .bottom {
    //   height: 90vh;
      display: flex;
      .left {
        width: 50vw;
        // margin-top: 172px;
        // margin-left: 70px;
        // margin-top: 8.96vw; /* Set margin-top in vw */
        margin-top: 24vh;
        margin-left: 5vw; 
        .img_box {
          display: flex;
        //   margin-top: 172px;
        //   margin-left: 70px;
          align-items: center;

          .arp_img_bg{
            display: flex;
        width: 100px;
        height: 100px;
        padding: 14.642px 11.526px 25.234px 12.461px;
        justify-content: center;
        align-items: center;
        border-radius: 14.679px;
        border: 1.558px solid #6C1866;
        background: linear-gradient(135deg, #53184E 0%, #7A1C72 100%);
        box-shadow: 1.835px 2.294px 1.246px 0px rgba(0, 0, 0, 0.25);
        
        }
        .arp_text{
            color: #FFF;
        font-family: Inter;
        // font-size: 56px;
        font-size: 2.92vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 16px;
        }
        }
        .service_text{
            color: #FF83F5;
            font-family: Inter;
            // font-size: 32px;
            font-size: 1.67vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 16px;
        }
        .service_text_line{
            margin-top: 16px;
            height: 6px;
            width: 610px;
            width: 31.77vw;
            align-self: stretch;
            border-radius: 22px;
            background: linear-gradient(90deg, #FF83F5 0%, rgba(122, 28, 114, 0.00) 98%);
        }
        .text_box {
          color: #ffffff;
          margin-top: 40px;
          // width: 713px;
          // height: 155px;
        //   margin-top: 50px;
        //   margin-left: 70px;
        color: #FFF;
        font-family: Inter;
        font-size: 2.92vw;
        line-height: 103%;
        /* or 87px */

        letter-spacing: -0.02em;
        font-weight: 700;
        line-height: normal;

        .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        }
        .mobile-br{
          display: none;
        }
      }
      .right {
        width: 50vw;
        .boyiImg {
          margin: 50px auto;
          display: flex;
          align-items: center;
          // width: 30vw;
          // height: 80vh;
        //   width: 28vw;
        //   height: 70vh;
          /* margin-top: 20vh; */
          /* margin: 156px; */
        //   margin-top: 20vh;
        //   margin-left: 16vw;

        // margin-top: 132px;
        // margin-top: 6.875vw;

        // margin-top: 20vh;
        margin-top: 10vh;
        margin-left: 0px;


        }
      }
  
      .boyiImg {
        // width: 30vw;
        // height: 70vh;

//         width: 100%; /* Set the width to 100% */
//   height: auto; /* Let the height adjust automatically based on the aspect ratio */
//   max-width: 751px; /* Set the maximum width to maintain aspect ratio */
//   max-height: 536px; /* Set the maximum height to maintain aspect ratio */
      }
    }
  }
  .trusted_text{
    color: #DC46D0;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
padding-bottom: 32px;
  }
  /* Media Query for Mobile Devices */
  @media (max-width: 767px) {
    .Arp_service_container {
      // background: #2156d2;
      width: 100vw;
    //   height: 100vh;
      box-sizing: border-box;

      .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 70px;
        padding: 20px 30px 40px 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: normal;
        /* identical to box height, or 54px */
    
        text-align: center;
    
        /* White */
    
        color: #ffffff;
  
        .rect_line_left{
            width: 39.873px;
            height: 3.418px;
  background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
  margin-right: 4px;
        }
        .rect_line_right{
            width: 39.873px;
            height: 3.418px;
  background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
  margin-left: 4px;
        }
    

        .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      
      .bottom {
        // height: 90vh;
        display: block;
        .left {
          width: 100vw;
          padding-top: 15vh;
          margin-top: 0;
          margin-left: 0px;
    padding-left: 5vw;
          .img_box {
            display: flex;
            // margin-top: 80px;
            // margin-left: 50px;

            // margin-top: 0vh;
            // margin-left: 0vw;
            // display: none;
            .arp_img_bg{
                display: flex;
                width: 48px;
                height: 48px;
                padding: 7.028px 5.533px 12.112px 5.981px;
                justify-content: center;
                align-items: center;
                border-radius: 7.046px;
border: 0.748px solid #6C1866;
background: linear-gradient(135deg, #53184E 0%, #7A1C72 100%);
box-shadow: 0.881px 1.101px 0.598px 0px rgba(0, 0, 0, 0.25);
            
img{
    width: 36.486px;
height: 28.86px;
}
            }
            .arp_text{
                color: #FFF;
                font-family: Inter;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
          }
          .service_text{
            color: #FF83F5;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
        }
        .service_text_line{
            margin-top: 16px;
            height: 6px;
            width: 65%;
            // width: 31.77vw;
            align-self: stretch;
            border-radius: 22px;
            background: linear-gradient(90deg, #FF83F5 0%, rgba(122, 28, 114, 0.00) 98%);
        }
          // .cloud2{
          //     margin-top: 80px;
          //     margin-left: 50px;
          //     margin-right: 70px;
          // }
          .text_box {
            color: #ffffff;
            margin-top: 40px;
            // width: 713px;
            // height: 155px;
          //   margin-top: 50px;
          //   margin-left: 70px;
          color: #FFF;
          font-family: Inter;
          font-size: 32px;
          line-height: 103%;
          /* or 87px */
  
          letter-spacing: -0.02em;
          font-weight: 700;
          line-height: normal;

          .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
          }
          .mobile-br{
            display: block;
          }
        }
        .right {
          width: 100vw;
          .boyiImg {
            // margin: 50px auto;
            display: flex;
            align-items: center;
            // width: 30vw;
            // height: 80vh;
            width: 80vw;
            height: 50vh;
            /* margin-top: 20vh; */
            /* margin: 156px; */
            // margin-top: 9vh;
            // margin-left: 12vw;
            margin: 0 auto;
          }
        }
    
        .boyiImg {
          width: 80vw;
          height: 70vh;
        }
      }
    }
  }
  






