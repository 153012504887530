// .header_container {
//     // background: #000614;
//     // background: linear-gradient(298.59deg, #45108A -5.2%, #3D065F 42.6%, #200554 168.28%);
//     // width: 100vw;
//     // height: 100vh;
//     // box-sizing: border-box;
//     // position: fixed;
//     // z-index: 3;
//     .navbar {
//       padding: 28px 116px;
//       height: 10vh;
//       width: 100vw;
//       a {
//         text-decoration: none;
//       }
//       display: flex;
//       justify-content: space-between;
//       .navbar-brand {
//         .navbar-item {
//           span {
//             font-family: Saira;
//             font-style: normal;
//             font-weight: 800;
//             font-size: 38px;
//             // line-height: 133%;
//             /* identical to box height, or 51px */

//             color: #ffffff;
//           }
//         }
//       }

//       .navbar-end {
//         .navbar-item {
//           font-family: Inter;
//           font-style: normal;
//           font-weight: normal;
//           font-size: 16px;
//           line-height: 19px;

//           color: #ffffff;
//           margin-left: 48px;
//         }
//         .button {
//           font-family: Inter;
//           font-style: normal;
//           font-weight: normal;
//           font-size: 16px;
//           line-height: 19px;

//           color: #ffffff;
//           margin-left: 48px;
//           width: 164px;
//           height: 52px;
//           padding: 14px 44px;
//           background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
//           border-radius: 8px;
//           border: none;
//         }
//       }
//     }

//   }

// bootstrap navbar

@import url('https://fonts.googleapis.com/css2?family=Saira:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.navbar {
  width: 100vw;


  .bg-navbar {
    background: #000614;
  }

  // .change_color{
  //   background: #000614;
  // }
  .container {
    a {
      text-decoration: none;
      color: #ffffff;
    }

    ul li {
      // a{
      //   text-decoration: none;
      //   color: #ffffff;
      // }
      // a:hover{
      //   color: yellow;
      // }
      align-items: center;
      display: flex;
      padding-left: 15px;

      .nav-link {
        // .text{
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;

        text-decoration: none;
        // }


        // .active{
        //   color: red;
        //   text-decoration: none;
        // }
      }

      // padding: 14px 44px;
    }


    .navbar-brand {
      font-family: Saira !important;
      font-style: bold !important;
      font-weight: 800 !important;
      font-size: 32px !important;
      line-height: 133%;
      /* identical to box height, or 51px */
      color: #ffffff;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
    }

    .nakad_logo {
      width: 5vw;
      height: 5vw;
    }


    .button {
      color: #ffffff;
      // margin-left: 48px;
      width: 164px;
      // height: 52px;
      padding: 14px 44px;
      background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
      border-radius: 8px;
      border: none;
    }

    .navbar-toggler {
      // background: #ffffff;
      // background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      background: #fff;
      border: none !important;

      // border: 1px solid linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%) !important;
      padding: 8px 8px;
      outline: none !important;
    }

    .navbar-toggler span {
      display: block;
      width: 22px;
      height: 2px;
      border: 1px;
      // background: #fff;
      background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      margin: 4px auto;
    }

    .navbar-nav {
      float: right;

      .nav-item {
        display: flex;
        justify-content: end;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .navbar{
    .container{
      .nakad_logo {
        width: 10vw;
        height: 10vw;
      }
    }
  }
  
}