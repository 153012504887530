.page_7_container {
  width: 1920px;
  height: 1080px;
  position: relative;

//   transition-timing-function: ease-out;
//   transition-delay: 100s;
//   transform: translateY(150px);
//   opacity: 0;
//   transition: 200s all ease;
  .text {
    position: absolute;
    width: 768px;
    height: 62px;
    left: 576px;
    //   top: 193px;
    margin-top: 193px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 120%;
    /* or 62px */

    letter-spacing: -0.03em;

    color: #ffffff;

    .color_text {
      background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .page_4_img {
    display: flex;
    // justify-content: space-around;
    // top: 255px;
    .anchor {
      position: absolute;
      width: 350.9px;
      height: 258.47px;
      top: 409.57px;
      margin-left: 64.79px;
    }
    .tier1 {
      position: absolute;
      left: 28.29%;
      right: 53.48%;
      top: 38.06%;
      bottom: 38.01%;
    }
    .tier2 {
      position: absolute;
      left: 52.71%;
      right: 29.06%;
      top: 38.06%;
      bottom: 38.01%;
    }
    .lender {
      position: absolute;
      left: 78.18%;
      right: 3.55%;
      top: 25.46%;
      bottom: 36.76%;
    }

    .anchor_title {
      position: absolute;
      // position: relative;
      width: 186px;
      height: 50px;
      left: 152px;
      top: 683px;
      // margin-top: 14.96px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 120%;
      /* or 50px */

      text-align: center;

      color: #ffffff;
    }
    .tier1_title {
      position: absolute;
      //   width: 124px;
      width: 135px;
      height: 50px;
      left: 657px;
      top: 683px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 120%;
      /* or 50px */

      text-align: center;

      color: #ffffff;
    }
    .tier2_title {
      position: absolute;
      //   width: 130px;
      width: 135px;
      height: 50px;
      left: 1122px;
      top: 683px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 120%;
      /* or 50px */

      text-align: center;

      color: #ffffff;
    }
    .lender_title {
      position: absolute;
      width: 165px;
      height: 50px;
      left: 1593px;
      top: 683px;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 120%;
      /* or 50px */

      text-align: center;

      color: #ffffff;
    }
    .text_box1 {
      position: absolute;
      width: 420px;
      height: 182px;
      left: 35px;
      top: 786px;

      background: rgba(255, 255, 255, 0.1);
      border-radius: 23px;

      .text {
        position: static;
        width: 257px;
        height: 164px;
        left: 81.5px;
        top: 18px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 225%;
        /* or 40px */

        letter-spacing: 0.105em;

        color: rgba(255, 255, 255, 0.7);

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 10px 0px;
      }
    }
    .text_box2 {
      position: absolute;
      width: 420px;
      height: 182px;
      left: 512px;
      top: 786px;

      background: rgba(255, 255, 255, 0.1);
      border-radius: 23px;

      .text {
        position: static;
        width: 257px;
        height: 164px;
        left: 81.5px;
        top: 18px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 225%;
        /* or 40px */

        letter-spacing: 0.105em;

        color: rgba(255, 255, 255, 0.7);

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 10px 0px;
      }
    }
    .text_box3 {
      position: absolute;
      width: 420px;
      height: 182px;
      left: 989px;
      top: 786px;

      background: rgba(255, 255, 255, 0.1);
      border-radius: 23px;

      .text {
        position: static;
        width: 257px;
        height: 164px;
        left: 81.5px;
        top: 18px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 225%;
        /* or 40px */

        letter-spacing: 0.105em;

        color: rgba(255, 255, 255, 0.7);

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 10px 0px;
      }
    }
    .text_box4 {
      position: absolute;
      width: 420px;
      height: 182px;
      left: 1466px;
      top: 786px;

      background: rgba(255, 255, 255, 0.1);
      border-radius: 23px;

      .text {
        position: static;
        width: 257px;
        height: 164px;
        left: 81.5px;
        top: 18px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 225%;
        /* or 40px */

        letter-spacing: 0.105em;

        color: rgba(255, 255, 255, 0.7);

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 10px 0px;
      }
    }
  }
}
