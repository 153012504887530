.media_page_new {
    background: #000614;
    width: 100vw;
    padding-top: 100px;
    // z-index: 4;
    .media_page_section {
      // background-image: url(../../assetsSvg/vcfirm/Saly-43.svg);
      // background-repeat: no-repeat;
      // background-position: top 10% right -3%;
      // background-size: 230px;
      .heading {
        height: 165px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 103%;
        text-align: center;
        color: #ffffff;
       .rocket{
        max-height: 165px;
       }
    }
      .vcfirm {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        
      .vcfirm_circle {
        background: #fff;
        border-radius: 50%;
        margin-right: 30px;
        display: flex;
        min-height: 200px;
        max-width: 200px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
         img {
          width: 100%;
          padding: 10px;
          min-width: 200px;
          object-fit: contain;
      }
    }
    
      }

      .media {
        // background: yellow;
        height: 100%;
        width: 100%;
        margin-top: 0px;
        .media_color_text {
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        ul.media_logo {
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          li{
            list-style: none;
            padding: 10px;
            a{
              text-decoration: none;
            .media_img {
            width: 100%;
            height: 100%;
            padding: 5px 25px;
            cursor: pointer;
            //max-width: 325px;
            margin: 0px auto;
            margin-top: 0px;
            img {
              width: 100%;
              height: 100%;
              max-height: 45px;
              max-width: 280px;
              object-fit: contain;
            }
         
        }
            }
          }
      }
       
        
      }
    }
   
  }

  /* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {

  .Life_6_container{
    height: 100%;
  }
     .media_page_new {
      background: #000614;
      width: 100%;
      height: 100%;
      padding-top: 0px;
      .media_page_section {
        background-size: 130px;
        .heading {
          height: auto;
          display: inline-block;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: normal;
          text-align: center;
          color: #ffffff;
          width: 100%;
          margin:20px 0;
          .rocket{
            max-height: 50px;
          }
      }
     
        .vcfirm {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          flex-flow: column;
          align-items: center;
          margin-left: 0px;
          margin-right: 0px;
          .vcfirm_circle{
            background:white;
            border-radius: 50%;
            margin: 30px auto;
            width: 260px;
            height: 260px;
            img{
              width: 100%;
              min-width: 100%;
              min-height: 100%;
            }
           
         }
        }
  
        .media {
          height: 100%;
          width: 100%;
          margin-top: 0px;
          padding: 50px 0;
          
          .media_logo {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 100px;
            a{
              text-decoration: none;
              .media_img {
                img{
                  max-width: 160px !important;
                }
              }
            }
        }
         

        //   .react-multiple-carousel__arrow {
        //     z-index: 9;
        //     top: initial;
        //     bottom: 0;
        //   }
        //   .media_slider {
        //     margin-bottom: 50px;
        //     .media_img {
        //       margin-top: 0;
        //     }
        // }
        }
      }
    }
}