.home_7_container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(298.59deg, #45108A -5.2%, #3D065F 42.6%, #200554 168.28%);
    // background: red;
    display: flex;
    // position: absolute;
  
    .left {
      width: 40%;
      height: 70vh;
      margin-top: 20vh;
      // background: rgb(170, 170, 165);
      // float: left;
      display: inline;
      img {
        // z-index: 3;
        // position: fixed;
      }
      .group1 {
        // position: fixed;
        width: 10vw;
        height: 10vh;
        margin-left: -2vw;
      }
      .group2 {
        // position: fixed;
        float: right;
        // margin-top: 10%;
        // margin-left: 32vw;
        margin-left: 34vw;
        // margin-top: 5vh;
        margin-top: 5vh;
        width: 5vw;
        height: 5vw;
      }
      .group3 {
        // position: fixed;
        // margin-top: 15vh;
        margin-top: 10vh;
        // margin-left: 70px;
        width: 12vw;
        /* height: 12vw; */
        height: 10vh;
        margin-left: 2vw;
      }
      .group4 {
        // position: fixed;
        margin-top: 50vh;
        margin-left: 100px;
      }
      .group5 {
        // position: fixed;
        margin-top: 50vh;
        float: right;
        margin-left: 30vw;
      }
      #second {
        opacity: 0;
      }
      #third {
        opacity: 0;
      }
      #fourth {
        opacity: 0;
      }
      #fifth {
        opacity: 0;
      }
      #sixth {
        opacity: 0;
      }
      .text_container {
        // position: fixed;
        // position: absolute;
        // background: skyblue;
        // position: fixed;
        // width: 670px;
        // height: 124px;
        // left: 167px;
        // top: 405px;
        // // top: 297px;
        width: 30vw;
        height: 25vh;
        margin-top: 16vh;
        margin-left: 9vw;
        // z-index: 4;
  
        .text {
        //   position: fixed;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          // font-size: 52px;
          font-size: 48px;
          line-height: 120%;
          /* or 62px */
  
          letter-spacing: -0.03em;
  
          color: #ffffff;
  
          .color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .box {
            // background: yellow;
            width: 30vw;
            height: 12vh;
            margin-top: 3vh;
            display: flex;
            // flex-direction: row;
            background: rgba(77, 29, 110, 0.56);
            border-radius: 0px 8px 8px 0px;
  
            .left_box {
              background: linear-gradient(
                144.98deg,
                #ff008a 16.34%,
                #0082ff 77.38%
              );
              border-radius: 0px 29px 29px 0px;
              width: 0.6vw;
            }
            .right_box {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 225%;
              /* or 54px */
  
              letter-spacing: 0.105em;
  
              color: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }
    }
    .right {
      width: 60%;
      height: 70vh;
      margin-top: 20vh;
      // background: rgb(214, 218, 158);
      img {
        // z-index: 3;
      }
  
      .tier2 {
        // width: 300px;
        // height: 200px;
        // position: fixed;
        width: 25vw;
        // height: 20vh;
        height: 25vh;
        margin-left: 18vw;
        margin-top: 6vh;
      }
      .tier1 {
        // width: 300px;
        // height: 200px;
        // position: fixed;
        width: 25vw;
        // height: 20vh;
        height: 25vh;
        // margin-left: 18vw;
        // margin-left: 36vw;
        // margin-top: 24vh;
        margin-left: 34vw;
        margin-top: 22vh;
      }
      .anchor {
        // width: 300px;
        // height: 200px;
        // position: fixed;
        width: 25vw;
        // height: 20vh;
        height: 25vh;
        // margin-left: 18vw;
        // margin-top: 44vh;
        margin-left: 18vw;
        // margin-top: 36vh;
        margin-top: 38vh;
      }
      .lender {
        // position: fixed;
        //     width: 20vw;
        // height: 40vh;
  
        width: 25vw;
        height: 40vh;
        // margin-top: 15vh;
        margin-top: 6vh;
        // margin-left: 18vw;
        // margin-left: 18vw;
  
        opacity: 0;
      }
      .truck1{
        height: 64px;
     
      width: 100px;
      margin-left: 34vw;
      margin-top: 21vh;
      }
    }
  
    // ecosystem
    .ecosystem {
      // display: block;
      // z-index: 3;
  
      .title {
        // background: skyblue;
        display: flex;
        justify-content: center;
        height: 10vh;
        margin-top: 20vh;
  
        .text {
          // position: fixed;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          // font-size: 52px;
          font-size: 48px;
          line-height: 120%;
          /* or 62px */
  
          letter-spacing: -0.03em;
  
          color: #ffffff;
  
          .color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .eco_box {
        // background: skyblue;
        width: 100vw;
        height: 60vh;
        margin-top: 0;
        display: flex;
        // justify-content: space-between;
        justify-content: space-evenly;
  
        .inside_eco {
          // background: #d1da57;
          width: 20vw;
          height: 100%;
          .eco_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            // font-size: 42px;
            font-size: 26px;
            line-height: 120%;
            /* or 50px */
  
            text-align: center;
  
            color: #ffffff;
          }
          .box {
            // background: yellow;
            width: 20vw;
            height: 12vh;
            margin-top: 3vh;
            // display: flex;
            // flex-direction: row;
            background: rgba(77, 29, 110, 0.56);
            border-radius: 8px;
  
            .left_box {
              background: linear-gradient(
                144.98deg,
                #ff008a 16.34%,
                #0082ff 77.38%
              );
              border-radius: 0px 29px 29px 0px;
              height: 0.6vh;
              width: 50%;
              display: flex;
              justify-content: center;
              border-radius: 0px 0px 29px 29px;
              margin: auto;
            }
            .right_box {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 225%;
              /* or 54px */
  
              letter-spacing: 0.105em;
  
              color: rgba(255, 255, 255, 0.7);
              margin-top: 5%;
            }
          }
        }
        .anchor {
          width: 20vw;
          height: 20vh;
          margin-top: 38%;
        }
        .lender{
          width: 22vw;
          height: 34.4vh;
          // margin-top: 38%;
          // width: 288px;
          // height: 270px;
        }
      }
    }
  }