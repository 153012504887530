.Life_1_container {
  background: #000614;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;

  .navbar {
    padding: 28px 116px;
    height: 10vh;
    a {
      text-decoration: none;
    }
    .navbar-brand {
      .navbar-item {
        span {
          font-family: Saira;
          font-style: normal;
          font-weight: 800;
          font-size: 38px;
          // line-height: 133%;
          /* identical to box height, or 51px */

          color: #ffffff;
        }
      }
    }

    .navbar-end {
      .navbar-item {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
        margin-left: 48px;
      }
      .button {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
        margin-left: 48px;
        width: 164px;
        height: 52px;
        padding: 14px 44px;
        background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
        border-radius: 8px;
        border: none;
      }
    }
  }
  .bottom {
    height: 90vh;
    display: flex;
    .left {
      width: 50vw;
      .img_box {
        display: flex;
        // margin-top: 80px;
        // margin-left: 50px;
        margin-top: 20vh;
        margin-left: 3vw;

        .cloud2 {
          width: 15vw;
        }
        .cloud1 {
          width: 15vw;
          height: 12vh;
          margin-left: 10vw;
        }
      }
      // .cloud2{
      //     margin-top: 80px;
      //     margin-left: 50px;
      //     margin-right: 70px;
      // }
      .text_box {
        color: #ffffff;
        // width: 713px;
        // height: 155px;
        margin-top: 50px;
        margin-left: 50px;
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        // font-size: 64px;
        font-size: 4.45vw;
        line-height: 103%;
        /* or 87px */

        letter-spacing: -0.02em;
        .color_text {
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .text_box_mobile{
        display: none;
      }
    }
    .right {
      width: 50vw;
      .boyiImg {
        margin: 50px auto;
        display: flex;
        align-items: center;
        // width: 30vw;
        // height: 80vh;
        width: 28vw;
        height: 70vh;
        /* margin-top: 20vh; */
        /* margin: 156px; */
        margin-top: 20vh;
        margin-left: 16vw;
      }
    }

    .boyiImg {
      width: 30vw;
      height: 70vh;
    }
  }
}

/* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .Life_1_container {
    // background: #2156d2;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    .bottom {
      height: 100vh;
      display: block;
      .left {
        width: 100vw;
        padding-top: 15vh;
        .img_box {
          display: flex;
          // margin-top: 80px;
          // margin-left: 50px;
          margin-top: 0vh;
          margin-left: 0vw;
          display: none;
          .cloud2 {
            width: 15vw;
           
          }
          .cloud1 {
            width: 15vw;
            height: 12vh;
            margin-left: 10vw;
          }
        }
        // .cloud2{
        //     margin-top: 80px;
        //     margin-left: 50px;
        //     margin-right: 70px;
        // }
        .text_box {
          display: none;
          color: #ffffff;
          // width: 713px;
          // height: 155px;
          // margin-top: 50px;
          // margin-left: 50px;
          font-family: Inter;
          font-style: normal;
          font-weight: 800;
          // font-size: 46px;
          font-size: 36px;
          line-height: 103%;
          
          /* or 87px */
  
          letter-spacing: -0.02em;
          .color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .text_box_mobile{
          display: flex;
          justify-content: center;
          .text{
          color: #ffffff;
          // width: 713px;
          // height: 155px;
          // margin-top: 50px;
          // margin-left: 50px;
          font-family: Inter;
          font-style: normal;
          font-weight: 800;
          // font-size: 46px;
          font-size: 36px;
          line-height: 130%;
          
          /* or 87px */
  
          letter-spacing: -0.02em;
          width: 80vw;
          text-align: center;
          .color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        }
      }
      .right {
        width: 100vw;
        .boyiImg {
          // margin: 50px auto;
          display: flex;
          align-items: center;
          // width: 30vw;
          // height: 80vh;
          width: 80vw;
          height: 50vh;
          /* margin-top: 20vh; */
          /* margin: 156px; */
          margin-top: 9vh;
          margin-left: 12vw;
        }
      }
  
      .boyiImg {
        width: 80vw;
        height: 70vh;
      }
    }
  }
}
