.Life_5_container {
  background: #000614;
  width: 100vw;
  // height: 100vh;
  height: 720px;
  box-sizing: border-box;
  // background-color: yellow;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 103%;
    /* identical to box height, or 54px */

    text-align: center;

    /* White */

    color: #ffffff;

    .color_text {
      background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .advisors_container {
    // background: skyblue;
    // width: 100vw;
    // height: 70vh;
    // margin-left: 3vw;
    width: 1440px;
    height: 70%;
    padding-left: 100px;
    margin: auto;

    .card1 {
        // background: rgb(101, 101, 93);
      // width: 23vw;
      // width: 18.4vw;
      // height: 70vh;
      width: 265px;
      height: 475px;

      .top {
        // background-color: gray;
        // height: 65%;
        height: 308px;

        .box {
          // background: #95f48d;
          // border-radius: 38.3333px 38.3333px 0px 0px;
          // background: linear-gradient(180deg, #48FA5A 0%, rgba(72, 250, 90, 0) 100%);
          // background: linear-gradient(180deg, #488cfa 0%, rgba(72, 250, 90, 0) 100%);
          background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
border-radius: 36.3192px 36.3192px 0px 0px;
          width: 100%;
          // margin-top: -47%;
          // height: 80%;
          // margin-top: -60%;
          // height: 70%;
          // margin-top: -34vh;
          margin-top: -230px;
          // height: 34vh;
          height: 230px;
        }

        img {
           // width: 23vw;
          // width: 18.4vw;
          // height: 44vh;
          width: 265px;
          height: 270px;
        }
    //     .Sandeep{
    //       width: 26vw;
    // height: 44vh;
    // margin-left: -20px;
    //     }
        // .abhinav{
        //   width: 20vw;
        //   height: 44vh;
        // }
        // .ankit{
        //   width: 25vw;
        //   height: 44vh;
        // }
        // .Amit{
        //   width: 20vw;
        //   height: 34vh;
        // }

        .Amit{
          // width: 19.5vw;
          // height: 44vh;
          // margin-left: -1vw;
          width: 280px;
          height: 270px;
          margin-left: -10px;
        }
      }
      .bottom1 {
        // background-color: #8d3bbf;
        height: 25%;
        .name {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 103%;
          /* or 41px */

          text-align: center;

          color: #ffffff;
          // padding: 5px;
          padding-top: 5px;
        }
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 103%;
          /* identical to box height, or 29px */

          text-align: center;

          color: #ffffff;

          opacity: 0.71;
          // padding: 5px;
        }
      }
    }
  }
  .advisors_container_mobile{
    display: none;
  }
}


/* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .Life_5_container {
    background: #000614;
    width: 100vw;
    height: 800px;
    box-sizing: border-box;
  
    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 70px;
      padding: 70px 70px 40px 70px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      // font-size: 52px;
      font-size: 36px;
      line-height: 103%;
      /* identical to box height, or 54px */
  
      text-align: center;
  
      /* White */
  
      color: #ffffff;
  
      .color_text {
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .advisors_container{
      display: none;
    }
    .advisors_container_mobile {
      // background: skyblue;
      display: block;
      width: 100vw;
      height: 80%;
      // margin-left: 1vw;
      margin-top: 5px;
  
      .card1 {
          // background: yellow;
        //  width: 314px;
        width: 237px;
        // height: 37vh;
        height: 315px;
        margin: auto;
  
        .top {
          // background-color: gray;
          // height: 63%;
          // height: 67%;
          // height: 210px;
          height: 170px;
  
          .box {
            // background: #95f48d;
            // border-radius: 38.3333px 38.3333px 0px 0px;
            // background: linear-gradient(180deg, #48FA5A 0%, rgba(72, 250, 90, 0) 100%);
            // background: linear-gradient(180deg, #488cfa 0%, rgba(72, 250, 90, 0) 100%);
            background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
  border-radius: 36.3192px 36.3192px 0px 0px;
            // width: 100%;
            width: 80%;
            // margin-top: -47%;
            // height: 80%;
            // margin-top: -60%;
            // height: 70%;
            margin-top: -136px;
            margin-left: 26px;
            height: 136px;
          }
  
          img {
            // width: 40vw;
            // width: 64vw;
            // height: 22vh;
            // height: 24vh;
            width: 252px;
            height: 204px;
            display: flex;
            margin: auto;
          }
        }
        .bottom1 {
          // background-color: #8d3bbf;
          height: 25%;
          .name {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 103%;
            /* or 41px */
  
            text-align: center;
  
            color: #ffffff;
            // padding: 5px;
            padding-top: 5px;
          }
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 103%;
            /* identical to box height, or 29px */
  
            text-align: center;
  
            color: #ffffff;
  
            opacity: 0.71;
            // padding: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .Life_5_container {
    .advisors_container {
      width: 1024px;
    }
  }
}