@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

body {
  overflow: hidden;
}
// html, body {
//   height: 100%;
// }

// body {
//   position: fixed;
//   margin: 0;
//   padding: 0;
//   border: 0;
//   outline: 0;
//   background: 0 0;
//       // -webkit-overflow-scrolling: touch;
//   // overflow-x: auto;
//   // overflow-y: scroll;
//   overflow: hidden;
// }

// .L2R_truck{
//   transform-box: fill-box;
// 	transform-origin: 50% 50%
// }

// .truck1{
//   animation: 2s linear 0s infinite normal forwards running TruckL2R;
// 	transform-box: fill-box;
// 	transform-origin: 50% 50%;
// }
// @keyframes TruckL2R {
// 	0% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 1;
// 	}
// 	95% {
// 		opacity: 1;
// 	}
// 	100% {
// 		transform: translateX(65px) translateY(45px);
// 		opacity: 0;
// 	}
// }

// .truck2{
//   animation: 2s linear 0s infinite normal forwards running TruckR2L;
// 	transform-box: fill-box;
// 	transform-origin: 50% 50%;
// }

// @keyframes TruckR2L {
// 	0% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 1;
// 	}
// 	95% {
// 		opacity: 1;
// 	}
// 	100% {
// 		transform: translateX(-70px) translateY(41px);
// 		opacity: 0;
// 	}
// }

// .coin_upward{
//   animation: 2s linear 0s 1 normal forwards running CoinUp;
// 	transform-box: fill-box;
// 	transform-origin: 50% 50%;
//   width: 8vw;
  
// }
// @keyframes CoinUp {
// 	0% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 1;
// 	}
// 	95% {
// 		opacity: 1;
// 	}
// 	100% {
// 		transform: translateX(144px) translateY(-116px);
// 		opacity: 0;
// 	}
// }

// .Small_coin2{
//   animation: 4s linear 0s 1 normal forwards running Small_coin2;
// 	transform-box: fill-box;
// 	transform-origin: 50% 50%;
//   width: 8vw
// }
// @keyframes Small_coin2 {
// 	0% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 0;
// 	}
// 	50% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: translateX(-139px) translateY(-97px);
// 		opacity: 0;
// 	}
// 	52.5% {
// 		opacity: 1;
// 	}
// 	97.5% {
// 		opacity: 1;
// 	}
// }

// .small_coin1{
//   animation: 4s linear 0s 1 normal forwards running small_coin1;
// 	transform-box: fill-box;
// 	transform-origin: 50% 50%;
//   width: 8vw
// }
// @keyframes small_coin1 {
// 	0% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 0;
// 	}
// 	50% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 1;
// 	}
// 	52.5% {
// 		opacity: 1;
// 	}
// }

// .coin_downward{
//   animation: 6s linear 0s 1 normal forwards running CoinDown;
// 	transform-box: fill-box;
// 	transform-origin: 50% 50%;
//   width: 8vw;
// }
// @keyframes CoinDown {
// 	0% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: translateX(-161px) translateY(112px);
// 		opacity: 1;
// 	}
// 	66.67% {
// 		transform: translateX(0px) translateY(0px);
// 		opacity: 0;
// 	}
// 	68.33% {
// 		opacity: 1;
// 	}
// }

.home_page {
  position: fixed;
  .home_1_container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(298.59deg, #45108A -5.2%, #3D065F 42.6%, #200554 168.28%);
    display: flex;
    position: absolute;

    .left {
      width: 40%;
      height: 70vh;
      margin-top: 20vh;
      // background: rgb(170, 170, 165);
      // float: left;
      display: inline;

      img {
        // z-index: 3;
        // position: fixed;
      }

      .group1 {
        position: fixed;
        width: 10vw;
        height: 10vh;
        margin-left: -2vw;
      }

      .group2 {
        position: fixed;
        float: right;
        // margin-top: 10%;
        // margin-left: 32vw;
        margin-left: 34vw;
        // margin-top: 5vh;
        margin-top: 5vh;
        width: 5vw;
        height: 5vw;
      }

      .group3 {
        position: fixed;
        // margin-top: 15vh;
        margin-top: 10vh;
        // margin-left: 70px;
        width: 12vw;
        /* height: 12vw; */
        height: 10vh;
        margin-left: 2vw;
      }

      .group4 {
        position: fixed;
        margin-top: 50vh;
        margin-left: 100px;
      }

      .group5 {
        position: fixed;
        margin-top: 50vh;
        float: right;
        margin-left: 30vw;
      }

      #second {
        opacity: 0;
      }

      #third {
        opacity: 0;
      }

      #fourth {
        opacity: 0;
      }

      #fifth {
        opacity: 0;
      }

      #sixth {
        opacity: 0;
      }

      .text_container_unlock{
        position: absolute;
        width: 60vw;
        height: 25vh;
        margin-top: 16vh;
        margin-left: 9vw;
        .text{
          position: fixed;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          // font-size: 52px;
          // font-size: 48px;
          font-size: 3.33vw;
          line-height: 120%;
          /* or 62px */

          letter-spacing: -0.03em;

          color: #ffffff;
          .color_text{
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .text_container_unlock_mobile{
        display: none;
      }

      .text_container_cashflow{
        position: absolute;
        width: 30vw;
        height: 25vh;
        margin-top: 16vh;
        margin-left: 9vw;
        .text{
          position: fixed;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          // font-size: 52px;
          // font-size: 48px;
          font-size: 3.33vw;
          line-height: 120%;
          /* or 62px */

          letter-spacing: -0.03em;

          color: #ffffff;
          .color_text{
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .text_container_cashflow_mobile{
        display: none;
      }

      .text_container {
        position: absolute;
        width: 60vw;
        height: 25vh;
        margin-top: 16vh;
        margin-left: 9vw;
        // z-index: 4;

        .text {
          position: fixed;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          // font-size: 52px;
          // font-size: 48px;
          font-size: 3.33vw;
          line-height: 120%;
          /* or 62px */

          letter-spacing: -0.03em;

          color: #ffffff;

          .color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .box {
            // background: yellow;
            width: 30vw;
            height: 12vh;
            margin-top: 3vh;
            display: flex;
            // flex-direction: row;
            background: rgba(77, 29, 110, 0.56);
            border-radius: 0px 8px 8px 0px;

            .left_box {
              background: linear-gradient(144.98deg,
                  #ff008a 16.34%,
                  #0082ff 77.38%);
              border-radius: 0px 29px 29px 0px;
              width: 0.6vw;
            }

            .right_box {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 225%;
              /* or 54px */
              // margin-top: auto;
              height: 14vh;
              display: flex;
              align-items: center;
              letter-spacing: 0.105em;

              color: rgba(255, 255, 255, 0.7);

            }
          }
        }
      }
    }

    .right {
      // position: absolute;
      position: fixed;
      width: 70%;
      height: 70vh;
      margin-top: 20vh;
      margin-left: 35vw;

      // background: rgb(214, 218, 158);
      img {
        // z-index: 3;
      }

      .tier2 {
        // width: 300px;
        // height: 200px;
        position: fixed;
        // width: 25vw;
        // height: 20vh;
        // height: 25vh;
        width: 18vw;
        height: 25vh;
        margin-left: 21vw;
        margin-top: 6vh;
      }

      .tier1 {
        // width: 300px;
        // height: 200px;
        position: fixed;
        // width: 25vw;
        // height: 20vh;
        // height: 25vh;
        width: 18vw;
        height: 25vh;
        // margin-left: 18vw;
        // margin-left: 36vw;
        // margin-top: 24vh;
        margin-left: 37vw;
        margin-top: 22vh;
      }

      .anchor {
        // width: 279px;
        // height: 197px;
        position: fixed;
        // width: 25.83vw;
        // height: 27.36vh;
        width: 18vw;
        height: 25vh;
        // height: 20vh;
        // margin-left: 18vw;
        // margin-top: 44vh;
        margin-left: 21vw;
        // margin-top: 36vh;
        margin-top: 38vh;
        z-index: 1;
      }

      .lender {
        position: fixed;
        //     width: 20vw;
        // height: 40vh;

        width: 18vw;
        height: 39vh;
        // margin-top: 6vh;
        margin-top: 7vh;
        margin-left: 4vw;
        // margin-top: 15vh;
        // margin-left: 18vw;

        opacity: 0;
        z-index: 1;
      }

      // .truck1{
      //   position: fixed;
      //   width: 100px;
      //   margin-left: 35vw;
      //   margin-top: -2.8vw;
      // }

      // .truck2{
      //   position: fixed;
      //   width: 5.5vw;
      //   margin-left: 36vw;
      //   margin-top: 6.5vw;
      // }

      // .arrow1{
      //   height: 200px;
      //   margin-top: -26vh;
      //   margin-left: 35vw;
      //  // transform: rotate(-2deg);
      //   opacity: 0
      // }

      // .arrow2{
      //   height: 200px;
      //   margin-top: 15vh;
      //   margin-left: 35vw;
      //   opacity: 0
      // }
      // .arrow3{
      //   height: 200px;
      //   margin-top: -110vh;
      //   opacity: 0;
      //   z-index: -1;
      // }

      // .lender_text_box{

      //   // margin-top:510px;
      //   margin-top:65vh;
      //   // margin-left: -180px;
      //   margin-left: -12vw;
      //   // opacity: 0;
      //   p{
      //     padding: 0;
      //     margin: 0;
      //   }
      //   .lender_text {
      //     font-family: 'Inter';
      //     font-style: normal;
      //     font-weight: 700;
      //     font-size: 32px;
      //     line-height: 120%;
      //     /* or 50px */
      //     color: rgba(255, 255, 255, 0.4);

      //     text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
      //     transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      //   }
      //   .lender_sub_text {
      //     font-family: 'Inter';
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 16px;
      //     line-height: 120%;
      //     margin-left: -40px;
      //     /* identical to box height, or 29px */


      //     color: rgba(255, 255, 255, 0.4);

      //     text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      //     transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      //   }
      // }


      // .anchor_text_box{
      //   // display: block;
      //   // margin-top:470px;
      //   // margin-left: -180px;
      //   // transform: rotate(33deg);
      //   // margin-top: 30px;
      //   margin-top: 3vh;
      //   // margin-left: 140px;
      //   margin-left: 15vh;
      //   p{
      //     padding: 0;
      //     margin: 0;
      //   }
      //   .anchor_text {
      //     font-family: 'Inter';
      //     font-style: normal;
      //     font-weight: 700;
      //     font-size: 32px;
      //     line-height: 120%;
      //     /* or 50px */
      //     color: rgba(255, 255, 255, 0.4);

      //     text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
      //     transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      //   }
      //   .anchor_sub_text {
      //     font-family: 'Inter';
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 16px;
      //     line-height: 120%;
      //     margin-left: -40px;
      //     /* identical to box height, or 29px */


      //     color: rgba(255, 255, 255, 0.4);

      //     text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      //     transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      //   }
      // }


      // .tier1_text_box{
      //    // margin-top: -340px;
      //   margin-top: -42vh;
      //   // margin-left: 780px;
      //   margin-left: 54vw;

      //   p{
      //     padding: 0;
      //     margin: 0;
      //   }
      //   .tier1_text {
      //     font-family: 'Inter';
      //     font-style: normal;
      //     font-weight: 700;
      //     font-size: 32px;
      //     line-height: 120%;
      //     /* or 50px */
      //     color: rgba(255, 255, 255, 0.4);

      //     text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
      //     transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      //   }
      //   .tier1_sub_text {
      //     font-family: 'Inter';
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 16px;
      //     line-height: 120%;
      //     margin-left: -40px;
      //     /* identical to box height, or 29px */


      //     color: rgba(255, 255, 255, 0.4);

      //     text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      //     transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      //   }
      // }


      // .tier2_text_box{
      //   // display: block;
      //   // margin-top:470px;
      //   // transform: rotate(33deg);
      //   // margin-top: 10px;
      //   // margin-left: 140px;
      //   margin-top: -30vh;
      //   margin-left: 13vw;

      //   p{
      //     padding: 0;
      //     margin: 0;
      //   }
      //   .tier2_text {
      //     font-family: 'Inter';
      //     font-style: normal;
      //     font-weight: 700;
      //     font-size: 32px;
      //     line-height: 120%;
      //     /* or 50px */
      //     color: rgba(255, 255, 255, 0.4);

      //     text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
      //     transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      //     // margin-left: 20px;
      //   }
      //   .tier2_sub_text {
      //     font-family: 'Inter';
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 16px;
      //     line-height: 120%;
      //     margin-left: -40px;
      //     /* identical to box height, or 29px */


      //     color: rgba(255, 255, 255, 0.4);

      //     text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
      //     transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      //     margin-top: -5px;
      //   }
      // }

    }

    .lender_text_box {
      position: absolute;
      margin-top: 57vh;
      // margin-left: -180px;
      // margin-left: -12vw;
      margin-left: 34vw;
      opacity: 0;
      transform: rotate(2deg);

      p {
        padding: 0;
        margin: 0;
      }

      .lender_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        /* or 50px */
        color: rgba(255, 255, 255, 0.4);

        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        letter-spacing: 3px;
      }

      .lender_sub_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 120%;
        margin-left: -40px;
        /* identical to box height, or 29px */


        color: rgba(255, 255, 255, 0.4);

        text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      }
    }

    .anchor_text_box {
      position: absolute;
      margin-top: 75vh;
      margin-left: 52vw;
      transform: rotate(2deg);

      p {
        padding: 0;
        margin: 0;
      }

      .anchor_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        /* or 50px */
        color: rgba(255, 255, 255, 0.4);

        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        letter-spacing: 3px;
      }

      .anchor_sub_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 120%;
        margin-left: -40px;
        /* identical to box height, or 29px */


        color: rgba(255, 255, 255, 0.4);

        text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      }
    }

    .tier1_text_box {
      position: absolute;
      margin-top: 58vh;
      margin-left: 90vw;

      p {
        padding: 0;
        margin: 0;
      }

      .tier1_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        /* or 50px */
        color: rgba(255, 255, 255, 0.4);

        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        letter-spacing: 3px;
      }

      .tier1_sub_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 120%;
        margin-left: -40px;
        /* identical to box height, or 29px */


        color: rgba(255, 255, 255, 0.4);

        text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
      }
    }

    .tier2_text_box {
      position: absolute;
      margin-top: 21vh;
      margin-left: 55vw;

      p {
        padding: 0;
        margin: 0;
      }

      .tier2_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        /* or 50px */
        color: rgba(255, 255, 255, 0.4);

        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        margin-left: 36px;
        letter-spacing: 3px;
      }

      .tier2_sub_text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 120%;
        margin-left: -64px;
        /* identical to box height, or 29px */


        color: rgba(255, 255, 255, 0.4);

        text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        margin-top: -17px;
      }
    }

    .truck1_stop{
      position: absolute;
      // width: 10vw;
      // margin-left: 68vw;
      // margin-top: 39vh;
      width: 3vw;
    margin-left: 71vw;
    margin-top: 41vh;
    opacity: 1;
    }

    .truck1 {
      position: absolute;
      // width: 10vw;
      // margin-left: 68vw;
      // margin-top: 39vh;
      width: 3vw;
    margin-left: 71vw;
    margin-top: 41vh;
      z-index: -1;
      animation: 2.5s linear 0s infinite normal forwards running TruckL2R;
      transform-box: fill-box;
      transform-origin: 50% 50%;
    }
    @keyframes TruckL2R {
      0% {
        transform: translateX(0px) translateY(0px);
        // opacity: 1;
      }
      95% {
        // opacity: 1;
      }
      100% {
        transform: translateX(65px) translateY(45px);
        // transform: translateX(32.5px) translateY(22.5px);
        opacity: 0;
      }
    }

    .truck1_opacity_less {
      position: absolute;
      // width: 10vw;
      // margin-left: 68vw;
      // margin-top: 39vh;
      width: 3vw;
    margin-left: 71vw;
    margin-top: 41vh;
      z-index: -1;
      animation: 2.5s linear 0s infinite normal forwards running TruckL2R_less;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      opacity: 0.4;
    }
    @keyframes TruckL2R_less {
      0% {
        transform: translateX(0px) translateY(0px);
        opacity: 0.4;
      }
      95% {
        // opacity: 1;
      }
      100% {
        transform: translateX(65px) translateY(45px);
        // transform: translateX(32.5px) translateY(22.5px);
        opacity: 0;
      }
    }

    

    .pathL2R{
      position: absolute;
      width: 10vw;
      margin-left: 68vw;
      margin-top: 39vh;
      z-index: -1
    }
    .truck2_stop{
      position: fixed;
      // width: 7.5vw;
      // margin-left: 70vw;
      // margin-top: 57vh;
      width: 3vw;
      margin-left: 73.5vw;
      margin-top: 57vh;
    }
    .truck2 {
      position: fixed;
      // width: 7.5vw;
      // margin-left: 70vw;
      // margin-top: 57vh;
      width: 3vw;
      margin-left: 73.5vw;
      margin-top: 57vh;
      z-index: -1;
      animation: 2.5s linear 0s infinite normal forwards running TruckR2L;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      
    }
    @keyframes TruckR2L {
      0% {
        transform: translateX(0px) translateY(0px);
        // opacity: 1;
      }
      95% {
        // opacity: 1;
      }
      100% {
        transform: translateX(-70px) translateY(41px);
        // transform: translateX(-35px) translateY(20.5px);
        opacity: 0;
      }
    }

    .truck2_opacity_less {
      position: fixed;
      // width: 7.5vw;
      // margin-left: 70vw;
      // margin-top: 57vh;
      width: 3vw;
      margin-left: 73.5vw;
      margin-top: 57vh;
      z-index: -1;
      animation: 2.5s linear 0s infinite normal forwards running TruckR2L_less;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      opacity: 0.4;
    }
    @keyframes TruckR2L_less {
      0% {
        transform: translateX(0px) translateY(0px);
        // opacity: 1;
        opacity: 0.4;
      }
      95% {
        // opacity: 1;
      }
      100% {
        transform: translateX(-70px) translateY(41px);
        // transform: translateX(-35px) translateY(20.5px);
        opacity: 0;
      }
    }

    .pathR2L{
      position: fixed;
      width: 10vw;
      margin-left: 68vw;
      margin-top: 57vh;
      z-index: -1;
    }


.coin_upward{
  // animation: 6s linear 0s infinite normal forwards running CoinUp;
	transform-box: fill-box;
	transform-origin: 50% 50%;
  width: 8vw;
  // opacity: 0;
  z-index: 0;
  position: absolute;
      height: 22vh;
      // margin-top: 68vh;
      // margin-left: 74vw;
      margin-top: 75vh;
      margin-left: 74vw;
      opacity: 0
  
}
@keyframes CoinUp {
	0% {
		transform: translateX(0px) translateY(0px);
		// opacity: 1;
    z-index: 1;
	}
  33.33%, 100%{
    transform: translateX(144px) translateY(-116px);
		opacity: 0;
    z-index: 0;
  }
  // 60%{
  //   opacity: 1;
  // }
	// 95% {
	// 	opacity: 1;
	// }
	// 100% {
	// 	transform: translateX(144px) translateY(-116px);
	// 	opacity: 0;
	// }
}

.coin_upward_visible{
  animation: 6s linear 0s infinite normal forwards running CoinUp_visible;
	transform-box: fill-box;
	transform-origin: 50% 50%;
  width: 8vw;
  // opacity: 0;
  z-index: 0;
  position: absolute;
      height: 22vh;
      // margin-top: 68vh;
      // margin-left: 74vw;
      margin-top: 75vh;
      margin-left: 74vw;
      opacity: 1
  
}
@keyframes CoinUp_visible {
	0% {
		transform: translateX(0px) translateY(0px);
		// opacity: 1;
    z-index: 1;
	}
  33.33%, 100%{
    transform: translateX(144px) translateY(-116px);
		opacity: 0;
    z-index: 0;
  }
  // 60%{
  //   opacity: 1;
  // }
	// 95% {
	// 	opacity: 1;
	// }
	// 100% {
	// 	transform: translateX(144px) translateY(-116px);
	// 	opacity: 0;
	// }
}

.Small_coin2{
  animation: 6s linear 0s infinite normal forwards running Small_coin2;
	transform-box: fill-box;
	transform-origin: 50% 50%;
  width: 8vw;
  // opacity: 0;
  z-index: 0;
  position: absolute;
  height: 22vh;
  // margin-top: 20vh;
  // margin-left: 76vw;
  margin-top: 27vh;
  margin-left: 85vw;
  opacity: 0;
}
@keyframes Small_coin2 {
	0%, 33.33% {
		transform: translateX(0px) translateY(0px);
		opacity: 0;
    z-index: 0;
	}
	// 50% {
	// 	transform: translateX(0px) translateY(0px);
	// 	opacity: 0;
	// }
  35%{
    // opacity: 1;
    z-index: 1;
  }
  65%{
    // opacity: 1;
    z-index: 1;
  }
	66.67%, 100% {
		transform: translateX(-139px) translateY(-97px);
		// opacity: 0;
    z-index: 0;
	}
	// 52.5% {
	// 	opacity: 1;
	// }
	// 97.5% {
	// 	opacity: 1;
	// }
}

.Small_coin2_visible{
  animation: 6s linear 0s infinite normal forwards running Small_coin2_visible;
	transform-box: fill-box;
	transform-origin: 50% 50%;
  width: 8vw;
  // opacity: 0;
  z-index: 0;
  position: absolute;
  height: 22vh;
  // margin-top: 20vh;
  // margin-left: 76vw;
  margin-top: 27vh;
  margin-left: 85vw;
  opacity: 1;
}
@keyframes Small_coin2_visible {
	0%, 33.33% {
		transform: translateX(0px) translateY(0px);
		opacity: 0;
    z-index: 0;
	}
	// 50% {
	// 	transform: translateX(0px) translateY(0px);
	// 	opacity: 0;
	// }
  35%{
    // opacity: 1;
    z-index: 1;
  }
  65%{
    // opacity: 1;
    z-index: 1;
  }
	66.67%, 100% {
		transform: translateX(-139px) translateY(-97px);
		// opacity: 0;
    z-index: 0;
	}
	// 52.5% {
	// 	opacity: 1;
	// }
	// 97.5% {
	// 	opacity: 1;
	// }
}

.small_coin1{
  animation: 6s linear 0s infinite normal forwards running small_coin1;
	transform-box: fill-box;
	transform-origin: 50% 50%;
  width: 8vw;
  // opacity: 0;
  // z-index: 0;
  position: absolute;
  height: 22vh;
  // margin-top: 20vh;
  // margin-left: 76vw;
  margin-top: 27vh;
  margin-left: 85vw;
  opacity: 0;
}
@keyframes small_coin1 {
	0%, 33.33% {
		transform: translateX(0px) translateY(0px);
		opacity: 0;
    z-index: 1;
	}
  35%{
    // opacity: 1;
    z-index: 1;
  }
  65%{
    // opacity: 1;
    z-index: 1;
  }
	// 50% {
	// 	transform: translateX(0px) translateY(0px);
	// 	opacity: 0;
	// }
	66.67%, 100% {
		transform: translateX(0px) translateY(0px);
		// opacity: 0;
    z-index: 1;
	}
	// 52.5% {
	// 	opacity: 1;
	// }
}

.small_coin1_visible{
  animation: 6s linear 0s infinite normal forwards running small_coin1_visible;
	transform-box: fill-box;
	transform-origin: 50% 50%;
  width: 8vw;
  // opacity: 0;
  // z-index: 0;
  position: absolute;
  height: 22vh;
  // margin-top: 20vh;
  // margin-left: 76vw;
  margin-top: 27vh;
  margin-left: 85vw;
  opacity: 1;
}
@keyframes small_coin1_visible{
	0%, 33.33% {
		transform: translateX(0px) translateY(0px);
		opacity: 0;
    z-index: 1;
	}
  35%{
    // opacity: 1;
    z-index: 1;
  }
  65%{
    // opacity: 1;
    z-index: 1;
  }
	// 50% {
	// 	transform: translateX(0px) translateY(0px);
	// 	opacity: 0;
	// }
	66.67%, 100% {
		transform: translateX(0px) translateY(0px);
		// opacity: 0;
    z-index: 1;
	}
	// 52.5% {
	// 	opacity: 1;
	// }
}

.coin_downward{
  animation: 6s linear 0s infinite normal forwards running CoinDown;
	transform-box: fill-box;
	transform-origin: 50% 50%;
  width: 8vw;
  // opacity: 0;
  z-index: 0;
  position: absolute;
  height: 22vh;
  // margin-top: 20vh;
  // margin-left: 32vw;
  margin-top: 14vh;
  margin-left: 44vw;
  opacity: 0;
}
@keyframes CoinDown {
	0%, 66.67% {
		transform: translateX(0px) translateY(0px);
		opacity: 0;
    z-index: 0;
	}
	100% {
		transform: translateX(-161px) translateY(112px);
		// opacity: 1;
    z-index: 1;
	}
	// 66.67% {
	// 	transform: translateX(0px) translateY(0px);
	// 	opacity: 0;
	// }
	// 68.33% {
	// 	opacity: 1;
	// }
}

.coin_downward_visible{
  animation: 6s linear 0s infinite normal forwards running CoinDown_visible;
	transform-box: fill-box;
	transform-origin: 50% 50%;
  width: 8vw;
  // opacity: 0;
  z-index: 0;
  position: absolute;
  height: 22vh;
  // margin-top: 20vh;
  // margin-left: 32vw;
  margin-top: 14vh;
  margin-left: 44vw;
  opacity: 1;
}
@keyframes CoinDown_visible {
	0%, 66.67% {
		transform: translateX(0px) translateY(0px);
		opacity: 0;
    z-index: 0;
	}
	100% {
		transform: translateX(-161px) translateY(112px);
		// opacity: 1;
    z-index: 1;
	}
	// 66.67% {
	// 	transform: translateX(0px) translateY(0px);
	// 	opacity: 0;
	// }
	// 68.33% {
	// 	opacity: 1;
	// }
}

    .arrow1 {
      position: absolute;
      height: 22vh;
      margin-top: 23vh;
      margin-left: 72vw;
      transform: rotate(-2deg);
      opacity: 0
    }

    .arrow2 {
      position: absolute;
      height: 22vh;
      margin-top: 64vh;
      margin-left: 72vw;
      opacity: 0
    }

    .arrow3 {
      position: absolute;
      height: 22vh;
      margin-top: 24vh;
      margin-left: 36vw;
      opacity: 0;
      // transform: rotate(2deg);
      z-index: -1;
    }

    // .star1 {
    //   position: absolute;
    //   height: 22vh;
    //   // margin-top: 20vh;
    //   // margin-left: 76vw;
    //   margin-top: 27vh;
    //   margin-left: 85vw;
    //   opacity: 0;
    //   // animation-delay: 2s;
    // }

    // .star2 {
    //   position: absolute;
    //   height: 22vh;
    //   // margin-top: 68vh;
    //   // margin-left: 74vw;
    //   margin-top: 75vh;
    //   margin-left: 74vw;
    //   opacity: 1
    // }

    // .star3 {
    //   position: absolute;
    //   height: 22vh;
    //   // margin-top: 20vh;
    //   // margin-left: 32vw;
    //   margin-top: 14vh;
    //   margin-left: 44vw;
    //   opacity: 0;
    //   // animation-delay: 4s;
    // }

    // dots
  

    // ecosystem
    .ecosystem {
      // display: block;
      // z-index: 3;
      position: absolute;

      .title {
        // background: skyblue;
        display: flex;
        justify-content: center;
        height: 10vh;
        margin-top: 15vh;

        .text {
          // position: fixed;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          // font-size: 52px;
          font-size: 48px;
          line-height: 120%;
          /* or 62px */

          letter-spacing: -0.03em;

          color: #ffffff;

          .color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .eco_box {
        // background: skyblue;
        width: 100vw;
        height: 60vh;
        margin-top: 3vh;
        display: flex;
        // justify-content: space-between;
        justify-content: space-evenly;

        .inside_eco {
          // background: #d1da57;
          width: 20vw;
          height: 100%;
          

          .eco_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            // font-size: 42px;
            font-size: 26px;
            line-height: 120%;
            /* or 50px */

            text-align: center;

            color: #ffffff;
            // background: red;
          }

          .box {
            // background: rgb(62, 226, 54);
            position: absolute;
            width: 23vw;
            height: 15vh;
            margin-top: 3vh;
            // display: flex;
            // flex-direction: row;
            background: rgba(77, 29, 110, 0.56);
            border-radius: 8px;

            // background: yellow;
            .left_box {
              background: linear-gradient(144.98deg,
                  #ff008a 16.34%,
                  #0082ff 77.38%);
              border-radius: 0px 29px 29px 0px;
              height: 0.6vh;
              width: 50%;
              display: flex;
              justify-content: center;
              border-radius: 0px 0px 29px 29px;
              margin: auto;
            }

            .right_box {
              // background: #0082ff;
              font-family: Inter;
              font-style: normal;
              font-weight: 800;
              // font-size: 14.8px;
              font-size: 1vw;
              line-height: 225%;
              /* or 54px */

              letter-spacing: 0.105em;

              color: rgba(255, 255, 255, 0.7);
              // margin-top: 5%;
              // text-align: center;
              height: 16vh;
              display: flex;
              align-items: center;
              ul {
                li {
                  display: list-item;
                  // padding-right: 0;
                  list-style-position: inside;
                  margin-left: -20px;
                }

              }
            }
          }
          .img_box{
            // background-color: green;
            height: 35vh;
            display: flex;
            justify-content: center;
            .anchor {
              width: 17vw;
              height: 22vh;
              margin-top: 34%;
            }
    
            .lender {
              width: 17vw;
              height: 34.4vh;
              // margin-top: 1%;
              // width: 288px;
              // height: 270px;
            }
          }
        }

       
      }
    }

    .ecosystem_mobile {
      display: none;
    }
  }
  .dots{
    position: absolute;
    width: 4vw;
    height: 30vh;
    margin-top: 38vh;
    margin-left: 96vw;
    // background: yellow;
    z-index: 2;
    
    .dot_white{
      width: 4px;
      height: 4px;
      // width: 1vw;
      // height: 1.8vh;
      background: white;
      border-radius: 50%;
      margin: 12px 10px 10px 20px;
      // margin: 1vh 1vw 1vh 2vw;
    }
    .dot_color{
      width: 12px;
      height: 12px;
      animation: 1s linear 0s  normal  Color_dot;
      // width: 1vw;
      // height: 1.8vh;
      // background: linear-gradient(94.77deg, #ff008a 6.23%, #2b51ec 95.59%);
      background: white;
      border-radius: 50%;
      margin: 12px 10px 10px 16px;
      // margin: 1vh 1vw 1vh 2vw;
    }
    @keyframes Color_dot {
      0% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
        // background-color: #ec407a;
    }
    }
  }

  .media_page {
    background: #000614;
    height: 100vh;

    .media_page_section {
      margin-left: 15vw;
      margin-right: 15vw;

      .vcfirm {
        // background-color: #0082ff;
        // margin-top: 15vh;
        height: 40vh;
        width: 70vw;
        display: flex;
        justify-content: space-evenly;

        .vcfirm_left {
          width: 40vw;
          // background-color: rgb(207, 123, 123);

          .vcfirm_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 120%;
            /* or 58px */

            letter-spacing: -0.02em;

            color: #ffffff;

            margin-top: 18vh;
            // margin-left: 5vw;
          }

          .vcfirm_color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .vcfirm_btn {
            margin-top: 8%;
            background: #272727;
            color: #ffffff;
            font-size: 12px;
            padding: 8px;
            border-radius: 8px;
            border: none;
          }
        }

        .vcfirm_right {
          width: 40vw;
          // background-color: rgb(99, 212, 207);

          .rocket_box {
            margin-top: 14vh;
            margin-left: 20vw;

            .rocket {
              width: 18vw;
              height: 14vw;
            }
          }
        }
      }

      .media {
        // background: yellow;
        height: 60vh;
        width: 70vw;
        margin-top: 40px;

        .react-multiple-carousel__arrow {
          z-index: 0;
        }

        .media_text {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 120%;
          /* identical to box height, or 58px */
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #ffffff;
          // display: inline;
        }

        .media_color_text {
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .media_img {
          width: 90%;
          height: 90%;
        }

        img {
          width: 22vw;
          // height: 260px;
        }

        .img_text {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          /* or 29px */

          letter-spacing: -0.02em;
          text-transform: uppercase;

          color: #ffffff;
        }
      }
    }

    // last page
    .join_nakad {
      // background: skyblue;
      width: 100vw;
      //height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 70vh;
      .rect {
        //height: 45%;
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        border-radius: 50px;
        margin: 100px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row;
        margin-bottom: 0;
        min-height: 260px;

        .left_rect {
          //width: 40%;
          text-align: center;
          .footerImg1 {
            margin-top: -240px;
            padding-bottom: 0;
            width: 100%;
            max-width: 450px;
          }
        }
        .right_rect {
          padding: 40px 0;
          padding-left: 0px;
  
          .text_box {
           max-width: 90%;
            .text {
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              font-size: 24px;
              // line-height: 44px;
              /* identical to box height */
  
              color: #ffffff;
              margin-bottom: 0px;
            }
            .footer_text {
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              // line-height: 29px;
  
              color: rgba(255, 255, 255, 0.6);
              display: flex;
              align-items: center;
            }
          }
  
          .footerBtn {
            background: #ffffff;
              box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.2);
              border-radius: 8px;
              padding: 10px 15px;
             // height: 5vh;
              border: none;
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
  
            .color_text {
              background: linear-gradient(
                94.77deg,
                #ff008a 6.23%,
                #8d3bbf 95.59%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              margin: 0 0;
            }
          }
        }
      }
    }

    footer {
      background: rgb(17 23 35);
      width: 100vw;
      height: 30vh;
      .row{
        &>div {
          display: inline-flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .copyright {
        // background: orangered;
        .copyright_text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 100% */
    
          font-feature-settings: "salt" on, "liga" off;
    
          /* Text / Gray 300 */
    
          color: #d9dbe1;
          display: flex;
          justify-content: center;
          margin-bottom: 25px;
        }
      }
      .footer_img {
        // background: white;
        max-width: 30vw;
        display: flex;
        justify-content: center;
        img {
          width: 20vw;
          margin-top: 15vh;
         }
      }
      .social {
        // background: green;
        max-width: 35vw;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        .icon_div {
          // background: red;
         // width: 70%;
          //height: 30%;
          //margin-top: 18vh;
          display: flex;
          // justify-content: space-between;
          align-items: center;
          justify-content: flex-start;
          .icon {
            justify-content: center;
            background: rgba(49, 53, 56, 0.548);
            align-items: center;
            display: flex;
            border-radius: 50%;
            //padding: 12px;
            margin-right: 10px;
            width: 40px;
            height: 40px;
            img{
              max-width: 20px;
            }
          }
        }
      }
    }
  }

  .media_page_new {
    background: #000614;
    width: 100vw;
    height: 100vh;
    // z-index: 4;
    .media_page_section {
      margin-left: 15vw;
      margin-right: 15vw;
      // background: yellow;

      .vcfirm {
        // background-color: #0082ff;
        // margin-top: 15vh;
        height: 40vh;
        width: 70vw;
        display: flex;
        justify-content: space-evenly;

        .vcfirm_left {
          width: 40vw;
          // background-color: rgb(207, 123, 123);

          .vcfirm_text {
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 120%;
            /* or 58px */

            letter-spacing: -0.02em;

            color: #ffffff;

            margin-top: 18vh;
            // margin-left: 5vw;
          }

          .vcfirm_color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .vcfirm_btn {
            width: 20vw;
            margin-top: 8%;
            background: #272727;
            color: #ffffff;
            font-size: 12px;
            padding: 8px;
            border-radius: 8px;
            border: none;
          }
          .vcfirm_img{
            width: 30vw;
            height: 10vh;
            margin-top: 5%;
           
            // padding: 8px;
          //  background: yellow;
          // background:rgba(255, 248, 227, 1);
           display: flex;
           .vcfirm_circle{
              width: 7vw;
              height: 7vw;
              background:rgba(255, 248, 227, 1);
              border-radius: 50%;
              margin-right: 30px;
              // img{
              //   width: 10vw;
              // }
              .matrix{
                width: 6vw;
                display: flex;
                margin: auto;
                margin-top: .8vw;
              }
              .accel{
                width: 7vw;
              }
              .advant{
                width: 7vw;
                margin-top: 2vw;
              }
           }
          
           
          }
        }

        .vcfirm_right {
          width: 40vw;
          // background-color: rgb(99, 212, 207);

          .rocket_box {
            margin-top: 14vh;
            margin-left: 20vw;

            .rocket {
              width: 18vw;
              height: 14vw;
            }
          }
        }
      }

      .media {
        // background: yellow;
        height: 60vh;
        width: 66vw;
        margin-top: 68px;

        a{
          text-decoration: none;
        }

        .react-multiple-carousel__arrow {
          z-index: 0;
        }

        .media_text {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 120%;
          /* identical to box height, or 58px */
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #ffffff;
          padding-bottom: 20px;
          // display: inline;
        }

        .media_color_text {
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .media_img {
          width: 90%;
          height: 90%;
          cursor: pointer;
          img {
            width: 21vw;
            height: 30vh;
            // height: 260px;
          }
          .vcCircle{
            height: 30vh;
          }
        .img_text {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          /* or 29px */

          letter-spacing: -0.02em;
          text-transform: uppercase;

          color: #ffffff;
          padding-top: 10px;
        }
      }
      }
    }
  }

        .scscrollGif_box {
          width: 100vw;
          // background: red;
          margin-top: -1vh;
          z-index: 1;
          position: absolute;
    
          img {
            height: 7vh;
            display: flex;
            margin: auto;
          }
    
          .arrows {
            width: 60px;
            height: 72px;
            position: absolute;
            left: 50%;
            margin-left: -30px;
            bottom: 20px;
          }
    
          .arrows path {
            // stroke: #2994D1;
            stroke: white;
            fill: transparent;
            stroke-width: 2px;
            animation: arrow 2s infinite;
            -webkit-animation: arrow 2s infinite;
          }
    
          @keyframes arrow {
            0% {
              opacity: 0
            }
    
            40% {
              opacity: 1
            }
    
            80% {
              opacity: 0
            }
    
            100% {
              opacity: 0
            }
          }
    
          @-webkit-keyframes arrow
    
          /*Safari and Chrome*/
            {
            0% {
              opacity: 0
            }
    
            40% {
              opacity: 1
            }
    
            80% {
              opacity: 0
            }
    
            100% {
              opacity: 0
            }
          }
    
          .arrows path.a1 {
            animation-delay: -1s;
            -webkit-animation-delay: -1s;
            /* Safari 和 Chrome */
          }
    
          .arrows path.a2 {
            animation-delay: -0.5s;
            -webkit-animation-delay: -0.5s;
            /* Safari 和 Chrome */
          }
    
          .arrows path.a3 {
            animation-delay: 0s;
            -webkit-animation-delay: 0s;
            /* Safari 和 Chrome */
          }
        }
}


.btnx {

  // background: red;
  a {
    color: #000614;
  }

  // display: flex;
  // align-items: center;
  // position: absolute;
  //   left: 50%;
  //   top: 90%;
  margin-top: 70vh;
  margin-left: 8vw;
}




//--------------------------------------//


/* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .home_page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;

    .home_1_container {
      width: 100vw;
      height: 100vh;
      // background: red;
      position: absolute;
      overflow: hidden;

      .left {
        // width: 40%;
        width: 100vw;
        height: 38vh;
        margin-top: 55vh;
        .group1 {
          position: fixed;
          width: 10vw;
          height: 5vh;
          margin-left: -2vw;
        }

        .group2 {
          position: fixed;
          float: right;
          // margin-top: 10%;
          // margin-left: 32vw;
          margin-left: 85vw;
          // margin-top: 5vh;
          margin-top: 0vh;
          width: 8vw;
          height: 8vh;
        }

        .group3 {
          position: fixed;
          // margin-top: 15vh;
          margin-top: 1vh;
          // margin-left: 70px;
          width: 12vw;
          /* height: 12vw; */
          height: 10vh;
          margin-left: 8vw;
          display: none;
        }

        .group4 {
          position: fixed;
          margin-top: 27vh;
          margin-left: 5vw;
          width: 6vw;
        }

        .group5 {
          position: fixed;
          margin-top: 27vh;
          float: right;
          margin-left: 64vw;
          width: 10vw;
        }

        #second {
          opacity: 0;
        }

        #third {
          opacity: 0;
        }

        #fourth {
          opacity: 0;
        }

        #fifth {
          opacity: 0;
        }

        #sixth {
          opacity: 0;
        }
        .text_container_unlock{
          display: none;
        }
        .text_container_unlock_mobile{
          position: absolute;

          width: 100vw;
          height: 30vh;
          margin-top: 6vh;
          margin-left: 0vw;
          display: flex;
          justify-content: center;
          .text{
            position: fixed;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            // font-size: 52px;
            // font-size: 36px;
            font-size: 38px;
            line-height: 120%;
            /* or 62px */

            letter-spacing: -0.03em;

            color: #ffffff;
            text-align: center;
            .color_text{
              background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .text_container_cashflow{
          display: none;
        }
        .text_container_cashflow_mobile{
          position: absolute;

          width: 100vw;
          height: 30vh;
          margin-top: 6vh;
          margin-left: 0vw;
          display: flex;
          justify-content: center;
          .text{
            position: fixed;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            // font-size: 52px;
            // font-size: 36px;
            font-size: 36px;
            line-height: 120%;
            /* or 62px */

            letter-spacing: -0.03em;

            color: #ffffff;
            text-align: center;
            .color_text{
              background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .text_container {

          position: absolute;

          width: 100vw;
          height: 30vh;
          margin-top: 6vh;
          margin-left: 0vw;
          display: flex;
          justify-content: center;



          .text {
            position: fixed;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            // font-size: 52px;
            // font-size: 36px;
            font-size: 25px;
            line-height: 120%;
            /* or 62px */

            letter-spacing: -0.03em;

            color: #ffffff;

            .color_text {
              background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .box {
              // background: yellow;
              width: 74vw;
              // height: 10vh;
              padding-top: 1px;
              margin-top: 1vh;
              display: flex;
              // flex-direction: row;
              background: rgba(77, 29, 110, 0.56);
              border-radius: 0px 8px 8px 0px;

              .left_box {
                background: linear-gradient(144.98deg,
                    #ff008a 16.34%,
                    #0082ff 77.38%);
                border-radius: 0px 29px 29px 0px;
                width: 0.6vw;
              }

              .right_box {
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 225%;
                margin-top: auto;
                /* or 54px */

                letter-spacing: 0.105em;

                color: rgba(255, 255, 255, 0.7);

              }
            }
          }
          .second_text{
            margin-left: 6.5px;
          }
        }
      }

      .right {
        // position: absolute;
        // position: fixed;
        // width: 100vw;
        // height: 70vh;
        // margin-top: 14vh;
        margin-top: 9vh;
        margin-left: 0vw;
        width: 100vw;

        // background: rgb(214, 218, 158);
        img {
          // z-index: 3;
        }

        .tier2 {
          // width: 300px;
          // height: 200px;
          position: fixed;
          // width: 32vw;
          // // height: 20vh;
          // height: 25vh;
          width: 32vw;
    height: 12vh;
          margin-left: 32vw;
          margin-top: 8vh;
        }

        .tier1 {
          // width: 300px;
          // height: 200px;
          position: fixed;
          // width: 32vw;
          // // height: 20vh;
          // height: 25vh;
          width: 32vw;
    height: 12vh;
          // margin-left: 18vw;
          // margin-left: 36vw;
          // margin-top: 24vh;
          margin-left: 59vw;
          margin-top: 16vh;
        }

        .anchor {
          // width: 300px;
          // height: 200px;
          position: fixed;
          // width: 32vw;
          // // height: 20vh;
          // height: 25vh;
          width: 32vw;
         height: 12vh;
          // margin-left: 18vw;
          // margin-top: 44vh;
          margin-left: 32vw;
          // margin-top: 36vh;
          // margin-top: 20vh;
          margin-top: 24vh;
          z-index: 1;
        }

        .lender {
          position: fixed;
          //     width: 20vw;
          // height: 40vh;

          width: 32vw;
          height: 18vh;
          // margin-top: 6vh;
          margin-top: 10vh;
          margin-left: 7vw;
          // margin-top: 15vh;
          // margin-left: 18vw;

          opacity: 0;
          z-index: 1;
        }
      }

      //-------//

      .lender_text_box {
        position: absolute;
        margin-top: 35vh;
        // margin-left: -180px;
        // margin-left: -12vw;
        margin-left: 0vw;
        opacity: 0;
        // transform: rotate(2deg);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        text-align: center;
         width: 24vw;
        p {
          padding: 0;
          margin: 0;
        }

        .lender_text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 90%;
          /* or 50px */
          color: rgba(255, 255, 255, 0.4);

          text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
          // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
          // transform: none;
          margin-left: -10vw;
          transform: rotate(1deg);
        }

        .lender_sub_text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 120%;
          margin-left: 0vw;
          /* identical to box height, or 29px */


          color: rgba(255, 255, 255, 0.4);

          text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
          // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
          // transform: none;
          transform: rotate(1deg);
        }
      }

      .anchor_text_box {
        position: absolute;
        margin-top: 42vh;
        margin-left: 18vw;
        // transform: rotate(2deg);
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        text-align: center;
        // width: 21vw;
        p {
          padding: 0;
          margin: 0;
        }

        .anchor_text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 90%;
          /* or 50px */
          color: rgba(255, 255, 255, 0.4);

          text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);

          // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
          transform: none;
        }

        .anchor_sub_text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 120%;
          margin-left: 0vw;
          /* identical to box height, or 29px */


          color: rgba(255, 255, 255, 0.4);

          text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
          // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
          transform: none;
        }
      }

      .tier1_text_box {
        position: absolute;
        // margin-top: 38vh;
        margin-top: 34vh;
        margin-left: 79vw;
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        text-align: center;
        width: 21vw;
        p {
          padding: 0;
          margin: 0;
        }

        .tier1_text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 90%;
          /* or 50px */
          color: rgba(255, 255, 255, 0.4);

          text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
          // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
          transform: none;
        }

        .tier1_sub_text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 120%;
          margin-left: 0vw;
          /* identical to box height, or 29px */


          color: rgba(255, 255, 255, 0.4);

          text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
          // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
          transform: none;
        }
      }

      .tier2_text_box {
        position: absolute;
        // margin-top: 20vh;
        margin-top: 13vh;
        margin-left: 19vw;
        transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
        text-align: center;
        p {
          padding: 0;
          margin: 0;
        }

        .tier2_text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 90%;
          /* or 50px */
          color: rgba(255, 255, 255, 0.4);

          text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.25);
          // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
          // transform: none;
          transform: rotate(2deg);
          margin-left: 0px;
        }

        .tier2_sub_text {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 120%;
          margin-left: -4vw;
          /* identical to box height, or 29px */


          color: rgba(255, 255, 255, 0.4);

          text-shadow: 0px 31.607px 22.3108px rgba(0, 0, 0, 0.25);
          // transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
          // transform: none;
          transform: rotate(2deg);
          margin-top: 0px;
        }
      }

      .truck1_stop{
        position: absolute;
        width: 5vw;
        margin-left: 55vw;
        margin-top: 24.5vh;
        opacity: 1;
      }

      .truck1 {
        position: absolute;
        width: 5vw;
        margin-left: 52vw;
        margin-top: 23.5vh;
        z-index: -1;
        animation: 3.5s linear 0s infinite normal forwards running TruckL2R;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      }
      @keyframes TruckL2R {
        0% {
          transform: translateX(0px) translateY(0px);
          // opacity: 1;
        }
        95% {
          // opacity: 1;
        }
        100% {
          transform: translateX(65px) translateY(45px);
          // transform: translateX(32.5px) translateY(22.5px);
          opacity: 0;
        }
      }
      .truck1_opacity_less {
        position: absolute;
        width: 5vw;
        margin-left: 52vw;
        margin-top: 23.5vh;
        z-index: -1;
        animation: 3.5s linear 0s infinite normal forwards running TruckL2R_less;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      opacity: 0.4;
      }
      @keyframes TruckL2R_less {
        0% {
          transform: translateX(0px) translateY(0px);
          // opacity: 1;
          opacity: 0.4;
        }
        95% {
          // opacity: 1;
        }
        100% {
          transform: translateX(65px) translateY(45px);
          // transform: translateX(32.5px) translateY(22.5px);
          opacity: 0;
        }
      }
      .pathL2R {
        position: absolute;
        width: 15vw;
        margin-left: 54vw;
        margin-top: 25vh;
        z-index: -1;
    }
    .truck2_stop{
      position: fixed;
        width: 5vw;
        margin-left: 61vw;
        margin-top: 32vh;
        opacity: 1;
    }
      .truck2 {
        position: fixed;
        width: 5vw;
        margin-left: 66vw;
        margin-top: 31vh;
        z-index: -1;
        animation: 3.5s linear 0s infinite normal forwards running TruckR2L;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      }
      @keyframes TruckR2L {
        0% {
          transform: translateX(0px) translateY(0px);
          // opacity: 1;
        }
        95% {
          // opacity: 1;
        }
        100% {
          transform: translateX(-70px) translateY(41px);
          // transform: translateX(-35px) translateY(20.5px);
          opacity: 0;
        }
      }
      .truck2_opacity_less {
        position: fixed;
        width: 5vw;
        margin-left: 66vw;
        margin-top: 31vh;
        z-index: -1;
        animation: 3.5s linear 0s infinite normal forwards running TruckR2L_less;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      opacity: 0.4;
      }
      @keyframes TruckR2L_less {
        0% {
          transform: translateX(0px) translateY(0px);
          // opacity: 1;
          opacity: 0.4;
        }
        95% {
          // opacity: 1;
        }
        100% {
          transform: translateX(-70px) translateY(41px);
          // transform: translateX(-35px) translateY(20.5px);
          opacity: 0;
        }
      }
      .pathR2L {
        position: fixed;
        width: 15vw;
        margin-left: 54vw;
        margin-top: 32vh;
        z-index: -1;
    }
    .coin_upward{
      animation: 6s linear 0s infinite normal forwards running CoinUp;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      // opacity: 1;
      position: absolute;
        height: 12vh;
        margin-top: 39vh;
        margin-left: 63vw;
        opacity: 0;
      // z-index: 0;
      
    }
    @keyframes CoinUp {
      0% {
        transform: translateX(0px) translateY(0px);
        // opacity: 1;
        z-index: 1;
      }
      33.33%, 100%{
        // transform: translateX(144px) translateY(-116px);
        transform: translateX(57.6px) translateY(-46.4px);
        opacity: 0;
        z-index: 0;
      }
      // 60%{
      //   opacity: 1;
      // }
      // 95% {
      // 	opacity: 1;
      // }
      // 100% {
      // 	transform: translateX(144px) translateY(-116px);
      // 	opacity: 0;
      // }
    }
    .coin_upward_visible{
      animation: 6s linear 0s infinite normal forwards running CoinUp_visible;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      // opacity: 1;
      position: absolute;
        height: 12vh;
        margin-top: 39vh;
        margin-left: 63vw;
        opacity: 1;
      z-index: 0;
      
    }
    @keyframes CoinUp_visible {
      0% {
        transform: translateX(0px) translateY(0px);
        // opacity: 1;
        z-index: 1;
      }
      33.33%, 100%{
        // transform: translateX(144px) translateY(-116px);
        transform: translateX(57.6px) translateY(-46.4px);
        opacity: 0;
        z-index: 0;
      }
      // 60%{
      //   opacity: 1;
      // }
      // 95% {
      // 	opacity: 1;
      // }
      // 100% {
      // 	transform: translateX(144px) translateY(-116px);
      // 	opacity: 0;
      // }
    }

    
    
    .Small_coin2{
      animation: 6s linear 0s infinite normal forwards running Small_coin2;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      // opacity: 0;
      z-index: 0;
      position: absolute;
      height: 12vh;
      margin-top: 18vh;
      margin-left: 83vw;
      opacity: 0;
    }
    @keyframes Small_coin2 {
      0%, 33.33% {
        transform: translateX(0px) translateY(0px);
        opacity: 0;
        z-index: 0;
      }
      // 50% {
      // 	transform: translateX(0px) translateY(0px);
      // 	opacity: 0;
      // }
      35%{
        // opacity: 1;
        z-index: 1;
      }
      65%{
        // opacity: 1;
        z-index: 1;
      }
      66.67%, 100% {
        // transform: translateX(-139px) translateY(-97px);
        transform: translateX(-77.84px) translateY(-54.32px);
        // opacity: 0;
        z-index: 0;
      }
      // 52.5% {
      // 	opacity: 1;
      // }
      // 97.5% {
      // 	opacity: 1;
      // }
    }

    .Small_coin2_visible{
      animation: 6s linear 0s infinite normal forwards running Small_coin2_visible;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      // opacity: 0;
      z-index: 0;
      position: absolute;
      height: 12vh;
      margin-top: 18vh;
      margin-left: 83vw;
      opacity: 1;
    }
    @keyframes Small_coin2_visible {
      0%, 33.33% {
        transform: translateX(0px) translateY(0px);
        opacity: 0;
        z-index: 0;
      }
      // 50% {
      // 	transform: translateX(0px) translateY(0px);
      // 	opacity: 0;
      // }
      35%{
        // opacity: 1;
        z-index: 1;
      }
      65%{
        // opacity: 1;
        z-index: 1;
      }
      66.67%, 100% {
        // transform: translateX(-139px) translateY(-97px);
        transform: translateX(-77.84px) translateY(-54.32px);
        // opacity: 0;
        z-index: 0;
      }
      // 52.5% {
      // 	opacity: 1;
      // }
      // 97.5% {
      // 	opacity: 1;
      // }
    }
    
    .small_coin1{
      animation: 6s linear 0s infinite normal forwards running small_coin1;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      position: absolute;
      height: 12vh;
      margin-top: 18vh;
      margin-left: 83vw;
      opacity: 0;
      // opacity: 0;
      // z-index: 0;
    }
    @keyframes small_coin1 {
      0%, 33.33% {
        transform: translateX(0px) translateY(0px);
        opacity: 0;
        z-index: 1;
      }
      35%{
        // opacity: 1;
        z-index: 1;
      }
      65%{
        // opacity: 1;
        z-index: 1;
      }
      // 50% {
      // 	transform: translateX(0px) translateY(0px);
      // 	opacity: 0;
      // }
      66.67%, 100% {
        transform: translateX(0px) translateY(0px);
        // opacity: 0;
        z-index: 1;
      }
      // 52.5% {
      // 	opacity: 1;
      // }
    }
    
    .small_coin1{
      animation: 6s linear 0s infinite normal forwards running small_coin1;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      position: absolute;
      height: 12vh;
      margin-top: 18vh;
      margin-left: 83vw;
      opacity: 0;
      // opacity: 0;
      // z-index: 0;
    }
    @keyframes small_coin1 {
      0%, 33.33% {
        transform: translateX(0px) translateY(0px);
        opacity: 0;
        z-index: 1;
      }
      35%{
        // opacity: 1;
        z-index: 1;
      }
      65%{
        // opacity: 1;
        z-index: 1;
      }
      // 50% {
      // 	transform: translateX(0px) translateY(0px);
      // 	opacity: 0;
      // }
      66.67%, 100% {
        transform: translateX(0px) translateY(0px);
        // opacity: 0;
        z-index: 1;
      }
      // 52.5% {
      // 	opacity: 1;
      // }
    }

    .small_coin1_visible{
      animation: 6s linear 0s infinite normal forwards running small_coin1_visible;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      position: absolute;
      height: 12vh;
      margin-top: 18vh;
      margin-left: 83vw;
      opacity: 1;
      // opacity: 0;
      // z-index: 0;
    }
    @keyframes small_coin1_visible {
      0%, 33.33% {
        transform: translateX(0px) translateY(0px);
        opacity: 0;
        z-index: 1;
      }
      35%{
        // opacity: 1;
        z-index: 1;
      }
      65%{
        // opacity: 1;
        z-index: 1;
      }
      // 50% {
      // 	transform: translateX(0px) translateY(0px);
      // 	opacity: 0;
      // }
      66.67%, 100% {
        transform: translateX(0px) translateY(0px);
        // opacity: 0;
        z-index: 1;
      }
      // 52.5% {
      // 	opacity: 1;
      // }
    }

    .coin_downward{
      animation: 6s linear 0s infinite normal forwards running CoinDown;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      // opacity: 0;
      z-index: 0;
      position: absolute;
      height: 12vh;
      margin-top: 13vh;
      margin-left: 18vw;
      opacity: 0;
    }
    @keyframes CoinDown {
      0%, 66.67% {
        transform: translateX(0px) translateY(0px);
        opacity: 0;
        z-index: 0;
      }
      100% {
        // transform: translateX(-161px) translateY(112px);
        transform: translateX(-64.4px) translateY(44.8px);
        // opacity: 1;
        z-index: 1;
      }
      // 66.67% {
      // 	transform: translateX(0px) translateY(0px);
      // 	opacity: 0;
      // }
      // 68.33% {
      // 	opacity: 1;
      // }
    }
    .coin_downward_visible{
      animation: 6s linear 0s infinite normal forwards running CoinDown_visible;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      width: 8vw;
      // opacity: 0;
      z-index: 0;
      position: absolute;
      height: 12vh;
      margin-top: 13vh;
      margin-left: 18vw;
      opacity: 1;
    }
    @keyframes CoinDown_visible {
      0%, 66.67% {
        transform: translateX(0px) translateY(0px);
        opacity: 0;
        z-index: 0;
      }
      100% {
        // transform: translateX(-161px) translateY(112px);
        transform: translateX(-64.4px) translateY(44.8px);
        // opacity: 1;
        z-index: 1;
      }
      // 66.67% {
      // 	transform: translateX(0px) translateY(0px);
      // 	opacity: 0;
      // }
      // 68.33% {
      // 	opacity: 1;
      // }
    }
      .arrow1 {
        position: absolute;
        height: 10vh;
        // margin-top: 22vh;
        margin-top: 17vh;
        margin-left: 59vw;
        transform: rotate(-1deg);
        opacity: 0
      }

      .arrow2 {
        position: absolute;
        height: 10vh;
        // margin-top: 41vh;
        margin-top: 37vh;
        margin-left: 52vw;
        opacity: 0
      }

      .arrow3 {
        position: absolute;
        height: 10vh;
        // margin-top: 22vh;
        margin-top: 18vh;
        margin-left: 2vw;
        opacity: 0;
        // transform: rotate(2deg);
        z-index: -1;
      }

      // .star1 {
      //   position: absolute;
      //   height: 12vh;
      //   margin-top: 18vh;
      //   margin-left: 83vw;
      //   opacity: 0;
      //   // animation-delay: 2s;
      // }

      // .star2 {
      //   position: absolute;
      //   height: 12vh;
      //   margin-top: 39vh;
      //   margin-left: 63vw;
      //   opacity: 1
      // }

      // .star3 {
      //   position: absolute;
      //   height: 12vh;
      //   margin-top: 13vh;
      //   margin-left: 18vw;
      //   opacity: 0;
      //   // animation-delay: 4s;
      // }

      .ecosystem {
        display: none;
      }

      .ecosystem_mobile {
        display: block;
        position: absolute;
        width: 100vw;
        height: 100vh;

        .title {
          // background: skyblue;
          display: flex;
          justify-content: center;
          height: 10vh;
          margin-top: 15vh;


          .text {
            // position: fixed;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            // font-size: 52px;
            font-size: 28px;
            line-height: 120%;
            margin-left: 0vw;
            /* or 62px */

            letter-spacing: -0.03em;

            color: #ffffff;
            text-align: center;

            .color_text {
              background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        .card1 {
          // background: yellow;
          width: 100vw;
          height: 70vh;

          .inside_eco {
            // background: #d1da57;
            width: 20vw;
            height: 100%;

            .eco_text {
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              // font-size: 42px;
              font-size: 26px;
              line-height: 120%;
              /* or 50px */

              text-align: center;

              color: #ffffff;
              width: 100vw;
              margin-top: 2vh;
            }

            .box {
              // background: yellow;
              position: absolute;
              width: 90vw;
              height: 15vh;
              margin-top: 7vh;
              // display: flex;
              // flex-direction: row;
              background: rgba(77, 29, 110, 0.56);
              border-radius: 8px;
              margin-left: 4vw;

              // background: yellow;
              .left_box {
                background: linear-gradient(144.98deg,
                    #ff008a 16.34%,
                    #0082ff 77.38%);
                border-radius: 0px 29px 29px 0px;
                height: 0.6vh;
                width: 50%;
                display: flex;
                justify-content: center;
                border-radius: 0px 0px 29px 29px;
                margin: auto;
              }

              .right_box {
                // background: #0082ff;
                font-family: Inter;
                font-style: normal;
                font-weight: 800;
                font-size: 14.5px;
                line-height: 225%;
                /* or 54px */

                letter-spacing: 0.105em;

                color: rgba(255, 255, 255, 0.7);
                // margin-top: 5%;
                height: 14vh;
                display: flex;
                align-items: center;

                ul {
                  li {
                    display: list-item;
                    // padding-right: 0;
                    list-style-position: inside;
                  }

                }
              }
            }

            .anchor {
              width: 65vw;
              height: 22vh;
              // margin-top: 34%;
              margin-top: 72%;
              margin-left: 17vw;
            }

            .lender {
              width: 55vw;
              height: 30vh;
              margin-top: 15%;
              margin-left: 21vw;
            }
          }
        }
      }
    }

    .dots{
      position: absolute;
      width: 4vw;
      height: 30vh;
      margin-top: 40vh;
      margin-left: 87vw;
      // background: yellow;
      z-index: 2;
      
      .dot_white{
        width: 4px;
        height: 4px;
        // width: 1vw;
        // height: 1.8vh;
        background: white;
        border-radius: 50%;
        margin: 10px 10px 10px 20px;
        // margin: 1vh 1vw 1vh 2vw;
      }
      .dot_color{
        width: 12px;
        height: 12px;
        animation: 1s linear 0s  normal  Color_dot;
        // width: 1vw;
        // height: 1.8vh;
        // background: linear-gradient(94.77deg, #ff008a 6.23%, #2b51ec 95.59%);
        background: white;
        border-radius: 50%;
        margin: 10px 10px 10px 16px;
        // margin: 1vh 1vw 1vh 2vw;
      }
      @keyframes Color_dot {
        0% {
          transform: scale(0.0);
          -webkit-transform: scale(0.0);
      }
      50% {
          transform: scale(1);
          -webkit-transform: scale(1);
          // background-color: #ec407a;
      }
      }
    }

    .media_page {
      background: #000614;
      // height: 100vh;
      height: 100vh;
      overflow: hidden;

      .media_page_section {
        margin-left: 15vw;
        margin-right: 15vw;

        .vcfirm {
          // background-color: #0082ff;
          // margin-top: 15vh;
          height: 40vh;
          width: 70vw;
          display: flex;
          justify-content: space-evenly;

          .vcfirm_left {
            width: 40vw;
            // background-color: rgb(207, 123, 123);

            .vcfirm_text {
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              font-size: 30px;
              line-height: 120%;
              /* or 58px */

              letter-spacing: -0.02em;

              color: #ffffff;

              margin-top: 18vh;
              // margin-left: 5vw;
            }

            .vcfirm_color_text {
              background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .vcfirm_btn {
              margin-top: 8%;
              background: #272727;
              color: #ffffff;
              font-size: 12px;
              padding: 8px;
              border-radius: 8px;
              border: none;
            }
          }

          .vcfirm_right {
            width: 40vw;
            // background-color: rgb(99, 212, 207);

            .rocket_box {
              margin-top: 14vh;
              margin-left: 20vw;

              .rocket {
                width: 18vw;
                height: 14vw;
              }
            }
          }
        }

        .media {
          // background: yellow;
          height: 60vh;
          width: 70vw;
          margin-top: 40px;

          .react-multiple-carousel__arrow {
            z-index: 0;
          }

          .media_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 120%;
            /* identical to box height, or 58px */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #ffffff;
            // display: inline;
          }

          .media_color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .media_img {
            width: 90%;
            height: 90%;
          }

          img {
            width: 22vw;
            // height: 260px;
          }

          .img_text {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            /* or 29px */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: #ffffff;
          }
        }
      }

       // last page
       .join_nakad {
        // background: skyblue;
        width: 100vw;
        // height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        min-height: 64vh;
        .rect {
          flex-flow: column;
          margin: 0;
          height: 100%;
          margin-top: 135px;
          .left_rect {
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            .footerImg1 {
              margin-top: -220px;
              padding-bottom: 0px;
              max-width: 270px;
              max-height: 100%;
             }
           }
          .right_rect {
            width:100%;
            padding-left: 0;
            padding-top: 10px;
            padding-bottom: 15px;
    
            .text_box {
              max-width: 100%;
              text-align: center;
              .text {
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                // line-height: 44px;
                /* identical to box height */
    
                color: #ffffff;
                // margin-bottom: 0px;
                text-align: center;
               // margin-left: -6vw;
              }
              .footer_text {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                // line-height: 29px;
                padding: 0 40px;
                color: rgba(255, 255, 255, 0.6);
                // text-align: center;
                margin-top: 1vh;
                display: flex;
               align-items: center;
              }
            }
    
            div{
              width: 100%;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              flex-flow: column;
              .footerBtn {
                background: #ffffff;
                box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.2);
                border-radius: 12px;
                padding: 5px 15px;
               // height: 5vh;
                border: none;
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                // line-height: 40px;
                // margin-left: 5vw;
                //margin-left: 2.5vw;
                // margin-top: 6vh;
      
                .color_text {
                  background: linear-gradient(
                    94.77deg,
                    #ff008a 6.23%,
                    #8d3bbf 95.59%
                  );
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  margin-top: 0px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      footer {
        width: 100vw;
        display: inline-block;
        min-height: 36vh;
        .row{
          &>div {
            display: inline-flex;
            justify-content: center;
            align-items: flex-end;
          }
        }
       
        .copyright {
         
          .copyright_text {
            margin-top: 20px;
          }
        }
        .footer_img {
          // background: white;
          max-width: 100vw;
          img {
            margin-top: 1vh;
            width: 30vw;
          }
        }
        .quick_links{
          margin-top: 15px;
        }
        .social {
           margin-top: 2vh;
           max-width: 100%;
          .icon_div {
            // background: red;
            width: 100%;
            //height: 30%;
            margin-top: 1vh;
            //margin-right: 5vw;
            display: flex;
            // justify-content: space-between;
            justify-content: center;
            // align-items: center;
            .icon{
              padding: 0 10px;
            }
          }
        }
       
      }
    }

    .media_page_new {
      background: #000614;
      width: 100vw;
      height: 100vh;
      // z-index: 4;
      .media_page_section {
        // margin-left: 0vw;
        // margin-right: 0vw;
        // background: yellow;
  
        .vcfirm {
          // background-color: #0082ff;
          // margin-top: 15vh;
          height: 40vh;
          width: 80vw;
          display: flex;
          justify-content: space-evenly;
  
          .vcfirm_left {
            width: 40vw;
            // background-color: rgb(207, 123, 123);
  
            .vcfirm_text {
              display: flex;
              flex-direction: column;
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              font-size: 30px;
              line-height: 120%;
              /* or 58px */
  
              letter-spacing: -0.02em;
  
              color: #ffffff;
  
              margin-top: 10vh;
              // margin-left: 5vw;
            }
  
            .vcfirm_color_text {
              background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
  
            .vcfirm_btn {
              width: 45vw;
              margin-top: 8%;
              background: #272727;
              color: #ffffff;
              font-size: 12px;
              padding: 8px;
              border-radius: 8px;
              border: none;
            }
            .vcfirm_img{
              width: 70vw;
              height: 10vh;
              margin-top: 22%;
             
              // padding: 8px;
            //  background: yellow;
            // background:rgba(255, 248, 227, 1);
             display: flex;
             .vcfirm_circle{
              width: 17.5vw;
              height: 17.5vw;
              background:rgba(255, 248, 227, 1);
              border-radius: 50%;
              margin-right: 30px;
              // img{
              //   width: 22vw;
              // }
              .matrix{
                width: 17vw;
              }
              .accel{
                width: 17vw;
              }
              .advant{
                width: 17vw;
                margin-top: 6vw;
              }
           }
            }
          }
  
          .vcfirm_right {
            width: 40vw;
            // background-color: rgb(99, 212, 207);
  
            .rocket_box {
              margin-top: 10vh;
              margin-left: 8vw;
  
              .rocket {
                width: 36vw;
                height: 36vw;
              }
            }
          }
        }
  
        .media {
          // background: yellow;
          height: 40vh;
          width: 70vw;
          margin-top: 40px;
  
          a{
            text-decoration: none;
          }

          .react-multiple-carousel__arrow {
            z-index: 0;
          }
  
          .media_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 120%;
            /* identical to box height, or 58px */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #ffffff;
            padding-bottom: 20px;
            padding-top: 20px;
            // display: inline;
          }
  
          .media_color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
  
          .media_img {
            width: 70vw;
            height: 40vh;
            cursor: pointer;
            img {
              width: 70vw;
              height: 25vh;
              // height: 260px;
            }
            .vcCircle{
              height: 25vh;
            }
            .img_text {
              font-family: Inter;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 120%;
              /* or 29px */
    
              letter-spacing: -0.02em;
              text-transform: uppercase;
    
              color: #ffffff;
              padding-top: 10px;
            }
          }
  
        }
      }

    }

    .scscrollGif_box{
      width: 100vw;
    // background: red;
    margin-top: -5vh;
    z-index: 1;
    position: relative;
    img{
      height: 7vh;
    display: flex;
    margin: auto;
    }
    .arrows {
      width: 60px;
      height: 72px;
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: 20px;
    }

    .arrows path {
      // stroke: #2994D1;
      stroke: white;
      fill: transparent;
      stroke-width: 2px;
      animation: arrow 2s infinite;
      -webkit-animation: arrow 2s infinite;
    }

    @keyframes arrow {
      0% {
        opacity: 0
      }

      40% {
        opacity: 1
      }

      80% {
        opacity: 0
      }

      100% {
        opacity: 0
      }
    }

    @-webkit-keyframes arrow

    /*Safari and Chrome*/
      {
      0% {
        opacity: 0
      }

      40% {
        opacity: 1
      }

      80% {
        opacity: 0
      }

      100% {
        opacity: 0
      }
    }

    .arrows path.a1 {
      animation-delay: -1s;
      -webkit-animation-delay: -1s;
      /* Safari 和 Chrome */
    }

    .arrows path.a2 {
      animation-delay: -0.5s;
      -webkit-animation-delay: -0.5s;
      /* Safari 和 Chrome */
    }

    .arrows path.a3 {
      animation-delay: 0s;
      -webkit-animation-delay: 0s;
      /* Safari 和 Chrome */
    }
    }
  }
}