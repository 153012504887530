.Solutions_container{
    background: linear-gradient(90deg, #7A1C72 0%, #581953 100%);
    // padding-top: 80px;

      .mt_40{
        margin-top: 40px;
      }

    .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 70px;
        padding: 70px 70px 70px 70px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 103%;
        /* identical to box height, or 54px */
    
        text-align: center;
    
        /* White */
    
        color: #ffffff;
  
        .rect_line_left{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
  margin-right: 9px;
        }
        .rect_line_right{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
  margin-left: 9px;
        }
    
        .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    
      .container{
        border-radius: 20px;
    border: 2px solid #FF018A;
    box-shadow: 4px 0px 33.7px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    // gap: 40px;
    width: 1120px;

    .text_subhead{
        color: #FFFFFF;
        font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.28px;
margin-bottom: 20px;
    }
    .card_bg_1{
        border-radius: 15px;
background: linear-gradient(90deg, #D07 33.27%, #1181FD 100%), linear-gradient(90deg, #09A756 0%, rgba(117, 28, 110, 0.00) 100%);
    }
    .card_bg_2{
        border-radius: 15px;
        background: linear-gradient(90deg, #6201FF 48.86%, #D07 100%), linear-gradient(90deg, #26B27C 30.38%, #F6C03D 100%);
    }
.card{
    display: flex;
height: 184px;
// padding: 24px;
flex-direction: column;
align-items: flex-start;
// gap: 20px;
flex: 1 0 0;
border-radius: 15px;
border: none;
margin-top: 20px;

    .card-body{
display: flex;

.card-title{
      color: #FFF;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.28px;
}
.card-text{
    color: #FFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.card-text-service{
    color: #FFF;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.card1img{
    position: absolute;
    right: -27px;
    top: 10px;
}
    }}
      }
      .pb_80{
        padding-bottom: 80px;
      }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    /* Styles for X-Small devices */
    .Solutions_container{
        .heading {
            display: flex;
            align-items: center;
            justify-content: center;
            // padding: 70px;
            padding: 40px 30px 40px 30px;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 103%;
            /* identical to box height, or 54px */
        
            text-align: center;
        
            /* White */
        
            color: #ffffff;
      
            .rect_line_left{
                width: 39.873px;
                height: 3.418px;
      background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
      margin-right: 4px;
            }
            .rect_line_right{
                width: 39.873px;
                height: 3.418px;
      background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
      margin-right: 4px;
            }
        
            .color_text {
                background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        .container{
            width: 90%;
            padding: 40px 15px;

            .text_subhead{
                color: #FFFFFF;
                font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.16px;
margin-bottom: 20px;
            }

            .card{
                display: flex;
height: 110px;
padding: 12.973px;
flex-direction: column;
align-items: flex-start;
gap: 10.811px;
align-self: stretch;
                .card-body{
                    display: flex;
                    padding: 6px;
            .card-title{
                color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.16px;
            }
            .card-text{
                color: #FFF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
            }
            .card-text-service{
                color: #FFF;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            }
            .card1img{
                position: absolute;
                right: -15.35px;
                bottom: 5.483px;
                width: 107.031px;
height: 85.949px;
            }
        }
        }
        }
      }
  }
   
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    /* Styles for Small devices */
    .Solutions_container{
    .container{
        // width: 540px;
        .card{
            .card-body{
        .card-title{
            color: #FFF;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        }
        .card-text{
            color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        }
        .card-text-service{
            color: #FFF;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        }
    }
    }
    }
  }
}
   
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    /* Styles for Medium devices */
  }
   
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .Solutions_container{
        .container{
            width: 960px;
            .card{
                .card-body{
            .card-title{
                color: #FFF;
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.28px;
            }
            .card-text{
                color: #FFF;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            }
            .card-text-service{
                color: #FFF;
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            }
        }
        }
        }
    }
  }
   
  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    /* Styles for X-Large devices */
  }