.Life_4_container {
  background: #000614;
  width: 100vw;
  // height: 100vh;
  height: 815px;
  box-sizing: border-box;


  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 70px;
    // padding: 70px 70px 20px 70px;
    padding: 70px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 103%;
    /* identical to box height, or 54px */

    text-align: center;

    /* White */

    color: #ffffff;

    .color_text {
      background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .minds_container {
    // background: skyblue;
    // width: 95vw;
    // height: 60vh;
    // height: 80vh;
    // margin-left: 5vw;
    width: 1440px;
    height: 80%;
    padding-left: 70px;
    margin: auto;

    .card1 {
        // background: yellow;
      // width: 21vw;
      // // height: 60vh;
      // height: 80vh;
      width: 302px;
      height: 610px;

      .top {
        // background-color: gray;
        // height: 50%;
        // height: 38%;
        height: 240px;

        .box {
          // background: #95f48d;
          // background: linear-gradient(180deg, #488cfa 0%, rgba(72, 250, 90, 0) 100%);
          background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
          border-radius: 38.3333px 38.3333px 0px 0px;
          width: 100%;
          // margin-top: -47%;
          // height: 80%;
          // margin-top: -60%;
          // height: 70%;
          // margin-top: -21vh;
          // height: 21vh;
          margin-top: -200px;
          height: 200px;
        }

        img{
          // width: 20vw;
          // height: 30vh;
          width: 288px;
          height: 237px;
        }
        .ujwal {
          width: 265px;
          height: 237px;
          margin-left: 24px;
        }
        // .sambhav{
        //   width: 30vw;
        //   height: 30vh;
        //   padding-top: 20vh;
        // }
        // .avinash{
        //   width: 20vw;
        //   height: 30vh;
        // }
        .sambhav{
          // width: 288px;
          width: 285px;
         margin-left: 10px;
          height: 237px;
        }
        .avinash{
          // width: 18vw;
          // height: 30vh;
          // margin-left: 1vw;
          // width: 259px;
          height: 237px;
          width: 247px;
          margin-left: 15px;
        }
        .chinmaya{
          // width: 19vw;
          // height: 30vh;
          // margin-left: 1vw;
          width: 273px;
          height: 237px;
          margin-left: 10px;
        }
      }
      .bottom1 {
        // background-color: #8d3bbf;
        // height: 25%;
        height: 20%;
        .name {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 103%;
          /* or 41px */

          // text-align: center;

          color: #ffffff;
          // padding: 5px;
          padding-top: 5px;
        }
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 103%;
          /* identical to box height, or 29px */

          // text-align: center;

          color: #ffffff;

          opacity: 0.71;
          // padding: 5px;
        }
        .img_box {
          margin-top: 5px;
          margin-bottom: 0;
          display: flex;
          .circle {
            border: 2px solid white;
            border-radius: 50%;
            // width: 3vw;
            // height: 5vh;
            width: 43.2px;
            height: 39.45px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            // margin-left: 5px;
            margin-bottom: 4px;
          }
        }
      }
      .readMore_box_mobile{
        display: none;
      }
      
      
      .bottom2 {
        background: #333843;
        border-radius: 12px;

        height: 25%;

        .text2 {
          padding: 8px;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 160%;
          /* or 32px */

          letter-spacing: 0.03em;

          color: #ffffff;

          .color_text {
            background: linear-gradient(
              94.77deg,
              #ff008a 6.23%,
              #8d3bbf 95.59%
            );
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .bottom3 {
        // position: relative;

        background: #333843;
        border-radius: 12px;
        width: 80vw;
        height: 25%;
        position: absolute;
        left: 5vw;

        .text2 {
          padding: 8px;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 160%;
          /* or 32px */

          letter-spacing: 0.03em;

          color: #ffffff;

          .color_text {
            background: linear-gradient(
              94.77deg,
              #ff008a 6.23%,
              #8d3bbf 95.59%
            );
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  //readMore

  .text2 {
    margin-top: 10px; //**//
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
  
    /* or 32px */
  
    letter-spacing: 0.03em;
  
    color: #ffffff;
  
    background: #333843;
    border-radius: 12px;
    // z-index: 2;
  
    // height: 120px; //**//
  }
  
  .color_text {
    background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
  .text3 {
    margin-top: 10px;
    // position: absolute;
    margin-left: 0;
    // width: 50vw;
    width: 720px;
    // height: 50vh;
    // left: 0;
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
  
    /* or 32px */
  
    letter-spacing: 0.03em;
  
    color: #ffffff;
  
    background: #333843;
    border-radius: 12px;
    // height: 190px;
  }
}





/* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .Life_4_container {
    background: #000614;
    width: 100vw;
    // height: 100vh;
    height: 800px;
    box-sizing: border-box;
  
    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 70px;
      padding: 90px 20px 70px 20px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 103%;
      /* identical to box height, or 54px */
  
      text-align: center;
  
      /* White */
  
      color: #ffffff;
  
      .color_text {
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .minds_container {
      // background: skyblue;
      width: 95vw;
      // height: 60vh;
      // height: 80vh;
      height: 80%;
      padding-left: 20px;
      // margin-left: 3vw;
      // margin: auto;

  
      .card1 {
        //   background: yellow;
        // width: 60vw;
        // height: 60vh;
        // height: 105vh;
        // height: 70vh;
        // margin: auto;
        width: 234px;
        height: 600px;
  
        .top {
          // background-color: gray;
          // height: 50%;
          // height: 30%;
          // height: 45%;
          height: 266px;
  
          .box {
            // background: #95f48d;
            // background: linear-gradient(180deg, #488cfa 0%, rgba(72, 250, 90, 0) 100%);
            background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
            border-radius: 38.3333px 38.3333px 0px 0px;
            width: 100%;
            // margin-top: -47%;
            // height: 80%;
            // margin-top: -60%;
            // height: 70%;
            // margin-top: -21vh;
            // height: 21vh;
            margin-top: -177px;
            height: 177px;
          }
  
          img {
            // width: 60vw;
            // height: 30vh;
            // margin: auto;
            // display: flex;
            width: 234px;
            height: 253px;

          }
          .ujwal{
            // width: 234px;
            // height: 253px;
            // width: 234px;
            // height: 253px;
            width: 242px;
            height: 217px;
            margin-left: 2px;
            margin-top: 26px;
          }
          .sambhav{
            // width: 61vw;
            // height: 30vh;
            // margin: auto;
            // display: flex;
            // width: 238px;
            // height: 253px;
            width: 258px;
            height: 225px;
            margin-left: -10px;
            margin-top: 20px;
          }
          .avinash{
            // width: 60vw;
            // height: 30vh;
            // margin: auto;
            // display: flex;
            // width: 234px;
            // height: 253px;
            width: 220px;
            height: 230px;
            margin-left: -4px;
            margin-top: 15px;
          }
          .chinmaya{
            // width: 56vw;
            // height: 30vh;
            // margin-left: 2vw;
            // width: 218.5px;
            // height: 253px;
            width: 230px;
            height: 226px;
            margin-left: 2px;
            margin-top: 18px;
          }
        }
        .bottom1 {
          // background-color: #8d3bbf;
          // height: 25%;
          // height: 14%;
          height: 20%;
          .name {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 103%;
            /* or 41px */
  
            // text-align: center;
  
            color: #ffffff;
            // padding: 5px;
            padding-top: 5px;
          }
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 103%;
            /* identical to box height, or 29px */
  
            // text-align: center;
  
            color: #ffffff;
  
            opacity: 0.71;
            // padding: 5px;
          }
          .img_box {
            margin-top: 5px;
            margin-bottom: 0;
            display: flex;
            .circle {
              border: 2px solid white;
              border-radius: 50%;
              // width: 7vw;
              // height: 3vh;
              width: 27.3px;
              height: 25.31px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            img {
              // margin-left: 2px;
              // margin-bottom: 2px;
              display: flex;
              margin: auto;
              width: 75%;
            }
          }
        }

        .readMore_box{
          display: none;
        }
        .readMore_box_mobile{
          display: block;
          background: #333843;
          border-radius: 12px;
  
          // height: 25%;
          .trim_text{
            padding: 8px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 160%;
            /* or 32px */
  
            letter-spacing: 0.03em;
  
            color: #ffffff;
            .color_text {
              background: linear-gradient(
                94.77deg,
                #ff008a 6.23%,
                #8d3bbf 95.59%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          // .modal-backdrop{
          //   z-index: -1050;
          // }
        }

        .bottom2 {
          background: #333843;
          border-radius: 12px;
  
          height: 25%;
  
          .text2 {
            padding: 8px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 160%;
            /* or 32px */
  
            letter-spacing: 0.03em;
  
            color: #ffffff;
  
            .color_text {
              background: linear-gradient(
                94.77deg,
                #ff008a 6.23%,
                #8d3bbf 95.59%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
  
        .bottom3 {
          // position: relative;
  
          background: #333843;
          border-radius: 12px;
          width: 80vw;
          height: 25%;
          position: absolute;
          left: 5vw;
  
          .text2 {
            padding: 8px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 160%;
            /* or 32px */
  
            letter-spacing: 0.03em;
  
            color: #ffffff;
  
            .color_text {
              background: linear-gradient(
                94.77deg,
                #ff008a 6.23%,
                #8d3bbf 95.59%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }

    .modal-content{
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 160%;
    
      /* or 32px */
    
      letter-spacing: 0.03em;
    
      color: #ffffff;
    
      background: #333843;
      border-radius: 12px;
    }

    // readMore 
    .text2 {
      padding: 8px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 160%;
    
      /* or 32px */
    
      letter-spacing: 0.03em;
    
      color: #ffffff;
    
      background: #333843;
      border-radius: 12px;
      // z-index: 2;
    
      height: 180px;
      // height: 12rem;
    }
    
    .color_text {
      background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }
    .text3 {
      // position: absolute;
      margin-left: 0;
      width: 60.5vw;
      // height: 50vh;
      // left: 0;
      padding: 8px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 160%;
    
      /* or 32px */
    
      letter-spacing: 0.03em;
    
      color: #ffffff;
    
      background: #333843;
      border-radius: 12px;
      // z-index: 2000;
      margin-top: -30vh;
      // height: 190px;
      position: relative;
    }
  }

  
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .Life_4_container {
    height: 885px;
    .minds_container {
      width: 1024px;
      // background: yellow;
      .card1{
        height: 690px;
      }
    }
    .text3 {
      margin-top: 10px;
      // position: absolute;
      margin-left: 0;
      // width: 50vw;
      width: 572px;
      // height: 50vh;
      // left: 0;
      padding: 8px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 160%;
    
      /* or 32px */
    
      letter-spacing: 0.03em;
    
      color: #ffffff;
    
      background: #333843;
      border-radius: 12px;
      // height: 190px;
    }
  }
}