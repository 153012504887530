:root{
    --black: rgba(0, 0, 0, 1);
    --blue-charcoal:rgba(0,6,20,1);

    --alto:rgba(217,219,225,1);
    --merculary:rgba(230, 230, 230,1);
    --white-5:rgba(255,255,255,0.102);
    --white-22:rgba(255,255,255,0.4);
    --white-42:rgba(255,255,255,0.502);
    --white-6:rgba(255,255,255,0.6);
    --white-32:rgba(255,255,255,0.698);
    --white322:rgba(255,255,255,1);

    --font-size-xsx: 16px;
    --font-size-xs: 18px;
    --font-size-s: 20px;
    --font-size-m: 24px;
    --font-size-l: 28px;
    --font-size-xl: 36px;
    --font-size-xxl: 48px;

    --font-family-inter: "Inter";
    --font-family-inter-bold: "Inter-Bold";
    --font-family-inter-medium: "Inter-Medium";
    --font-family-inter-regular: "Inter-Regular";
    --font-family-saira: "Saira";
    --font-family-saira-extrabold: "Saira-ExtraBold";
};

/* classes */
.inter-bold-black-52px {
    color: var(--black);
    font-family: var(--font-family-inter);
    font-size: 52px;
    font-weight: 700;
    font-style: normal;
}
.inter-bold-white-52px{
    color: var(--white322);
    font-family: var(--font-family-inter);
    font-size: 52px;
    font-weight: 700;
    font-style: normal;
}
.saira-extra-bold-white-38px{
    color: var(--white322);
    font-family: var(--font-family-saira);
    font-size: 38px;
    font-weight: 800;
    font-style: normal;
}
.inter-normal-white-16px{
    color: var(--white322);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xsx);
    font-weight: 400;
    font-style: normal;
}
.inter-bold-white-16px{
    color: var(--white322);
    font-family: var(--font-family-inter-bold);
    font-size: var(--font-size-xsx);
    font-weight: 700;
    font-style: normal;
}