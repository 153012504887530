.sales_container{
    background: linear-gradient(90deg, #7A1C72 0%, hsl(305, 56%, 22%) 100%);

    .pb_60{
        padding-bottom: 60px;
    }

    .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 70px;
        padding: 88px 70px 100px 70px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: normal;
        /* identical to box height, or 54px */
    
        text-align: center;
    
        /* White */
    
        color: #ffffff;
  
        .rect_line_left{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
  margin-right: 9px;
        }
        .rect_line_right{
          width: 70px;
  height: 6px;
  background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
  margin-left: 9px;
        }
    
   
        .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .container{
        width: 980px;

        .center_align{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ver_line{
            width: 1px;
// height: 431px;
height: 100%;
background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF02EB 50%, rgba(122, 28, 114, 0.00) 100%);
        }

        .chat_text{
            color: #FFFFFF;
font-family: Inter;
font-size: 34px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.34px;
margin-bottom: 60px;
        }
        .card{
            border-radius: 8px;
background: #7A1C72;
        }
        .mb_8{
            margin-bottom: 8px;
        }
        .card-body{
            display: flex;
            align-items: center;

            .contact_details{
                margin-left: 17px;

                .contact_head{
                    color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.16px;
                }
                .contact_name{
                    color: #FFC3FA;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.2px;
                }
            }
        }

        .details_text{
            color: #D265C9;
font-family: Inter;
font-size: 34px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.34px;
margin-bottom: 60px;
        }
        .submit_btn{
            display: flex;
height: 56px;
padding: 8px 22px;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
align-self: stretch;
border-radius: var(--borderRadius, 4px);
background: linear-gradient(0deg, #7A1C72 0%, #7A1C72 100%), #000;

/* elevation/2 */
box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
border: none;
width: 100%;
color: #FFF;

font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 173.333% */
letter-spacing: 0.46px;
text-transform: uppercase;
        }
        .success_msg{
            margin-top: 10px;
            display: flex;
height: 55px;
padding: 8px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: var(--borderRadius, 4px);
background: #26B27C;

color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
        }
      }

      .connect_section_title{
        font-size: 20px;
    color: #D265C9;
    margin-top: 25px;
    margin-bottom: 8px;
      }
      .contact_head_address{
        color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.16px;
margin-bottom: 10px;
    }
    .contact_name_address{
        color: #FFC3FA;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.2px;
    }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .sales_container{
        .heading {
            display: flex;
            align-items: center;
            justify-content: center;
            // padding: 70px;
            padding: 40px 30px 40px 30px;
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: normal;
            /* identical to box height, or 54px */
        
            text-align: center;
        
            /* White */
        
            color: #ffffff;
      
            .rect_line_left{
                width: 39.873px;
                height: 3.418px;
      background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
      margin-right: 4px;
            }
            .rect_line_right{
                width: 39.873px;
                height: 3.418px;
      background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
      margin-left: 4px;
            }
        
            .color_text {
                background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

        .container{
            width: 90%;
            .chat_text {
                color: #FFFFFF;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.34px;
                margin-bottom: 25px;
                text-align: center;
            }
            .details_text {
                color: #D265C9;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.34px;
                margin-bottom: 25px;
                margin-top: 30px;
                text-align: center;
            }
            .card-body{
            .contact_details{
                .contact_name{
                    color: #FFC3FA;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.2px;
                }
            }}
        }
        .connect_section_title{
            font-size: 18px;
        color: #D265C9;
        margin-top: 25px;
        margin-bottom: 8px;
          }

    }
  }
   
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    /* Styles for Small devices */
    .sales_container{
        .container{
            width: 90%;
            .chat_text {
                color: #FFFFFF;
                font-family: Inter;
                font-size: 34px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.34px;
                margin-bottom: 60px;
                text-align: center;
            }
            .details_text {
                color: #D265C9;
                font-family: Inter;
                font-size: 34px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.34px;
                margin-bottom: 60px;
                margin-top: 40px;
                text-align: center;
            }
        }
    }
  }
   
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    /* Styles for Medium devices */
    .sales_container .container{
        width: 90%;
        .chat_text {
            color: #FFFFFF;
            font-family: Inter;
            font-size: 34px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.34px;
            margin-bottom: 60px;
            text-align: center;
        }
        .details_text {
            color: #D265C9;
            font-family: Inter;
            font-size: 34px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.34px;
            margin-bottom: 60px;
            margin-top: 40px;
            text-align: center;
        }
    } 
  }
   
//   /* Large devices (desktops, 992px and up) */
//   @media (min-width: 992px) and (max-width: 1199.98px) {
//     /* Styles for Large devices */
//   }
   
//   /* X-Large devices (large desktops, 1200px and up) */
//   @media (min-width: 1200px) and (max-width: 1399.98px) {
//     /* Styles for X-Large devices */
//   }
   