.Benefits_container {
    background: #000614;
    width: 100vw;
    // height: 100vh;
    height: 715px;
    box-sizing: border-box;
  
    // .ellipse15{
    //   width: 60vw;
    //   height: 500px;
    //   // margin-left: 20vw;
    //   margin-right: 40vw;
    // }
  
    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 70px;
      padding: 70px 70px 70px 70px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 103%;
      /* identical to box height, or 54px */
  
      text-align: center;
  
      /* White */
  
      color: #ffffff;

      .rect_line_left{
        width: 70px;
height: 6px;
background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
margin-right: 9px;
      }
      .rect_line_right{
        width: 70px;
height: 6px;
background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
margin-left: 9px;
      }
  
      .color_text {
        background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    }
  
    .whats_container {
      // background-color: rgb(37, 9, 48);
      // width: 100vw;
      width: 1440px;
      height: 70%;
      display: flex;
      margin: auto;
  
      .first {
        // background: orange;
        width: 30%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
  
        .inside_first {
          position: absolute;
          top: 25%;
          left: 20%;
  
          .first_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 115%;
            /* or 67px */
  
            /* White */
  
            color: #ffffff;
          }
  
          .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        }
      }
  
      .second {
        // background: gray;
        width: 45%;
        height: 100%;
        // position: relative;
        // top: 0;
        // left: 0;
        display: flex;
  
        .ellipse15 {
          // height: 70vh;
          // margin-left: -20vw;
          // height: 544px;
  
        //   height: 505px;
        //   margin-left: -282px;

        height: 400px;
    margin-left: -100px;
  
          // height: 64vh;
          // margin-left: -19.5vw;
  
          // margin-right: 20px;
          // position: relative;
          // top: 0;
          // left: 0;
          // position: absolute;
          // height: 480px;
          // margin-left: -280px;
          // margin-top: 0;
          // margin-bottom: 0;
        }
  
        .circle {
          background: white;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          cursor: pointer;
        }
  
        .circle_active {
            background: #26B27C;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          cursor: pointer;
        }
  
        .circle_text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 103%;
          /* identical to box height, or 29px */
  
        //   color: rgba(255, 255, 255, 0.5);
        color: #FFF;
          margin-left: 40px;
          cursor: pointer;
        }
  
        .circle_text_active {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          // line-height: 103%;
          line-height: 110%;
          /* identical to box height, or 39px */
  
          letter-spacing: -0.02em;
          background: #26B27C;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 25px;
          cursor: pointer;
        }
  
        // .ellipse20 {
        //   position: absolute;
        //   top: 2%;
        //   left: 4%;
        // }
        // .ellipse16 {
        //   position: absolute;
  
        //   top: 15%;
        //   left: 19%;
        // }
        // .ellipse23 {
        //   position: absolute;
        //   top: 28%;
        //   left: 26%;
        // }
        // .ellipse17 {
        //   position: absolute;
        //   top: 41%;
        //   left: 29%;
        // }
        // .ellipse22 {
        //   position: absolute;
        //   top: 54%;
        //   left: 29.5%;
        // }
        // .ellipse18 {
        //   position: absolute;
        //   top: 68%;
        //   left: 26.5%;
        // }
        // .ellipse19 {
        //   position: absolute;
        //   top: 81%;
        //   left: 20%;
        // }
        // .ellipse21 {
        //   position: absolute;
        //   top: 94%;
        //   left: 4%;
        // }
  
        .inside_second1 {
          // min-width: 35vw;
          // margin-bottom: 6vh;
          display: flex;
          min-width: 360px;
        //   margin-bottom: 43px;
          margin-bottom: 78px;
          // margin-left: -12vw;
        //   margin-left: -152px;
        margin-left: -130px 
        }
  
        .inside_second2 {
          // min-width: 35vw;
          min-width: 360px;
          display: flex;
          // margin-bottom: 6vh;
        //   margin-bottom: 46px;
        //   // margin-left: -5vw;
        //   margin-left: -70px;
        margin-bottom: 105px;
    margin-left: -28px;
        }
  
        .inside_second3 {
          // min-width: 35vw;
          // margin-bottom: 6vh;
          min-width: 360px;
        //   margin-bottom: 43px;
          margin-bottom: 118px;
          display: flex;
          // margin-left: -2vw;
        //   margin-left: -32px;
          margin-left: -15px;
        }
  
        .inside_second4 {
          // min-width: 35vw;
          min-width: 360px;
          display: flex;
          // margin-bottom: 6vh;
          margin-bottom: 43px;
          // margin-left: -1vw;
          margin-left: -112px;
        }
  
        .inside_second5 {
          // min-width: 35vw;
          min-width: 360px;
          display: flex;
          // margin-bottom: 6vh;
          margin-bottom: 43px;
          // margin-left: -1vw;
          margin-left: -12px;
        }
  
        .inside_second6 {
          // min-width: 35vw;
          min-width: 360px;
          display: flex;
          // margin-bottom: 6vh;
          margin-bottom: 43px;
          // margin-left: -2vw;
          margin-left: -26px;
        }
  
        .inside_second7 {
          // min-width: 35vw;
          min-width: 360px;
          display: flex;
          // margin-bottom: 6vh;
          margin-bottom: 53px;
          // margin-left: -4.5vw;
          margin-left: -60px;
  
        }
  
        .inside_second8 {
          // min-width: 35vw;
          min-width: 360px;
          display: flex;
          // margin-bottom: 6vh;
          margin-bottom: 43px;
          // margin-left: -10vw;
          margin-left: -143px;
        }
      }
  
      .third {
        // background: green;
        width: 25%;
        height: 100%;
        position: relative;
        // margin-left: -3vw;
        margin-left: -40px;
        // top: 0;
        // left: 0;
        .doodleUp {
          // margin-left: 110px;
          // // margin-left: 40%;
          width: 200px;
          margin-left: 150px
        }
  
        .doodleDown {
          // margin-left: -75px;
          // margin-top: 120px;
          // // margin-left: -18%;
          // // margin-top: 50%;
          // // margin-top: 40%;
          width: 200px;
          margin-left: -100px;
          margin-top: 150px;
        }
  
        .inside_third {
          position: absolute;
          // top: 20%;
          // left: 15%;
  
          //  background: yellow; 
          // width: 25vw;
          // height: 35vh;
          width: 277.25px;
          height: 276.15px;
          // margin-top: -10vh;
        //   margin-top: -79px;
  
          .partImg {
            width: 240px;
            height: 300px;
          }
  
          .partImg1 {
            // width: 240px;
            // height: 300px;
            // width: 35vw;
            // height: 44vh;
            // width: 504px;
            // height: 347px;

            // width: 302px;
            // height: 273px;

            width: 362.4px;
            height: 327.6px;

            // margin-top: 24px;
            // margin-left: 0px;
            // margin-top: -6vh;
            // margin-top: -47px;
            // margin-left: -5vw;
            // margin-left: -55px;
          }
  
          .partImg2 {
            // width: 330px;
            // height: 300px;
            // width: 30vw;
            // height: 44vh;

            // width: 432px;
            // height: 324px;

            width: 305.6px;
            height: 259.2px;
            margin-top: 55px;
            margin-left: 30px;
            // margin-top: -2vh;
            // margin-left: -3vw;
            // margin-top: -15px;
            // margin-left: -34px;
          }
  
          .partImg3 {
            // width: 240px;
            // height: 300px;
  
            // width: 23vw;
            // height: 36vh;
            width: 331px;
            height: 265px;

            margin-top: 30px;
            margin-left: 40px;
            // margin-top: 2vh;
            // margin-left: -2vw;
            // margin-top: 15px;
            // margin-left: -22px;
          }
  
          .partImg4 {
            // width: 300px;
            // height: 320px;
  
            // width: 30vw;
            // height: 40vh;
            // margin-top: -3vh;
            // margin-left: -2vw;
            // width: 432px;
            // height: 295px;

            width: 325.6px;
            height: 236px;
            margin-top: 30px;
            margin-left: 22px;
          }
  
          .partImg5 {
            // width: 280px;
            // height: 300px;
            // width: 29vw;
            // height: 36vh;
            // margin-top: -3vh;
            // margin-left: 1vw;
            width: 418px;
            height: 265px;
            margin-top: -23px;
            margin-left: 10px;
          }
  
          .partImg6 {
            // width: 240px;
            // height: 300px;
  
            // width: 21vw;
            // height: 27vh;
            // margin-top: 4vh;
            // margin-left: 1vw;
            width: 302px;
            height: 199px;
            margin-top: 30px;
            margin-left: 12px;
          }
  
          .partImg7 {
            // width: 240px;
            // height: 300px;
            // width: 21vw;
            // height: 33vh;
            // margin-top: 1vh;
            // margin-left: 1vw;
            width: 302px;
            height: 243px;
            margin-top: 8px;
            margin-left: 11px;
          }
  
          .partImg8 {
            // width: 240px;
            // height: 300px;
  
            // width: 21vw;
            // height: 37vh;
            // margin-top: -3vh;
            // margin-left: 0vw;
            width: 302px;
            height: 273px;
            margin-top: 24px;
            margin-left: 0px;
          }
        }
      }
    }
  
    .whats_container_mobile {
      display: none;
    }
  }
  
  
//   /* Media Query for Mobile Devices */
//   @media only screen and (min-width: 320px) and (max-width: 767px) {
//     .Benefits_container {
//       background: #000614;
//       width: 100vw;
//       height: 100vh;
//       box-sizing: border-box;
  
//       // .ellipse15{
//       //   width: 60vw;
//       //   height: 500px;
//       //   // margin-left: 20vw;
//       //   margin-right: 40vw;
//       // }
  
//       .heading {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         // padding: 70px;
//         padding: 70px 15px 40px 15px;
//         font-family: Inter;
//         font-style: normal;
//         font-weight: bold;
//         font-size: 34px;
//         line-height: 103%;
//         /* identical to box height, or 54px */
  
//         text-align: center;
  
//         /* White */
  
//         color: #ffffff;
//         text-decoration: underline;
//         text-underline-offset: 8px;
  
//         .color_text {
//           background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;
//         }
//       }
  
//       .whats_container {
//         display: none;
//       }
  
//       .whats_container_mobile {
//         display: block;
//         width: 100vw;
//         height: 70vh;
//         // background-color: #4a76de;
  
//         .card1 {
//           // background: yellow;
//           width: 100vw;
//           height: 70vh;
//           margin-bottom: 4vh;
//           // margin: auto;
  
//           .circle_text_active {
//             font-family: Inter;
//             font-style: normal;
//             font-weight: 600;
//             font-size: 30px;
//             line-height: 115%;
//             /* identical to box height, or 39px */
    
//             letter-spacing: -0.02em;
//             background: #26B27C;
//             background-clip: text;
//             -webkit-text-fill-color: transparent;
//             // margin-left: 46px;
//             // cursor: pointer;
//             display: flex;
//             // margin: auto;
//             justify-content: center;
//             // margin-top:2vh ;
//             padding-top: 4vh;
//             text-align: center;
//           }
  
//           .partImg1 {
//             // width: 240px;
//             // height: 300px;
//             width: 100vw;
//             height: 42vh;
//             margin-top: -5vh;
//             // margin-left: -5vw;
//           }
  
//           .partImg2 {
//             width: 70vw;
//       height: 44vh;
//       margin-top: -4vh;
//       margin-left: 12vw;
//           }
  
//           .partImg3 {
//             width: 66vw;
//       height: 40vh;
//       margin-top: 4vh;
//       margin-left: 8vw;
//           }
  
//           .partImg4 {
//             width: 90vw;
//       height: 45vh;
//       margin-top: -2vh;
//       margin-left: 5vw;
//           }
  
//           .partImg5 {
//             width: 85vw;
//             height: 36vh;
//             margin-top: 3vh;
//             margin-left: 15vw;
//           }
  
//           .partImg6 {
//             width: 60vw;
//       height: 27vh;
//       margin-top: 6vh;
//       margin-left: 25vw;
//           }
  
//           .partImg7 {
//             width: 60vw;
//       height: 33vh;
//       margin-top: 7vh;
//       margin-left: 26vw;
//           }
  
//           .partImg8 {
//             width: 70vw;
//       height: 35vh;
//       margin-top: 4vh;
//       margin-left: 15vw;
//           }
  
//           .first_text {
//             font-family: Inter;
//             font-style: normal;
//             font-weight: bold;
//             font-size: 26px;
//             line-height: 115%;
//             /* or 67px */
  
//             /* White */
  
//             color: #ffffff;
//             // margin-left: 11vw;
//             // margin-top: 6vh;
//             margin: 6vh 2vh;
//             text-align: center;
  
//             .color_text {
//               background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
//               background-clip: text;
//               -webkit-text-fill-color: transparent;
//             }
//           }
  
          
//         }
//       }
  
//       .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
//         background: #ffffff;
//         // border: 1px solid #ffffff;
//       }
//       .react-multiple-carousel__arrow::before{
//         color: #000614;
//       }
//       .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
//         background: #ffffff;
//         // border: 1px solid #ffffff;
//       }
//       // .react-multiple-carousel__arrow{
//       //   background: #ffffff;
//       // }
//       // // .react-multiple-carousel__arrow::before{
//       // //   color: #000614;
//       // // }
//     }
//   }
  

  

  
//   @media (min-width: 1024px) and (max-width: 1439px) {
//     .Benefits_container {
//       .whats_container {
//         width: 1024px;
//         // background: rgb(81, 81, 68);
//         .first {
//           // background: orange;
//           width: 30%;
//           height: 100%;
//           position: relative;
//           top: 0;
//           left: 0;
    
//           .inside_first {
//             position: absolute;
//             top: 33%;
//             left: 20%;
    
//             .first_text {
//               font-family: Inter;
//               font-style: normal;
//               font-weight: bold;
//               font-size: 28px;
//               line-height: 115%;
//               /* or 67px */
    
//               /* White */
    
//               color: #ffffff;
//             }
    
//             .color_text {
//               background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
//               background-clip: text;
//               -webkit-text-fill-color: transparent;
//             }
//           }
//         }
//         .second {
//             .inside_second3 {
//                 min-width: 360px;
//                 margin-bottom: 95px;
//                 display: flex;
//                 margin-left: -15px;
//             }
//         }
//         .third {
//           // background: green;
//           width: 25%;
//           height: 100%;
//           position: relative;
//           // margin-left: -3vw;
//           margin-left: -40px;
//           // top: 0;
//           // left: 0;
//           .doodleUp {
//             // margin-left: 110px;
//             // // margin-left: 40%;
//             width: 200px;
//             margin-left: 150px
//           }
    
//           .doodleDown {
//             // margin-left: -75px;
//             // margin-top: 120px;
//             // // margin-left: -18%;
//             // // margin-top: 50%;
//             // // margin-top: 40%;
//             width: 200px;
//             margin-left: -80px;
//             margin-top: 150px;
//           }
    
//           .inside_third {
//             position: absolute;
//             // top: 20%;
//             // left: 15%;
    
//             //  background: yellow; 
//             // width: 25vw;
//             // height: 35vh;
//             width: 277.25px;
//             height: 276.15px;
//             // margin-top: -10vh;
//             // margin-top: -79px;
    
//             .partImg {
//               width: 240px;
//               height: 300px;
//             }
    
//             .partImg1 {
//               // width: 240px;
//               // height: 300px;
//               // width: 35vw;
//               // height: 44vh;
//               width: 504px;
//               height: 347px;
//               // margin-top: -6vh;
//             //   margin-top: -47px;
//             //   // margin-left: -5vw;
//             //   margin-left: -55px;
//             }
    
//             .partImg2 {
//               // width: 330px;
//               // height: 300px;
//               // width: 30vw;
//               // height: 44vh;
//               width: 388.8px;
//               height: 291.6px;
//               // margin-top: -2vh;
//               // margin-left: -3vw;
//             //   margin-top: -15px;
//             //   margin-left: -24px;
//             }
    
//             .partImg3 {
//               // width: 240px;
//               // height: 300px;
    
//               // width: 23vw;
//               // height: 36vh;
//               width: 331px;
//               height: 265px;
//               // margin-top: 2vh;
//             //   // margin-left: -2vw;
//             //   margin-top: 25px;
//             //   margin-left: -6px;
//             }
    
//             .partImg4 {
//               // width: 300px;
//               // height: 320px;
    
//               // width: 30vw;
//               // height: 40vh;
//               // margin-top: -3vh;
//               // margin-left: -2vw;
//               width: 432px;
//               height: 295px;
//             //   margin-top: -24px;
//             //   margin-left: -22px;
//             }
    
//             .partImg5 {
//               // width: 280px;
//               // height: 300px;
//               // width: 29vw;
//               // height: 36vh;
//               // margin-top: -3vh;
//               // margin-left: 1vw;
//               width: 376.2px;
//               height: 238.5px;
//               margin-top: -13px;
//               margin-left: 55px;
//             }
    
//             .partImg6 {
//               // width: 240px;
//               // height: 300px;
    
//               // width: 21vw;
//               // height: 27vh;
//               // margin-top: 4vh;
//               // margin-left: 1vw;
//               width: 302px;
//               height: 199px;
//               margin-top: 30px;
//               margin-left: 24px;
//             }
    
//             .partImg7 {
//               // width: 240px;
//               // height: 300px;
//               // width: 21vw;
//               // height: 33vh;
//               // margin-top: 1vh;
//               // margin-left: 1vw;
//               width: 302px;
//               height: 243px;
//               margin-top: 8px;
//               margin-left: 11px;
//             }
    
//             .partImg8 {
//               // width: 240px;
//               // height: 300px;
    
//               // width: 21vw;
//               // height: 37vh;
//               // margin-top: -3vh;
//               // margin-left: 0vw;
//               width: 302px;
//               height: 273px;
//               margin-top: -24px;
//               margin-left: 0px;
//             }
//           }
//         }
//       }
//     }
//   }


  /* Media Query for Mobile Devices */
@media (max-width: 991.98px) {
    .Benefits_container {
      background: #000614;
      width: 100vw;
      height: 100vh;
    // height: 530px;
      box-sizing: border-box;
  
      // .ellipse15{
      //   width: 60vw;
      //   height: 500px;
      //   // margin-left: 20vw;
      //   margin-right: 40vw;
      // }
  
      .heading {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 70px;
        padding: 70px 15px 40px 15px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 103%;
        /* identical to box height, or 54px */
  
        text-align: center;
  
        /* White */
  
        // color: #ffffff;
        // text-decoration: underline;
        // text-underline-offset: 8px;
  
        .color_text {
            background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
  
      .whats_container {
        display: none;
      }
  
      .whats_container_mobile {
        display: block;
        width: 100vw;
        height: 70vh;
        // background-color: #4a76de;

        .center_align_ben{
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 60%;
            // height: 60%;
        }
  
        .card1 {
          // background: yellow;
          width: 100vw;
          height: 70vh;
        //   margin-bottom: 4vh;
          margin-bottom: 40px;
          // margin: auto;
  
          .circle_text_active {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 115%;
            /* identical to box height, or 39px */
    
            letter-spacing: -0.02em;
            // background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            background: #26B27C;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            // margin-left: 46px;
            // cursor: pointer;
            display: flex;
            // margin: auto;
            justify-content: center;
            // margin-top:2vh ;
            padding-top: 4vh;
            text-align: center;
          }
  
          .partImg1 {
            // width: 240px;
            // height: 300px;
            // width: 100vw;
            // height: 42vh;
            // margin-top: -5vh;
            // margin-left: -5vw;

            width: 362.4px;
            height: 327.6px;
          }
  
          .partImg2 {
    //         width: 70vw;
    //   height: 44vh;
    //   margin-top: -4vh;
    //   margin-left: 12vw;

    width: 305.6px;
    height: 259.2px;
          }
  
          .partImg3 {
    //         width: 66vw;
    //   height: 40vh;
    //   margin-top: 4vh;
    //   margin-left: 8vw;
    width: 331px;
    height: 265px;
          }
  
          .partImg4 {
    //         width: 90vw;
    //   height: 45vh;
    //   margin-top: -2vh;
    //   margin-left: 5vw;

    width: 325.6px;
    height: 236px;
          }
  
          .partImg5 {
            width: 85vw;
            height: 36vh;
            margin-top: 3vh;
            margin-left: 15vw;
          }
  
          .partImg6 {
            width: 60vw;
      height: 27vh;
      margin-top: 6vh;
      margin-left: 25vw;
          }
  
          .partImg7 {
            width: 60vw;
      height: 33vh;
      margin-top: 7vh;
      margin-left: 26vw;
          }
  
          .partImg8 {
            width: 70vw;
      height: 35vh;
      margin-top: 4vh;
      margin-left: 15vw;
          }
  
          .first_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 115%;
            /* or 67px */
  
            /* White */
  
            color: #ffffff;
            // margin-left: 11vw;
            // margin-top: 6vh;
            margin: 6vh 2vh;
            text-align: center;
  
            .color_text {
                background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
  
          
        }
      }
  
      .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
        background: #ffffff;
        // border: 1px solid #ffffff;
      }
      .react-multiple-carousel__arrow::before{
        color: #000614;
      }
      .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
        background: #ffffff;
        // border: 1px solid #ffffff;
      }
      // .react-multiple-carousel__arrow{
      //   background: #ffffff;
      // }
      // // .react-multiple-carousel__arrow::before{
      // //   color: #000614;
      // // }
    }
  }

//   @media (max-width: 575.98px) {
//     .Benefits_container {
//     .heading {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         // padding: 70px;
//         padding: 40px 30px 40px 30px;
//         font-family: Inter;
//         font-style: normal;
//         font-weight: bold;
//         font-size: 24px;
//         line-height: 103%;
//         /* identical to box height, or 54px */
    
//         text-align: center;
    
//         /* White */
    
//         color: #ffffff;
  
//         .rect_line_left{
//             width: 39.873px;
//             height: 3.418px;
//   background: linear-gradient(90deg, rgba(122, 28, 114, 0.00) 0%, #FF01EA 98%);
//   margin-right: 4px;
//         }
//         .rect_line_right{
//             width: 39.873px;
//             height: 3.418px;
//   background: linear-gradient(90deg, #FF01EA 0%, rgba(122, 28, 114, 0.00) 98%);
//   margin-right: 4px;
//         }
    
//         .color_text {
//             background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;
//         }
//       }
//       .whats_container_mobile .card1 {
//      .circle_text_active {
//         font-family: Inter;
//         font-style: normal;
//         font-weight: 600;
//         font-size: 20px;
//         line-height: 115%;
//         letter-spacing: -0.02em;
//         background: #26B27C;
//         -webkit-background-clip: text;
//         background-clip: text;
//         -webkit-text-fill-color: transparent;
//         display: flex;
//         justify-content: center;
//         padding-top: 4vh;
//         text-align: center;
//     }
//     .first_text{
//         font-size: 20px;
//     }
//   }
//     }
// }
   
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .Benefits_container {
        .whats_container {
          width: 980px;
          // background: rgb(81, 81, 68);
          .first {
            // background: orange;
            width: 25%;
            height: 100%;
            position: relative;
            top: 0;
            left: 0;
      
            .inside_first {
              position: absolute;
              top: 22%;
              left: 20%;
      
              .first_text {
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                line-height: 115%;
                /* or 67px */
      
                /* White */
      
                color: #ffffff;
              }
      
              .color_text {
                background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            }
          }
          .second {
            width: 40%;
            height: 100%;
              .inside_second3 {
                  min-width: 360px;
                  margin-bottom: 95px;
                  display: flex;
                  margin-left: -15px;
              }
          }
          .third {
            // background: green;
            width: 35%;
            height: 100%;
            position: relative;
            // margin-left: -3vw;
            margin-left: -40px;
            // top: 0;
            // left: 0;
            .doodleUp {
              // margin-left: 110px;
              // // margin-left: 40%;
              width: 200px;
              margin-left: 150px
            }
      
            .doodleDown {
              // margin-left: -75px;
              // margin-top: 120px;
              // // margin-left: -18%;
              // // margin-top: 50%;
              // // margin-top: 40%;
              width: 200px;
              margin-left: -80px;
              margin-top: 150px;
            }
      
            .inside_third {
              position: absolute;
              // top: 20%;
              // left: 15%;
      
              //  background: yellow; 
              // width: 25vw;
              // height: 35vh;
              width: 277.25px;
              height: 276.15px;
              // margin-top: -10vh;
              // margin-top: -79px;
      
              .partImg {
                width: 240px;
                height: 300px;
              }
      
              .partImg1 {
                // width: 240px;
                // height: 300px;
                // width: 35vw;
                // height: 44vh;
                width:289.92px;
                height: 262.08px;

                margin-left: 120px;
                margin-top: 40px;
                // margin-top: -6vh;
              //   margin-top: -47px;
              //   // margin-left: -5vw;
              //   margin-left: -55px;
              }
      
              .partImg2 {
                // width: 330px;
                // height: 300px;
                // width: 30vw;
                // height: 44vh;
                width: 248.04px;
                height: 186.28px;
                margin-top: 75px;
                margin-left: 120px;
                // margin-top: -2vh;
                // margin-left: -3vw;
              //   margin-top: -15px;
              //   margin-left: -24px;
              }
      
              .partImg3 {
                // width: 240px;
                // height: 300px;
      
                // width: 23vw;
                // height: 36vh;
                width: 211.8px;
                height: 169.6px;
                margin-top: 80px;
                margin-left: 140px;
                // margin-top: 2vh;
              //   // margin-left: -2vw;
              //   margin-top: 25px;
              //   margin-left: -6px;
              }
      
              .partImg4 {
                // width: 300px;
                // height: 320px;
      
                // width: 30vw;
                // height: 40vh;
                // margin-top: -3vh;
                // margin-left: -2vw;
                width: 218.04px;
                height: 159.4px;
                margin-top: 90px;
            margin-left: 135px;
              //   margin-top: -24px;
              //   margin-left: -22px;
              }
      
              .partImg5 {
                // width: 280px;
                // height: 300px;
                // width: 29vw;
                // height: 36vh;
                // margin-top: -3vh;
                // margin-left: 1vw;
                width: 376.2px;
                height: 238.5px;
                margin-top: -13px;
                margin-left: 55px;
              }
      
              .partImg6 {
                // width: 240px;
                // height: 300px;
      
                // width: 21vw;
                // height: 27vh;
                // margin-top: 4vh;
                // margin-left: 1vw;
                width: 302px;
                height: 199px;
                margin-top: 30px;
                margin-left: 24px;
              }
      
              .partImg7 {
                // width: 240px;
                // height: 300px;
                // width: 21vw;
                // height: 33vh;
                // margin-top: 1vh;
                // margin-left: 1vw;
                width: 302px;
                height: 243px;
                margin-top: 8px;
                margin-left: 11px;
              }
      
              .partImg8 {
                // width: 240px;
                // height: 300px;
      
                // width: 21vw;
                // height: 37vh;
                // margin-top: -3vh;
                // margin-left: 0vw;
                width: 302px;
                height: 273px;
                margin-top: -24px;
                margin-left: 0px;
              }
            }
          }
        }
      }
  }
   
  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .Benefits_container {
        .whats_container {
          width: 1160px;
          // background: rgb(81, 81, 68);
          .first {
            // background: orange;
            width: 30%;
            height: 100%;
            position: relative;
            top: 0;
            left: 0;
      
            .inside_first {
              position: absolute;
              top: 33%;
              left: 20%;
      
              .first_text {
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                line-height: 115%;
                /* or 67px */
      
                /* White */
      
                color: #ffffff;
              }
      
              .color_text {
                background: linear-gradient(90deg, #FF0089 18.01%, #FFA4D5 50.47%, #35C2EC 82.92%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            }
          }
          .second {
            width: 45%;
            height: 100%;
              .inside_second3 {
                  min-width: 360px;
                  margin-bottom: 95px;
                  display: flex;
                  margin-left: -15px;
              }
          }
          .third {
            // background: green;
            width: 25%;
            height: 100%;
            position: relative;
            // margin-left: -3vw;
            margin-left: -40px;
            // top: 0;
            // left: 0;
            .doodleUp {
              // margin-left: 110px;
              // // margin-left: 40%;
              width: 200px;
              margin-left: 150px
            }
      
            .doodleDown {
              // margin-left: -75px;
              // margin-top: 120px;
              // // margin-left: -18%;
              // // margin-top: 50%;
              // // margin-top: 40%;
              width: 200px;
              margin-left: -80px;
              margin-top: 150px;
            }
      
            .inside_third {
              position: absolute;
              // top: 20%;
              // left: 15%;
      
              //  background: yellow; 
              // width: 25vw;
              // height: 35vh;
              width: 277.25px;
              height: 276.15px;
              // margin-top: -10vh;
              // margin-top: -79px;
      
              .partImg {
                width: 240px;
                height: 300px;
              }
      
              .partImg1 {
                // width: 240px;
                // height: 300px;
                // width: 35vw;
                // height: 44vh;
                width: 322.8px;
                height: 277.6px;

                margin-top: 40px;
                // margin-top: -6vh;
              //   margin-top: -47px;
              //   // margin-left: -5vw;
              //   margin-left: -55px;
              }
      
              .partImg2 {
                // width: 330px;
                // height: 300px;
                // width: 30vw;
                // height: 44vh;
                width: 311.04px;
                height: 233.28px;
                margin-top: 80px;
                // margin-top: -2vh;
                // margin-left: -3vw;
              //   margin-top: -15px;
              //   margin-left: -24px;
              }
      
              .partImg3 {
                // width: 240px;
                // height: 300px;
      
                // width: 23vw;
                // height: 36vh;
                width: 301px;
                height: 265px;
                margin-top: 55px;
                // margin-top: 2vh;
              //   // margin-left: -2vw;
              //   margin-top: 25px;
              //   margin-left: -6px;
              }
      
              .partImg4 {
                // width: 300px;
                // height: 320px;
      
                // width: 30vw;
                // height: 40vh;
                // margin-top: -3vh;
                // margin-left: -2vw;
                width: 311.04px;
                height: 212.4px;
                margin-top: 50px;
              //   margin-top: -24px;
              //   margin-left: -22px;
              }
      
              .partImg5 {
                // width: 280px;
                // height: 300px;
                // width: 29vw;
                // height: 36vh;
                // margin-top: -3vh;
                // margin-left: 1vw;
                width: 376.2px;
                height: 238.5px;
                margin-top: -13px;
                margin-left: 55px;
              }
      
              .partImg6 {
                // width: 240px;
                // height: 300px;
      
                // width: 21vw;
                // height: 27vh;
                // margin-top: 4vh;
                // margin-left: 1vw;
                width: 302px;
                height: 199px;
                margin-top: 30px;
                margin-left: 24px;
              }
      
              .partImg7 {
                // width: 240px;
                // height: 300px;
                // width: 21vw;
                // height: 33vh;
                // margin-top: 1vh;
                // margin-left: 1vw;
                width: 302px;
                height: 243px;
                margin-top: 8px;
                margin-left: 11px;
              }
      
              .partImg8 {
                // width: 240px;
                // height: 300px;
      
                // width: 21vw;
                // height: 37vh;
                // margin-top: -3vh;
                // margin-left: 0vw;
                width: 302px;
                height: 273px;
                margin-top: -24px;
                margin-left: 0px;
              }
            }
          }
        }
      }
  }