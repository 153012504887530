.Life_2_container {
  background: #000614;
  width: 100vw;
  // height: 100vh;
  height: 815px;
  box-sizing: border-box;

  // .ellipse15{
  //   width: 60vw;
  //   height: 500px;
  //   // margin-left: 20vw;
  //   margin-right: 40vw;
  // }

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 70px;
    padding: 70px 70px 70px 70px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 103%;
    /* identical to box height, or 54px */

    text-align: center;

    /* White */

    color: #ffffff;

    .color_text {
      background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .whats_container {
    // background-color: rgb(37, 9, 48);
    // width: 100vw;
    width: 1440px;
    height: 70%;
    display: flex;
    margin: auto;

    .first {
      // background: orange;
      width: 30%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;

      .inside_first {
        position: absolute;
        top: 25%;
        left: 20%;

        .first_text {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 115%;
          /* or 67px */

          /* White */

          color: #ffffff;
        }

        .color_text {
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .second {
      // background: gray;
      width: 40%;
      height: 100%;
      // position: relative;
      // top: 0;
      // left: 0;
      display: flex;

      .ellipse15 {
        // height: 70vh;
        // margin-left: -20vw;
        // height: 544px;

        height: 505px;
        margin-left: -282px;

        // height: 64vh;
        // margin-left: -19.5vw;

        // margin-right: 20px;
        // position: relative;
        // top: 0;
        // left: 0;
        // position: absolute;
        // height: 480px;
        // margin-left: -280px;
        // margin-top: 0;
        // margin-bottom: 0;
      }

      .circle {
        background: white;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
      }

      .circle_active {
        background: linear-gradient(94.77deg, #ff008a 6.23%, #0082ff 95.59%);
        width: 26px;
        height: 26px;
        border-radius: 50%;
        cursor: pointer;
      }

      .circle_text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 103%;
        /* identical to box height, or 29px */

        color: rgba(255, 255, 255, 0.5);
        margin-left: 40px;
        cursor: pointer;
      }

      .circle_text_active {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        // line-height: 103%;
        line-height: 110%;
        /* identical to box height, or 39px */

        letter-spacing: -0.02em;
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 46px;
        cursor: pointer;
      }

      // .ellipse20 {
      //   position: absolute;
      //   top: 2%;
      //   left: 4%;
      // }
      // .ellipse16 {
      //   position: absolute;

      //   top: 15%;
      //   left: 19%;
      // }
      // .ellipse23 {
      //   position: absolute;
      //   top: 28%;
      //   left: 26%;
      // }
      // .ellipse17 {
      //   position: absolute;
      //   top: 41%;
      //   left: 29%;
      // }
      // .ellipse22 {
      //   position: absolute;
      //   top: 54%;
      //   left: 29.5%;
      // }
      // .ellipse18 {
      //   position: absolute;
      //   top: 68%;
      //   left: 26.5%;
      // }
      // .ellipse19 {
      //   position: absolute;
      //   top: 81%;
      //   left: 20%;
      // }
      // .ellipse21 {
      //   position: absolute;
      //   top: 94%;
      //   left: 4%;
      // }

      .inside_second1 {
        // min-width: 35vw;
        // margin-bottom: 6vh;
        display: flex;
        min-width: 360px;
        margin-bottom: 43px;
        // margin-left: -12vw;
        margin-left: -152px;
      }

      .inside_second2 {
        // min-width: 35vw;
        min-width: 360px;
        display: flex;
        // margin-bottom: 6vh;
        margin-bottom: 46px;
        // margin-left: -5vw;
        margin-left: -70px;
      }

      .inside_second3 {
        // min-width: 35vw;
        // margin-bottom: 6vh;
        min-width: 360px;
        margin-bottom: 43px;
        display: flex;
        // margin-left: -2vw;
        margin-left: -32px;
      }

      .inside_second4 {
        // min-width: 35vw;
        min-width: 360px;
        display: flex;
        // margin-bottom: 6vh;
        margin-bottom: 43px;
        // margin-left: -1vw;
        margin-left: -15px;
      }

      .inside_second5 {
        // min-width: 35vw;
        min-width: 360px;
        display: flex;
        // margin-bottom: 6vh;
        margin-bottom: 43px;
        // margin-left: -1vw;
        margin-left: -12px;
      }

      .inside_second6 {
        // min-width: 35vw;
        min-width: 360px;
        display: flex;
        // margin-bottom: 6vh;
        margin-bottom: 43px;
        // margin-left: -2vw;
        margin-left: -26px;
      }

      .inside_second7 {
        // min-width: 35vw;
        min-width: 360px;
        display: flex;
        // margin-bottom: 6vh;
        margin-bottom: 53px;
        // margin-left: -4.5vw;
        margin-left: -60px;

      }

      .inside_second8 {
        // min-width: 35vw;
        min-width: 360px;
        display: flex;
        // margin-bottom: 6vh;
        margin-bottom: 43px;
        // margin-left: -10vw;
        margin-left: -143px;
      }
    }

    .third {
      // background: green;
      width: 30%;
      height: 100%;
      position: relative;
      // margin-left: -3vw;
      margin-left: -40px;
      // top: 0;
      // left: 0;
      .doodleUp {
        // margin-left: 110px;
        // // margin-left: 40%;
        width: 200px;
        margin-left: 150px
      }

      .doodleDown {
        // margin-left: -75px;
        // margin-top: 120px;
        // // margin-left: -18%;
        // // margin-top: 50%;
        // // margin-top: 40%;
        width: 200px;
        margin-left: -100px;
        margin-top: 150px;
      }

      .inside_third {
        position: absolute;
        // top: 20%;
        // left: 15%;

        //  background: yellow; 
        // width: 25vw;
        // height: 35vh;
        width: 277.25px;
        height: 276.15px;
        // margin-top: -10vh;
        margin-top: -79px;

        .partImg {
          width: 240px;
          height: 300px;
        }

        .partImg1 {
          // width: 240px;
          // height: 300px;
          // width: 35vw;
          // height: 44vh;
          width: 504px;
          height: 347px;
          // margin-top: -6vh;
          margin-top: -47px;
          // margin-left: -5vw;
          margin-left: -55px;
        }

        .partImg2 {
          // width: 330px;
          // height: 300px;
          // width: 30vw;
          // height: 44vh;
          width: 432px;
          height: 324px;
          // margin-top: -2vh;
          // margin-left: -3vw;
          margin-top: -15px;
          margin-left: -34px;
        }

        .partImg3 {
          // width: 240px;
          // height: 300px;

          // width: 23vw;
          // height: 36vh;
          width: 331px;
          height: 265px;
          // margin-top: 2vh;
          // margin-left: -2vw;
          margin-top: 15px;
          margin-left: -22px;
        }

        .partImg4 {
          // width: 300px;
          // height: 320px;

          // width: 30vw;
          // height: 40vh;
          // margin-top: -3vh;
          // margin-left: -2vw;
          width: 432px;
          height: 295px;
          margin-top: -24px;
          margin-left: -22px;
        }

        .partImg5 {
          // width: 280px;
          // height: 300px;
          // width: 29vw;
          // height: 36vh;
          // margin-top: -3vh;
          // margin-left: 1vw;
          width: 418px;
          height: 265px;
          margin-top: -23px;
          margin-left: 10px;
        }

        .partImg6 {
          // width: 240px;
          // height: 300px;

          // width: 21vw;
          // height: 27vh;
          // margin-top: 4vh;
          // margin-left: 1vw;
          width: 302px;
          height: 199px;
          margin-top: 30px;
          margin-left: 12px;
        }

        .partImg7 {
          // width: 240px;
          // height: 300px;
          // width: 21vw;
          // height: 33vh;
          // margin-top: 1vh;
          // margin-left: 1vw;
          width: 302px;
          height: 243px;
          margin-top: 8px;
          margin-left: 11px;
        }

        .partImg8 {
          // width: 240px;
          // height: 300px;

          // width: 21vw;
          // height: 37vh;
          // margin-top: -3vh;
          // margin-left: 0vw;
          width: 302px;
          height: 273px;
          margin-top: -24px;
          margin-left: 0px;
        }
      }
    }
  }

  .whats_container_mobile {
    display: none;
  }
}


/* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .Life_2_container {
    background: #000614;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;

    // .ellipse15{
    //   width: 60vw;
    //   height: 500px;
    //   // margin-left: 20vw;
    //   margin-right: 40vw;
    // }

    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 70px;
      padding: 70px 15px 40px 15px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 103%;
      /* identical to box height, or 54px */

      text-align: center;

      /* White */

      color: #ffffff;
      text-decoration: underline;
      text-underline-offset: 8px;

      .color_text {
        background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .whats_container {
      display: none;
    }

    .whats_container_mobile {
      display: block;
      width: 100vw;
      height: 70vh;
      // background-color: #4a76de;

      .card1 {
        // background: yellow;
        width: 100vw;
        height: 70vh;
        margin-bottom: 4vh;
        // margin: auto;

        .circle_text_active {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 115%;
          /* identical to box height, or 39px */
  
          letter-spacing: -0.02em;
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          // margin-left: 46px;
          // cursor: pointer;
          display: flex;
          // margin: auto;
          justify-content: center;
          // margin-top:2vh ;
          padding-top: 4vh;
          text-align: center;
        }

        .partImg1 {
          // width: 240px;
          // height: 300px;
          width: 100vw;
          height: 42vh;
          margin-top: -5vh;
          // margin-left: -5vw;
        }

        .partImg2 {
          width: 70vw;
    height: 44vh;
    margin-top: -4vh;
    margin-left: 12vw;
        }

        .partImg3 {
          width: 66vw;
    height: 40vh;
    margin-top: 4vh;
    margin-left: 8vw;
        }

        .partImg4 {
          width: 90vw;
    height: 45vh;
    margin-top: -2vh;
    margin-left: 5vw;
        }

        .partImg5 {
          width: 85vw;
          height: 36vh;
          margin-top: 3vh;
          margin-left: 15vw;
        }

        .partImg6 {
          width: 60vw;
    height: 27vh;
    margin-top: 6vh;
    margin-left: 25vw;
        }

        .partImg7 {
          width: 60vw;
    height: 33vh;
    margin-top: 7vh;
    margin-left: 26vw;
        }

        .partImg8 {
          width: 70vw;
    height: 35vh;
    margin-top: 4vh;
    margin-left: 15vw;
        }

        .first_text {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 115%;
          /* or 67px */

          /* White */

          color: #ffffff;
          // margin-left: 11vw;
          // margin-top: 6vh;
          margin: 6vh 2vh;
          text-align: center;

          .color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        
      }
    }

    .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
      background: #ffffff;
      // border: 1px solid #ffffff;
    }
    .react-multiple-carousel__arrow::before{
      color: #000614;
    }
    .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
      background: #ffffff;
      // border: 1px solid #ffffff;
    }
    // .react-multiple-carousel__arrow{
    //   background: #ffffff;
    // }
    // // .react-multiple-carousel__arrow::before{
    // //   color: #000614;
    // // }
  }
}

// @media only screen and (max-height:720px) and (min-width:481px){
//   .Life_2_container {
//     background: #000614;
//     width: 100vw;
//     height: 100vh;
//     box-sizing: border-box;

//     // .ellipse15{
//     //   width: 60vw;
//     //   height: 500px;
//     //   // margin-left: 20vw;
//     //   margin-right: 40vw;
//     // }

//     .heading {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       // padding: 70px;
//       padding: 70px 20px 40px 25px;
//       font-family: Inter;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 34px;
//       line-height: 103%;
//       /* identical to box height, or 54px */

//       text-align: center;

//       /* White */

//       color: #ffffff;
//       text-decoration: underline;
//       text-underline-offset: 8px;

//       .color_text {
//         background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//       }
//     }

//     .whats_container {
//       display: none;
//     }

//     .whats_container_mobile {
//       display: block;
//       width: 100vw;
//       height: 70vh;
//       // background-color: #4a76de;

//       .card1 {
//         // background: yellow;
//         width: 23vw;
//         height: 75vh;
//         margin-bottom: 4vh;
//         // margin: auto;
//         margin-left: 4vw;
//         margin-right: 4vw;

//         .circle_text_active {
//           font-family: Inter;
//           font-style: normal;
//           font-weight: 600;
//           font-size: 30px;
//           line-height: 115%;
//           /* identical to box height, or 39px */
  
//           letter-spacing: -0.02em;
//           background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
//           background-clip: text;
//           -webkit-text-fill-color: transparent;
//           // margin-left: 46px;
//           // cursor: pointer;
//           display: flex;
//           // margin: auto;
//           justify-content: center;
//           // margin-top:2vh ;
//           padding-top: 4vh;
//           text-align: center;
//         }

//         .partImg1 {
//           // width: 240px;
//           // height: 300px;
//           width: 25vw;
//           height: 42vh;
//           margin-top: -5vh;
//           margin-left: -1vw;
//         }

//         .partImg2 {
//           width: 28vw;
//     height: 44vh;
//     margin-top: -4vh;
//     margin-left: -3vw;
//         }

//         .partImg3 {
//           width: 26.4vw;
//     height: 40vh;
//     margin-top: 4vh;
//     margin-left: -2vw;
//         }

//         .partImg4 {
//           width: 30vw;
//     height: 45vh;
//     margin-top: -2vh;
//     margin-left: -2vw;
//         }

//         .partImg5 {
//           width: 30vw;
//           height: 36vh;
//           margin-top: 3vh;
//           margin-left: 0vw;
//         }

//         .partImg6 {
//           width: 17vw;
//     height: 27vh;
//     margin-top: 6vh;
//     margin-left: 4vw;
//         }

//         .partImg7 {
//           width: 24vw;
//     height: 33vh;
//     margin-top: 7vh;
//     margin-left: 0vw;
//         }

//         .partImg8 {
//           width: 20vw;
//     height: 35vh;
//     margin-top: 4vh;
//     margin-left: 1vw;
//         }

//         .first_text {
//           font-family: Inter;
//           font-style: normal;
//           font-weight: bold;
//           font-size: 20px;
//           line-height: 115%;
//           /* or 67px */

//           /* White */

//           color: #ffffff;
//           // margin-left: 11vw;
//           // margin-top: 6vh;
//           // margin: 6vh 2vh;
//           text-align: center;

//           .color_text {
//             background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
//             background-clip: text;
//             -webkit-text-fill-color: transparent;
//           }
//         }

//         .wealth{
//           margin-top: 5vh;
//       margin-right: 2vw;
//         }
//         .fun{
//           margin-top: 4vh;
//         }
//         .helping{
//           margin-top: 11vh;
//         }
//         .continuous{
//           margin-top: 5vh;
//         }
//         .independence{
//           margin-top: 1vh;
//         }
//         .changing{
//           margin-top: 0vh;
//         }
//         .biggest{
//           margin-top: 3vh;
//         }
//       }
//     }

//     .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
//       background: #ffffff;
//       // border: 1px solid #ffffff;
//     }
//     .react-multiple-carousel__arrow::before{
//       color: #000614;
//     }
//     .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
//       background: #ffffff;
//       // border: 1px solid #ffffff;
//     }
//     // .react-multiple-carousel__arrow{
//     //   background: #ffffff;
//     // }
//     // // .react-multiple-carousel__arrow::before{
//     // //   color: #000614;
//     // // }
//   }
// }

@media (min-width: 1024px) and (max-width: 1439px) {
  .Life_2_container {
    .whats_container {
      width: 1024px;
      // background: rgb(81, 81, 68);
      .first {
        // background: orange;
        width: 30%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
  
        .inside_first {
          position: absolute;
          top: 33%;
          left: 20%;
  
          .first_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 115%;
            /* or 67px */
  
            /* White */
  
            color: #ffffff;
          }
  
          .color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .third {
        // background: green;
        width: 30%;
        height: 100%;
        position: relative;
        // margin-left: -3vw;
        margin-left: -40px;
        // top: 0;
        // left: 0;
        .doodleUp {
          // margin-left: 110px;
          // // margin-left: 40%;
          width: 200px;
          margin-left: 150px
        }
  
        .doodleDown {
          // margin-left: -75px;
          // margin-top: 120px;
          // // margin-left: -18%;
          // // margin-top: 50%;
          // // margin-top: 40%;
          width: 200px;
          margin-left: -80px;
          margin-top: 150px;
        }
  
        .inside_third {
          position: absolute;
          // top: 20%;
          // left: 15%;
  
          //  background: yellow; 
          // width: 25vw;
          // height: 35vh;
          width: 277.25px;
          height: 276.15px;
          // margin-top: -10vh;
          margin-top: -79px;
  
          .partImg {
            width: 240px;
            height: 300px;
          }
  
          .partImg1 {
            // width: 240px;
            // height: 300px;
            // width: 35vw;
            // height: 44vh;
            width: 504px;
            height: 347px;
            // margin-top: -6vh;
            margin-top: -47px;
            // margin-left: -5vw;
            margin-left: -55px;
          }
  
          .partImg2 {
            // width: 330px;
            // height: 300px;
            // width: 30vw;
            // height: 44vh;
            width: 388.8px;
            height: 291.6px;
            // margin-top: -2vh;
            // margin-left: -3vw;
            margin-top: -15px;
            margin-left: -24px;
          }
  
          .partImg3 {
            // width: 240px;
            // height: 300px;
  
            // width: 23vw;
            // height: 36vh;
            width: 331px;
            height: 265px;
            // margin-top: 2vh;
            // margin-left: -2vw;
            margin-top: 25px;
            margin-left: -6px;
          }
  
          .partImg4 {
            // width: 300px;
            // height: 320px;
  
            // width: 30vw;
            // height: 40vh;
            // margin-top: -3vh;
            // margin-left: -2vw;
            width: 432px;
            height: 295px;
            margin-top: -24px;
            margin-left: -22px;
          }
  
          .partImg5 {
            // width: 280px;
            // height: 300px;
            // width: 29vw;
            // height: 36vh;
            // margin-top: -3vh;
            // margin-left: 1vw;
            width: 376.2px;
            height: 238.5px;
            margin-top: -13px;
            margin-left: 55px;
          }
  
          .partImg6 {
            // width: 240px;
            // height: 300px;
  
            // width: 21vw;
            // height: 27vh;
            // margin-top: 4vh;
            // margin-left: 1vw;
            width: 302px;
            height: 199px;
            margin-top: 30px;
            margin-left: 24px;
          }
  
          .partImg7 {
            // width: 240px;
            // height: 300px;
            // width: 21vw;
            // height: 33vh;
            // margin-top: 1vh;
            // margin-left: 1vw;
            width: 302px;
            height: 243px;
            margin-top: 8px;
            margin-left: 11px;
          }
  
          .partImg8 {
            // width: 240px;
            // height: 300px;
  
            // width: 21vw;
            // height: 37vh;
            // margin-top: -3vh;
            // margin-left: 0vw;
            width: 302px;
            height: 273px;
            margin-top: -24px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}