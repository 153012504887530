.life_last_page{
  .media_page{
    width: 100vw;
    height: 100%;
    background: #000614;
    padding: 100px 0;

    .join_nakad {
        // background: skyblue;
        width: 100vw;
        //height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
    
        .rect {
          //height: 45%;
          background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
          border-radius: 50px;
          margin: 100px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: row;
          margin-bottom: 0;
          min-height: 260px;

          .left_rect {
            //width: 40%;
            text-align: center;
            .footerImg1 {
              margin-top: -240px;
              padding-bottom: 0;
              width: 100%;
              max-width: 450px;
            }
          }
          .right_rect {
            padding: 40px 0;
            padding-left: 0px;
    
            .text_box {
             max-width: 90%;
              .text {
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                // line-height: 44px;
                /* identical to box height */
    
                color: #ffffff;
                margin-bottom: 0px;
              }
              .footer_text {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                // line-height: 29px;
    
                color: rgba(255, 255, 255, 0.6);
                display: flex;
                align-items: center;
              }
            }
    
            .footerBtn {
              background: #ffffff;
                box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                padding: 10px 15px;
               // height: 5vh;
                border: none;
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
    
              .color_text {
                background: linear-gradient(
                  94.77deg,
                  #ff008a 6.23%,
                  #8d3bbf 95.59%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 0 0;
              }
            }
          }
        }
      }
  }
}


footer {
  background: rgb(17 23 35);
  width: 100vw;
  // height: 30vh;
  .row{
    &>div {
      display: inline-flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
  .copyright {
    // background: orangered;
    .copyright_text {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 100% */

      font-feature-settings: "salt" on, "liga" off;

      /* Text / Gray 300 */

      color: #d9dbe1;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
    }
  }
  .footer_img {
    // background: white;
    max-width: 30vw;
    display: flex;
    justify-content: center;
    img {
      width: 20vw;
      margin-top: 15vh;
     }
  }
  .social {
    // background: green;
    max-width: 35vw;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    .icon_div {
      // background: red;
     // width: 70%;
      //height: 30%;
      //margin-top: 18vh;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      justify-content: flex-start;
      .icon {
        justify-content: center;
        background: rgba(49, 53, 56, 0.548);
        align-items: center;
        display: flex;
        border-radius: 50%;
        //padding: 12px;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        img{
          max-width: 20px;
        }
      }
    }
  }
}

/* Media Query for Mobile Devices */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .life_last_page{
     .media_page {
      background: #000614;
      height: auto;
      padding-bottom: 30px;
      padding-top: 100px;
      .media_page_section {
        margin-left: 15vw;
        margin-right: 15vw;
    
        .vcfirm {
          // background-color: #0082ff;
          // margin-top: 15vh;
          height: 40vh;
          width: 70vw;
          display: flex;
          justify-content: space-evenly;
    
          .vcfirm_left {
            width: 40vw;
            // background-color: rgb(207, 123, 123);
    
            .vcfirm_text {
              font-family: Inter;
              font-style: normal;
              font-weight: bold;
              font-size: 30px;
              line-height: 120%;
              /* or 58px */
    
              letter-spacing: -0.02em;
    
              color: #ffffff;
    
              margin-top: 18vh;
              // margin-left: 5vw;
            }
            .vcfirm_color_text {
              background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .vcfirm_btn {
              margin-top: 8%;
              background: #272727;
              color: #ffffff;
              font-size: 12px;
              padding: 8px;
              border-radius: 8px;
              border: none;
            }
          }
          .vcfirm_right {
            width: 40vw;
            // background-color: rgb(99, 212, 207);
    
            .rocket_box {
              margin-top: 14vh;
              margin-left: 20vw;
              .rocket {
                width: 18vw;
                height: 14vw;
              }
            }
          }
        }
        .media {
          // background: yellow;
          height: 60vh;
          width: 70vw;
          margin-top: 40px;
          .react-multiple-carousel__arrow{
            z-index: 0;
          }
    
          .media_text {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 120%;
            /* identical to box height, or 58px */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #ffffff;
            // display: inline;
          }
          .media_color_text {
            background: linear-gradient(94.77deg, #ff008a 6.23%, #8d3bbf 95.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
    
          .media_img {
            width: 90%;
            height: 90%;
          }
          img {
            width: 22vw;
            // height: 260px;
          }
          .img_text {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            /* or 29px */
    
            letter-spacing: -0.02em;
            text-transform: uppercase;
    
            color: #ffffff;
          }
        }
      }
    
      // last page
      .join_nakad {
        width: 100vw;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        .rect {
          flex-flow: column;
          margin: 0;
          height: 100%;
          .left_rect {
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            .footerImg1 {
              margin-top: -250px;
              padding-bottom: 0px;
              max-height: 100%;
              min-width: 300px;
             }
           }
          .right_rect {
            width:100%;
            padding-left: 0;
            padding-top: 10px;
            padding-bottom: 15px;
    
            .text_box {
              max-width: 100%;
              text-align: center;
              .text {
                font-family: Inter;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                // line-height: 44px;
                /* identical to box height */
    
                color: #ffffff;
                // margin-bottom: 0px;
                text-align: center;
               // margin-left: -6vw;
              }
              .footer_text {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                // line-height: 29px;
                padding: 0 40px;
                color: rgba(255, 255, 255, 0.6);
                // text-align: center;
                margin-top: 1vh;
                display: flex;
               align-items: center;
              }
            }
    
            div{
              width: 100%;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              flex-flow: column;
              .footerBtn {
                background: #ffffff;
                box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.2);
                border-radius: 12px;
                padding: 5px 15px;
               // height: 5vh;
                border: none;
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                // line-height: 40px;
                // margin-left: 5vw;
                //margin-left: 2.5vw;
                // margin-top: 6vh;
      
                .color_text {
                  background: linear-gradient(
                    94.77deg,
                    #ff008a 6.23%,
                    #8d3bbf 95.59%
                  );
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  margin-top: 0px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      
    }
  }

  footer {
    width: 100vw;
    display: inline-block;
    .row{
      &>div {
        display: inline-flex;
        justify-content: center;
        align-items: flex-end;
      }
    }
   
    .copyright {
     
      .copyright_text {
        margin-top: 20px;
      }
    }
    .footer_img {
      // background: white;
      max-width: 100vw;
      img {
        margin-top: 1vh;
        width: 50vw
      }
    }
    .quick_links{
      margin-top: 15px;
    }
    .social {
       margin-top: 2vh;
       max-width: 100%;
      .icon_div {
        // background: red;
        width: 100%;
        //height: 30%;
        margin-top: 3vh;
        //margin-right: 5vw;
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        // align-items: center;
        .icon{
          padding: 0 10px;
        }
      }
    }
   
  }

}