.faq_sec{
    .accordion-flush .accordion-item .accordion-button{
        color: black;
        font-weight: bold;
    }
    // .accordion-button:after {
    //     order: -1; //swap order
    //     margin-left: 0; 
    //     margin-right:0.5em; // just extra space between text and icon
    // }
    .fs_16{
        li{
            font-size: 16px !important;
        }
       
    }
}